export const isNumber = (value: unknown): boolean => typeof value === "number";

export const isString = (value: unknown): boolean => typeof value === "string";

export const isUndefined = (value: unknown): boolean => typeof value === "undefined";

export const isArray = (value: unknown): boolean => Array.isArray(value);

export const isStringOrNumber = (value: unknown): boolean => typeof value === "string" || typeof value === "number";

export const isBoolean = (value: unknown): boolean => typeof value === "boolean";

export const isNotNumber = (value: unknown): boolean => typeof value !== "number";

export const isNotString = (value: unknown): boolean => typeof value !== "string";

export const isNotArray = (value: unknown): boolean => !Array.isArray(value);

export const isNotStringOrNumber = (value: unknown): boolean => !(typeof value === "string" || typeof value === "number");

export const isNotUndefined = (value: unknown): boolean => typeof value !== "undefined";

export const isNotBoolean = (value: unknown): boolean => typeof value !== "boolean";
