import React from "react";
import { Controller } from "react-hook-form";

const Checkbox = ({ name, control, label, anchorText, anchorLink, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <div className="flex flex-row-reverse items-center justify-end gap-2">
                        {label && !anchorLink && (
                            <label htmlFor={name} className="text-sm font-bold text-white">
                                {label}
                            </label>
                        )}
                        {anchorText && (
                            <a className="text-sm text-rc-blue hover:text-rc-pink" rel="noreferrer" href={anchorLink} target="_blank">
                                {anchorText}
                            </a>
                        )}
                        <input
                            type="checkbox"
                            {...field}
                            {...props}
                            id={name}
                            className={`cursor-pointer border text-black ${error ? "border-red-500" : "border-gray-300"} rounded`}
                        />
                    </div>
                    {error && <span className="text-sm text-red-500">{error.message}</span>}
                </div>
            )}
        />
    );
};

export default Checkbox;
