import useSignUp from "@helper/custom-hooks/use-sign-up";
import { getRange } from "@helper/get-range";
import React, { Dispatch, FC, SetStateAction } from "react";

import ProgressBullet from "./progess-bullet";
import ProgressBulletDivider from "./progress-bullet-divider";

export interface Props {
    currentProgressPoint: number;
    steps: number;
    blocked?: number[];
    setSteps?: Dispatch<SetStateAction<number>>;
}

// TODO: Implement validation
const ProgressBulletBar: FC<Props> = ({ currentProgressPoint, steps, blocked, setSteps }: Props) => {
    const range = getRange(0, steps);
    const { setStep } = useSignUp();

    const handleSetStep = (step: number): void => {
        if (blocked?.includes(step)) return;

        if (setSteps) {
            setSteps(step);
            return;
        }
        setStep(step);
    };

    return (
        <div className="flex h-10 flex-row justify-center space-x-2 md:space-x-8">
            {range.map((v) => (
                <React.Fragment key={`ProgressBullet_${v}`}>
                    <ProgressBullet
                        bulletNumber={v}
                        currentProgressPoint={currentProgressPoint}
                        onClick={() => handleSetStep(v)}
                        blocked={blocked?.includes(v) || false}
                    />
                    {v !== range.length - 1 && <ProgressBulletDivider nextBulletNumber={v + 1} currentProgressPoint={currentProgressPoint} />}
                </React.Fragment>
            ))}
        </div>
    );
};
export default ProgressBulletBar;
