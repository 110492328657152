import AuthStore from "@helper/auth/auth-store";
import useVirtualTour from "@helper/custom-hooks/use-virtual-tour";
import { ElementType, useMemo } from "react";
import Joyride, { TooltipRenderProps } from "react-joyride";

import VirtualTourTooltip from "./virtual-tour-tooltip";

const optionStyles = {
    arrowColor: "rgb(41, 37, 36)",
    width: 300,
    zIndex: 1000,
};

const VirutalTour = () => {
    const user = AuthStore((state) => state.user);

    const { steps, stepIndex, tourKey } = useVirtualTour();

    const stepList = useMemo(
        () =>
            steps
                // remove watched step
                .filter((step) => {
                    return !localStorage.getItem(`${tourKey}_${step.target}`);
                })
                .map((step) => ({ ...step, disableBeacon: true, target: `#${step.target}` })),
        [steps, tourKey],
    );

    if (!user?.onboarding || stepIndex < 0 || stepIndex >= steps.length) return null;

    return (
        <Joyride
            tooltipComponent={VirtualTourTooltip as ElementType<TooltipRenderProps>}
            disableOverlay={false}
            disableCloseOnEsc
            disableOverlayClose
            stepIndex={stepIndex}
            steps={stepList}
            floaterProps={{ disableAnimation: true }}
            scrollOffset={100}
            styles={{ options: optionStyles }}
        />
    );
};

export default VirutalTour;
