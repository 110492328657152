import { ICurrentUser } from "@models/session/current-user";
import create from "zustand";
import { devtools } from "zustand/middleware";

interface AuthStoreState {
    user: ICurrentUser;
    setUser: (user: ICurrentUser) => void;
    updateUser: (user: Partial<ICurrentUser>) => void;
}

const AuthStore = create<AuthStoreState>(
    devtools(
        (set, get) => ({
            user: undefined,
            setUser: (user: ICurrentUser) => set(() => ({ user })),
            updateUser: (userData: Partial<ICurrentUser>) => {
                const { user } = get();
                set(() => ({ user: { ...user, ...userData } }));
            },
        }),
        { name: "AuthStore" },
    ),
);

export default AuthStore;
