import Player from "@components/player/player";
import AuthStore from "@helper/auth/auth-store";
import useBreakpoints from "@helper/custom-hooks/use-breakpoints";
import useOpenClose from "@helper/custom-hooks/use-open-close";
import useVisual from "@helper/custom-hooks/use-visual";
import { XIcon } from "@heroicons/react/outline";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";
import { menuCloseIcon, menuOpenIcon, searchIcon } from "styles/icons";

import SignupLoginButtons from "../buttons/signup-login-buttons";
import Modal from "../modal/modal";
import SignupLoginModule from "../modal/modal-contents/signup-login-module";
import NavbarMenuContent from "../navbar/navbar-menu/navbar-menu-content";
import UserHeader from "./user-header";

interface IConfig {
    logo: { href: string; alt: string };
    logoDark: { href: string; alt: string };
    signet: { href: string; alt: string };
}

const CONFIG: IConfig = {
    logo: { href: "/logo_white.png", alt: "Realclixx Logo" },
    logoDark: { href: "/logo_black.png", alt: "Realclixx Logo" },
    signet: { href: "/signet.svg", alt: "Realclixx-Signet" },
};

const Header: FC = () => {
    const breakpoints = useBreakpoints();
    const visual = useVisual();
    const user = AuthStore((state) => state.user);
    const isAdmin = user?.role === "admin";

    const openClose = useOpenClose();
    const router = useRouter();

    const { t } = useTranslation();

    return (
        <div className=" ">
            {user && <Player />}
            <header className={`${2 < 300 ? "fixed" : "fixed md:absolute"}  shadow-lg top-0 z-40 w-full bg-rc-dark`}>
                {breakpoints.isLgAndAbove && user && <UserHeader />}
                <div className="flex w-full justify-start md:justify-center">
                    <div className="relative w-full">
                        {user ? (
                            breakpoints.isLgAndAbove ? (
                                <></>
                            ) : (
                                <div className="container mx-auto flex h-12 flex-row items-center  justify-between bg-rc-dark px-4">
                                    {!isAdmin && <div onClick={openClose.toggleOpenClose}>{menuOpenIcon("h-8 w-8", "#FFF")}</div>}
                                    <div className="flex flex-row gap-8">
                                        <button>{searchIcon("#fff", "h-7 w-7")}</button>
                                        <Link href="/profile">
                                            <a>
                                                <img
                                                    src={user?.profile_picture || `https://ui-avatars.com/api/?name=${user.username}`}
                                                    alt={user?.username}
                                                    className="size-7 cursor-pointer self-end rounded-full"
                                                />
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="flex w-full flex-row justify-end justify-self-end ">
                                {breakpoints.isLgAndAbove ? (
                                    router.pathname === "/" ? (
                                        ""
                                    ) : (
                                        <SignupLoginButtons
                                            signUpLabel={t("generic.signup")}
                                            loginLabel={t("generic.login")}
                                            signUpLink="/setup"
                                            loginLink="/login"
                                        />
                                    )
                                ) : (
                                    <div onClick={openClose.toggleOpenClose} className="float-right">
                                        {menuCloseIcon("h-8 w-8", "#FFF")}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </header>

            <Modal handler={openClose}>
                {user ? (
                    <div className=" h-screen bg-white p-4 dark:bg-rc-dark">
                        <div className="flex w-full justify-between">
                            <button type="button" className="w-full" onClick={openClose.handleClose}>
                                <Link href="/">
                                    <a>
                                        <img
                                            src={visual.darkLightMode === "dark" ? CONFIG.logo.href : CONFIG.logoDark.href}
                                            alt={visual.darkLightMode === "dark" ? CONFIG.logo.alt : CONFIG.logoDark.alt}
                                            className={`duration-500 h-8 pt-1 transition-opacity ${
                                                visual?.searchOpenClose?.isOpen ? "opacity-0" : "opacity-100"
                                            }`}
                                        />
                                    </a>
                                </Link>
                            </button>
                            <XIcon onClick={openClose.handleClose} className="size-8 cursor-pointer text-black dark:text-white" />
                        </div>

                        <div className="flex size-full  flex-col justify-center">
                            <NavbarMenuContent onClick={openClose.handleClose} handleCloseNavbar={() => {}} />
                        </div>
                    </div>
                ) : (
                    <SignupLoginModule onClick={openClose.handleClose} />
                )}
            </Modal>
        </div>
    );
};
export default Header;
