import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import VideoPlayerControls from "./video-player-controls";

interface IState {
    playing: boolean;
    muted: boolean;
    duration: number;
    progress: number;
}

export interface IVideoPlayer {
    videoUrl: string;
    duration_ms?: number;
    controls?: boolean;
    play?: boolean;
    showOnlySlider?: boolean;
    loop?: boolean;
    shouldBeFullScreen?: boolean;
    showForwardIcon?: boolean;
    finish?: {
        index: number;
        setIndex: ((index: number) => void) | undefined;
        length: number;
        close: () => void;
    };
    getIsPlayingCallback?: (isPlaying: boolean) => void;
    initialStartTime?: number;
    onEnded?: () => void;
    handleOnPlay?: () => void;
    handleOnStop?: () => void;
    onError?: () => void;
}

const VideoPlayer: FC<IVideoPlayer> = ({
    videoUrl,
    controls = true,
    play = false,
    showForwardIcon = false,
    showOnlySlider = false,
    finish,
    shouldBeFullScreen,
    getIsPlayingCallback,
    initialStartTime,
    onEnded,
    handleOnPlay,
    handleOnStop,
    duration_ms,
    onError,
}: IVideoPlayer) => {
    const [state, setState] = useState<IState>({
        playing: play,
        muted: false,
        duration: 0,
        progress: 0,
    });
    const [jumpedToInitialStartTime, setJumpedToInitialStartTime] = useState<boolean>(false);

    const [locallyRequestedFullScreen, setLocallyRequestedFullScreen] = useState<boolean>(false);

    const seek = PlayerPersistSettingsStore((s) => s.seek);

    const setPlayerTime = PlayerPersistSettingsStore((s) => s.setPlayerTime);
    const volume = PlayerPersistSettingsStore((s) => s.volume);
    const reactPlayerReference: React.LegacyRef<ReactPlayer> | undefined = useRef(null);

    const videoPlayerReference: React.LegacyRef<HTMLDivElement> | undefined = useRef(null);

    const handleDuration = (duration: number): void => {
        setState({ ...state, duration });
    };

    const handleProgress = (progress: number): void => {
        setState({ ...state, progress });
    };

    const handleSeek = (fraction: number): void => {
        if (reactPlayerReference?.current) {
            reactPlayerReference.current.seekTo(fraction, "fraction");
        }
    };

    useEffect(() => {
        if (seek) {
            reactPlayerReference?.current?.seekTo(seek);
        }
    }, [seek]);
    const handleFullscreen = (): void => {
        if (videoPlayerReference && videoPlayerReference?.current?.requestFullscreen && document) {
            if (!document?.fullscreenElement) {
                setLocallyRequestedFullScreen(true);
            } else {
                setLocallyRequestedFullScreen(false);
                document.exitFullscreen();
            }
        }
    };

    const handleEnd = (): void => {
        if (finish && reactPlayerReference?.current) {
            reactPlayerReference.current.seekTo(0, "fraction");

            if (finish.index === finish.length - 1) finish.close();
            else if (finish?.setIndex) finish.setIndex(finish.index + 1);
        }
    };

    const onPlay = (): void => {
        if (handleOnPlay) handleOnPlay();
        setState({ ...state, playing: true });
    };
    const onPause = (): void => {
        if (handleOnStop) handleOnStop();
        setState({ ...state, playing: false });
    };

    useEffect(() => {
        if (typeof initialStartTime === "number" && initialStartTime > 0 && !jumpedToInitialStartTime && reactPlayerReference?.current?.seekTo) {
            reactPlayerReference?.current?.seekTo(initialStartTime);
            setJumpedToInitialStartTime(true);
        }
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (reactPlayerReference?.current) {
            interval = setInterval(() => {
                return setPlayerTime(moment.duration(reactPlayerReference?.current?.getCurrentTime()).asMilliseconds() * 1000);
            }, 500);
        }
        return () => clearInterval(interval);
    }, [reactPlayerReference?.current]);

    useEffect(() => {
        if (
            videoPlayerReference &&
            videoPlayerReference?.current?.requestFullscreen &&
            document &&
            (shouldBeFullScreen || locallyRequestedFullScreen) &&
            !document?.fullscreenElement
        ) {
            videoPlayerReference?.current?.requestFullscreen();
        }
    }, [shouldBeFullScreen, locallyRequestedFullScreen]);

    useEffect(() => {
        if (getIsPlayingCallback) {
            getIsPlayingCallback(state?.playing);
        }
    }, [state?.playing]);

    return (
        <div className="player-wrapper group relative overflow-hidden bg-black" ref={videoPlayerReference}>
            <ReactPlayer
                ref={reactPlayerReference}
                url={videoUrl}
                width="100%"
                height="100%"
                playing={play !== undefined ? play : state.playing}
                muted={volume === 0}
                className="react-player"
                onDuration={handleDuration}
                onProgress={(e) => handleProgress(e.played)}
                volume={volume}
                onEnded={onEnded || handleEnd}
                onPlay={onPlay}
                onPause={onPause}
                onError={onError}
            />
            {controls && (
                <VideoPlayerControls
                    showForwardIcon={showForwardIcon}
                    showOnlySlider={showOnlySlider}
                    // onPlayPause={handlePlayPause}
                    // playing={state.playing}
                    progress={state.progress}
                    handleSeek={handleSeek}
                    handleFullscreen={handleFullscreen}
                    duration_ms={duration_ms}
                />
            )}
        </div>
    );
};
export default VideoPlayer;
