import React, { FC } from "react";

interface ILoadingSpinner {
    isLoading: boolean;
    isCentered?: boolean;
    className?: string;
}

const SingleLoadingSpinner: FC<ILoadingSpinner> = ({ isLoading, isCentered, className }: ILoadingSpinner) => {
    return (
        <>
            {isLoading && (
                <img src="/signet.svg" alt="Realclixx" className={`h-10 w-10 animate-spin ${isCentered && "mx-auto"} ${className || ""}`} />
            )}
        </>
    );
};

export default SingleLoadingSpinner;
