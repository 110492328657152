import { InstagramImageResponse } from "@models/instagram/images-by-username";
import { MappedVideoResponse } from "@models/mapped-video-item";
import { PostAPIResponse } from "@models/post";
import axios from "axios";
import queryString from "querystring";

export const apiClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND,
    withCredentials: true,
});

apiClient.interceptors.response.use(null, (error) => {
    // Check if error from AbortController
    if (error.message === "canceled") {
        return;
    }

    return Promise.reject(error);
});

export const getInstagramVideos = async (
    username: string,
    hashtag?: string,
    limit: number = 12,
    videoPageInfo: {
        nextToken?: string;
    } = undefined,
    findByHashtag: boolean = false,
): Promise<MappedVideoResponse> => {
    const searchQueryStr = "/instagram/rapid/videos";
    const { nextToken } = videoPageInfo || {};

    const res = await apiClient.get(searchQueryStr, {
        params: {
            username,
            hashtag,
            limit,
            nextToken,
            findByHashtag,
        },
    });

    return {
        data: res?.data?.posts?.map((media) => ({
            id: media.id,
            profilePicUrl: media?.user?.profileImageUrl,
            fullName: media?.user?.name,
            username: media?.user?.username,
            caption: media?.postContent?.text,
            videoPreviewImage: media?.postContent?.entities?.[0]?.previewUrl,
            videoUrl: media?.postContent?.entities?.[0]?.variants?.[0]?.url,
            takenAt: media?.createdAt,
            videoDuration: media?.postContent?.entities?.[0]?.duration,
            code: media?.postContent?.entities?.[0]?.id,
        })),
        pageInfo: res?.data?.meta || {},
    };
};

export const getInstagramImages = async (
    username: string,
    hashtag: string,
    limit: number = 12,
    videoPageInfo: {
        nextToken?: string;
    } = undefined,
    findByHashtag: boolean = false,
): Promise<InstagramImageResponse> => {
    const searchQueryStr = "/instagram/rapid/images";
    const { nextToken } = videoPageInfo || {};

    const res = await apiClient.get(searchQueryStr, {
        params: {
            username,
            hashtag,
            limit,
            nextToken,
            findByHashtag,
        },
    });
    const mappedResUsername = res?.data?.posts?.map((imageItem) => {
        return {
            id: imageItem?.id,
            code: imageItem?.postContent?.entities?.[0]?.id,
            profile_pic_url: imageItem?.user?.profile_pic_url,
            username: imageItem?.user?.username,
            taken_at_timestamp: imageItem?.createdAt,
            caption: imageItem?.postContent?.text,
            display_url: imageItem?.postContent?.entities?.[0]?.previewUrl,
            likeCount: imageItem?.postContent?.favoriteCount,
            commentCount: imageItem?.postContent?.replyCount,
            children: imageItem?.postContent?.children,
        };
    });

    if (res?.data?.posts?.length)
        return {
            data: mappedResUsername,
            pageInfo: res?.data?.meta || {},
        };
};

export const getInstagramPostDetail = async (code: string, type = "video") => {
    const searchStr = `/instagram/rapid/media-detailed?shortcode=${code}`;

    const res = await apiClient.get(searchStr);
    const data = res?.data;
    if (data?.posts?.length > 0) {
        if (type === "video") {
            const videoItem = data?.posts[0];
            return {
                id: videoItem.id,
                profilePicUrl: videoItem?.user?.profileImageUrl,
                fullName: videoItem?.user?.name,
                username: videoItem?.user?.username,
                caption: videoItem?.postContent?.text,
                videoPreviewImage: videoItem?.postContent?.entities?.[0]?.previewUrl,
                videoUrl: videoItem?.postContent?.entities?.[0]?.variants?.[0].url,
                takenAt: videoItem?.createdAt,
                videoDuration: videoItem?.postContent?.entities?.[0]?.duration,
                likeCount: videoItem?.postContent?.favoriteCount,
                commentCount: videoItem?.postContent?.replyCount,
                shareCount: videoItem?.postContent?.reitemCount,
                viewCount: videoItem?.postContent?.reitemCount,
                code: videoItem?.postContent?.entities?.[0]?.id,
            };
        }

        const imageItem = data.posts[0];
        return {
            id: imageItem?.id,
            profile_pic_url: imageItem?.user?.profileImageUrl,
            username: imageItem?.user?.username,
            taken_at_timestamp: imageItem?.createdAt,
            caption: imageItem?.postContent?.text,
            display_url: imageItem?.postContent?.entities[0]?.variants?.[0].url,
            likeCount: imageItem?.postContent?.favoriteCount,
            commentCount: imageItem?.postContent?.replyCount,
            children: imageItem?.postContent?.children,
        };
    }
    return {};
};

export const getInstagramComments = async ({ shortcode }: { shortcode: string }) => {
    const params = queryString.stringify({ shortcode });
    const res = await apiClient.get(`/instagram/rapid/comments?${params}`);
    if (res?.data?.posts?.length) return res?.data;
};

export const getMyMedia = async (nextPageToken: string = undefined) => {
    const { data } = await apiClient.get(nextPageToken ? `/instagram/my-media/?page_token=${nextPageToken}` : "/instagram/my-media");

    return {
        data: (data?.data?.medias || []).map((item) =>
            item.media_type === "VIDEO"
                ? {
                      id: item.id,
                      username: item.username,
                      videoPreviewImage: item.thumbnail_url,
                      videoUrl: item.media_url,
                      takenAt: item.timestamp,
                      caption: item?.caption || "",
                  }
                : {
                      id: item.id,
                      username: item.username,
                      taken_at_timestamp: item.timestamp,
                      display_url: item.media_url,
                      caption: item?.caption || "",
                  },
        ),
        nextPageToken: data?.data?.nextPageToken,
        previousPageToken: data?.data?.previousPageToken,
        hasMore: (data?.data?.medias || []).length > 10,
    };
};

export async function getMyFollowings(userId = "") {
    const url = userId ? `/instagram/my-following?userId=${userId}` : "/instagram/my-following";
    const data = await apiClient.get(url);

    return data.data;
}

export async function getMyInstagramFeed() {
    const response: {
        data: PostAPIResponse;
    } = await apiClient.get("/instagram/my-feed");
    return response.data;
}
