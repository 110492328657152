import axios from "axios";

export const apiClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND,
    withCredentials: true,
});

apiClient.interceptors.response.use(null, (error) => {
    // Check if error from AbortController
    if (error.message === "canceled") {
        return;
    }

    return Promise.reject(error);
});

export const getListOfInterests = async (gender = "") => {
    const res = await apiClient.get(`/interests${gender ? `?gender=${gender}` : ""}`);
    return res.data;
};

export const updateInterestsForUser = async (interests: string[]) => {
    const res = await apiClient.patch("/user/interests", { interests });
    return res.data;
};

export const updateInterestsForCompany = async (slug: string, interests: string[]) => {
    const res = await apiClient.patch(`/companies/${slug}/interests`, { interests });
    return res.data;
};
