import useMediaQuery from "./use-media-query";

export interface IBreakpoints {
    isXs: boolean;
    isSm: boolean;
    isMd: boolean;
    isLg: boolean;
    isXl: boolean;
    is2xl: boolean;
    isMdAndAbove: boolean;
    isLgAndAbove: boolean;
    isXlAndAbove: boolean;
    isXsToLg: boolean;
    active: string;
}

export default function useBreakpoints(): IBreakpoints {
    const breakpoints = {
        isXs: useMediaQuery("(max-width: 640px)"),
        isSm: useMediaQuery("(min-width: 641px) and (max-width: 768px)"),
        isMd: useMediaQuery("(min-width: 769px) and (max-width: 1024px)"),
        isLg: useMediaQuery("(min-width: 1024px) and (max-width: 1280px)"),
        isXl: useMediaQuery("(min-width: 1281px) and (max-width: 1536px)"),
        is2xl: useMediaQuery("(min-width: 1537px)"),
        isXsToLg: useMediaQuery("(max-width: 1024px)"),
        isMdAndAbove: useMediaQuery("(min-width: 769px)"),
        isLgAndAbove: useMediaQuery("(min-width: 1024px)"),
        isXlAndAbove: useMediaQuery("(min-width: 1280px)"),
        active: "xs",
    };

    if (breakpoints.isXs) breakpoints.active = "xs";

    if (breakpoints.isSm) breakpoints.active = "sm";

    if (breakpoints.isMd) breakpoints.active = "md";

    if (breakpoints.isLg) breakpoints.active = "lg";

    if (breakpoints.isXl) breakpoints.active = "xl";

    if (breakpoints.is2xl) breakpoints.active = "2xl";

    return breakpoints;
}
