/* eslint-disable no-underscore-dangle */
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import React, { FC } from "react";

import PlayerController from "./player-controller";
import PlayerQueueList from "./player-queue/player-queue-list";

const Player: FC = () => {
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const playerSlideIn = PlayerSettingsStore((state) => state.playerSlideIn);

    const rightPosition = playerSlideIn ? "-right-80" : currentPlayerItem ? "right-0" : "-right-672";
    return (
        <div className={`fixed bottom-14 z-50 w-480 select-none transition duration-1000 lg:bottom-10 ${rightPosition}`}>
            <PlayerQueueList />
            <PlayerController />
        </div>
    );
};

export default Player;
