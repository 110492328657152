import React, { FC } from "react";

export interface Props {
    currentProgressPoint: number;
    nextBulletNumber: number;
}

const ProgressBulletDivider: FC<Props> = ({ currentProgressPoint, nextBulletNumber }: Props) => (
    <hr
        className={`w-4 self-center sm:w-14 md:w-20 ${currentProgressPoint >= nextBulletNumber ? `border-rc-pink` : `border-rc-gray-light`} ${
            currentProgressPoint > nextBulletNumber ? `opacity-50` : `opacity-100`
        }`}
    />
);
export default ProgressBulletDivider;
