import create from "zustand";
import { devtools } from "zustand/middleware";

export interface IStreamingNetwork {
    id: number;
    key?: string;
    name: string;
    logoUrl?: string;
}

interface IStreamingNetworkState {
    streamingNetworks: IStreamingNetwork[];
    setStreamingNetworks: (streamingNetworks: IStreamingNetwork[]) => void;
    selectedStreamingNetworks: IStreamingNetwork[];
    setSelectedStreamingNetworks: (streamingNetworks: IStreamingNetwork[]) => void;
    toggleSelectedStreamingNetwork: (streamingNetwork: IStreamingNetwork) => void;
}

const StreamingNetworkStore = create<IStreamingNetworkState>(
    devtools(
        (set) => ({
            streamingNetworks: [],
            setStreamingNetworks: (streamingNetworks: IStreamingNetwork[]) => set(() => ({ streamingNetworks })),
            selectedStreamingNetworks: [],
            setSelectedStreamingNetworks: (streamingNetworks: IStreamingNetwork[]) => set(() => ({ selectedStreamingNetworks: streamingNetworks })),
            toggleSelectedStreamingNetwork: (streamingNetwork: IStreamingNetwork) =>
                set((state) => {
                    const streamingNetworks = [...state.selectedStreamingNetworks];
                    const index = streamingNetworks.findIndex((g) => g.name === streamingNetwork.name);
                    if (index === -1) {
                        streamingNetworks.push(streamingNetwork);
                    } else {
                        streamingNetworks.splice(index, 1);
                    }
                    state.setSelectedStreamingNetworks(streamingNetworks);
                }),
        }),
        { name: "StreamingNetworkStore" },
    ),
);

export default StreamingNetworkStore;
