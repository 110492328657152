import ButtonOutline from "@components/buttons/button-outline";
import { faBell, faBellSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteSubscription, getSubInfos, subscription } from "@helper/youtube/youtube-api-helper";
import { PostAuthor } from "@models/post";
import React, { FC, useEffect, useState } from "react";

export interface Properties {
    channelId: string;
    outline?: boolean;
}

const YoutubeSubButton: FC<Properties> = ({ channelId, outline = false }: Properties) => {
    const [subInformation, setSubInformation] = useState<PostAuthor>();

    useEffect(() => {
        if (channelId)
            getSubInfos(channelId)
                .then((information) => setSubInformation(information))
                .catch(console.log);
    }, [channelId]);

    const subscribeChannel = () => {
        if (channelId) {
            subscription(channelId)
                .then((sub) => setSubInformation(sub))
                .catch(console.log);
        }
    };
    const unsubscribeChannel = () => {
        if (subInformation) {
            deleteSubscription(subInformation.id);
            setSubInformation(undefined);
        }
    };
    if (outline)
        return (
            <>
                {subInformation ? (
                    <ButtonOutline onClick={() => unsubscribeChannel()} className="float-left mr-3">
                        <FontAwesomeIcon icon={faBellSlash} className="mr-2 h-4 w-4 self-center" />

                        <span className="self-center text-sm">Unsubscribe</span>
                    </ButtonOutline>
                ) : (
                    <ButtonOutline onClick={() => subscribeChannel()} className="float-left mr-3">
                        <FontAwesomeIcon icon={faBell} className="mr-2 h-4 w-4 self-center" />

                        <span className="self-center text-sm">Subscribe</span>
                    </ButtonOutline>
                )}
            </>
        );

    return (
        <div>
            {subInformation ? (
                <div className="self-center ">
                    <div
                        onClick={() => unsubscribeChannel()}
                        className="cursor-pointer self-center rounded-lg bg-rc-pink px-4 py-1 text-center text-white transition duration-200 hover:bg-pink-600"
                    >
                        <FontAwesomeIcon icon={faBellSlash} className="mr-2" />
                        Unsubscribe
                    </div>
                </div>
            ) : (
                <div className="self-center ">
                    <div
                        onClick={() => subscribeChannel()}
                        className="w-full cursor-pointer self-center rounded-lg bg-rc-blue px-4 py-1 text-center text-white transition duration-200 hover:bg-blue-600"
                    >
                        <FontAwesomeIcon icon={faBell} className="mr-2" />
                        Subscribe
                    </div>
                </div>
            )}
        </div>
    );
};
export default YoutubeSubButton;
