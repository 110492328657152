import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import { VolumeOffIcon, VolumeUpIcon } from "@heroicons/react/solid";
import React, { FC, useState } from "react";

const PlayerVolumeControl: FC = () => {
    const volume = PlayerPersistSettingsStore((state) => state.volume);
    const setVolume = PlayerPersistSettingsStore((state) => state.setVolume);

    const [showSlider, setShowSlider] = useState(false);

    return (
        <div className="my-auto lg:block">
            <div className=" relative " onMouseLeave={() => setShowSlider(false)} onMouseEnter={() => setShowSlider(true)}>
                {volume === 0 ? (
                    <VolumeOffIcon
                        className="z-50 h-5 w-5 cursor-pointer self-center text-white transition hover:text-rc-pink"
                        onClick={() => setVolume(1)}
                    />
                ) : (
                    <VolumeUpIcon
                        className="z-50 h-5 w-5 cursor-pointer self-center text-white transition hover:text-rc-pink "
                        onClick={() => setVolume(0)}
                    />
                )}
                {showSlider && (
                    <div className="absolute -left-6 bottom-12 z-30 h-full">
                        <input
                            type="range"
                            id="volume"
                            name="volume"
                            min="0"
                            max="100"
                            value={volume * 100}
                            onChange={(e) => {
                                setVolume(Number.parseInt(e.target.value, 10) / 100);
                            }}
                            className={`-ml-1 -rotate-90`}
                            style={{ maxWidth: "5rem" }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PlayerVolumeControl;
