import { i18n } from "next-i18next";

export const getIso649 = () => {
    switch (i18n.language) {
        case "de":
            return "de-DE";
        case "en":
            return "en-US";
        default:
            return "en-US";
    }
};
