import Link from "next/link";
import React, { FC } from "react";

export interface Props {
    signUpLabel: string;
    loginLabel: string;
    signUpLink: string;
    loginLink: string;
}

const SignupLoginButtons: FC<Props> = ({ signUpLabel, loginLabel, loginLink, signUpLink }: Props) => {
    return (
        <div className="flex flex-row space-x-4 text-center text-xs text-white">
            <Link href={signUpLink}>
                <a className="cursor-pointer rounded-lg bg-rc-blue px-8 py-2 transition duration-200 hover:bg-blue-600">{signUpLabel}</a>
            </Link>
            <Link href={loginLink}>
                <a className="rounded-lg bg-rc-pink px-8 py-2 transition duration-200 hover:bg-pink-600">{loginLabel}</a>
            </Link>
        </div>
    );
};
export default SignupLoginButtons;
