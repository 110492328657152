import { nextTrack } from "@helper/player/player-helper";
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import React, { FC, useEffect, useRef } from "react";

import Networks from "../../../helper/networks";

const AudioPlayer: FC = () => {
    const audioReference = useRef(new Audio());

    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const isPlaying = PlayerSettingsStore((state) => state.isPlaying);
    const isReady = useRef(false);
    const setPlayerTime = PlayerPersistSettingsStore((state) => state.setPlayerTime);
    const seek = PlayerPersistSettingsStore((s) => s.seek);
    const volume = PlayerPersistSettingsStore((state) => state.volume);

    useEffect(() => {
        audioReference.current.pause();
        if (currentPlayerItem?.platform === Networks.pinterest.name) {
            audioReference.current = new Audio(currentPlayerItem?.audio_url);
        } else {
            audioReference.current = new Audio(currentPlayerItem?.preview_url);
        }
        if (isReady.current) {
            audioReference.current.play();
        } else {
            isReady.current = true;
        }
    }, [currentPlayerItem]);

    useEffect(() => {
        if (seek) {
            audioReference.current.currentTime = Math.round((currentPlayerItem.duration_ms / 1000) * seek);
        }
    }, [seek]);

    useEffect(() => {
        audioReference.current.volume = volume;
    }, [volume]);

    useEffect(() => {
        if (currentPlayerItem?.platform === Networks.pinterest.name) return;
        let interval: NodeJS.Timeout;
        if (audioReference.current) {
            interval = setInterval(() => {
                if (audioReference.current.ended) {
                    nextTrack();
                } else {
                    setPlayerTime(audioReference.current.currentTime * 1000);
                }
            }, 500);
        }
        return () => {
            clearInterval(interval);
        };
    }, [audioReference.current]);

    useEffect(() => {
        return () => {
            audioReference.current.pause();
        };
    }, []);

    useEffect(() => {
        if (isPlaying) audioReference.current.play();
        else audioReference.current.pause();
    }, [isPlaying]);

    useEffect(() => {}, []);
    return <></>;
};

export default AudioPlayer;
