import useIndexState, { IIndexState } from "@helper/custom-hooks/use-index-state";
import useNumberArray, { IUseNumberArray } from "@helper/custom-hooks/use-number-array";
import useOpenClose, { IUseOpenClose } from "@helper/custom-hooks/use-open-close";
import { Dispatch, SetStateAction, useState } from "react";

type DarkLightModeType = "dark" | "light";

export type SelectedOptionType = "Nothing" | "Employees" | "Likes" | "Reviews";

export interface IProfilePicture {
    userId: number;
    picture: string;
    loading: boolean;
}

export interface IUseVisualContext {
    toggleMenuHome: IIndexState;
    toggleMenuProfile: IIndexState;
    toggleMenuCompanyPage: IIndexState;
    darkLightMode: DarkLightModeType;
    toggleDarkLightMode: () => void;
    searchOpenClose: IUseOpenClose;
    navbarOpenClose: IUseOpenClose;
    profileFilterOpenClose: IUseOpenClose;
    quickSettingsOpenClose: IUseOpenClose;
    notificationOpenClose: IUseOpenClose;
    loading: {
        isLoading: boolean;
        setIsLoading: Dispatch<SetStateAction<boolean>>;
    };
    companyInfo: {
        selectedOption: SelectedOptionType;
        setSelectedOption: Dispatch<SetStateAction<SelectedOptionType>>;
    };
    networkSetting: {
        selectedOption: string;
        setSelectedOption: Dispatch<SetStateAction<string>>;
    };
    // TODO: watchedStories will likely be moved when stories are implemented in laravel.
    watchedStories: IUseNumberArray;
    profilePictures: IProfilePicture[];
    setProfilePictures: Dispatch<SetStateAction<IProfilePicture[]>>;
    getProfilePicturesByUserId: (userId: number) => IProfilePicture | undefined;
}

// Context for states that mostly affect how elements is rendered.
const useVisualContext = (): IUseVisualContext => {
    const [darkLightMode, setDarkLightMode] = useState<DarkLightModeType>("light");

    const [selectedOptionCompanyInfo, setSelectedOptionCompanyInfo] = useState<SelectedOptionType>("Nothing");

    const [selectedOptionNetworkSetting, setSelectedOptionNetworkSetting] = useState<string>("");

    const toggleDarkLightMode = () => {
        switch (darkLightMode) {
            case "dark":
                setDarkLightMode("light");
                break;

            default:
                setDarkLightMode("dark");
        }
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [profilePictures, setProfilePictures] = useState<IProfilePicture[]>([]);

    const getProfilePicturesByUserId = (userId: number): IProfilePicture | undefined => profilePictures.find((v) => v.userId === userId);

    return {
        toggleMenuHome: useIndexState(),
        toggleMenuProfile: useIndexState(),
        toggleMenuCompanyPage: useIndexState(),
        darkLightMode,
        toggleDarkLightMode,
        searchOpenClose: useOpenClose(),
        navbarOpenClose: useOpenClose(),
        profileFilterOpenClose: useOpenClose(),
        quickSettingsOpenClose: useOpenClose(),
        notificationOpenClose: useOpenClose(),
        loading: { isLoading, setIsLoading },
        companyInfo: {
            selectedOption: selectedOptionCompanyInfo,
            setSelectedOption: setSelectedOptionCompanyInfo,
        },
        networkSetting: {
            selectedOption: selectedOptionNetworkSetting,
            setSelectedOption: setSelectedOptionNetworkSetting,
        },
        watchedStories: useNumberArray(),
        profilePictures,
        setProfilePictures,
        getProfilePicturesByUserId,
    };
};

export default useVisualContext;
