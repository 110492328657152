import React, { FC } from "react";

export interface IDropdownOption {
    value?: string;
    text: string;
}

interface IDropdown {
    forceSelection?: boolean;
    options: IDropdownOption[];
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
    value?: string;
    className?: string;
    onBlur?: () => void;
}

const DropdownRounded: FC<IDropdown> = ({ options, forceSelection, onChange, value, className, onBlur }: IDropdown) => (
    <select
        className={
            className || "h-8 w-full rounded-md border border-rc-gray-dark bg-transparent text-sm text-black focus:outline-none dark:text-white"
        }
        onChange={onChange}
        value={value || "-"}
        onBlur={onBlur}
    >
        {forceSelection && (
            <option value="-" disabled className="dark:bg-rc-dark">
                -
            </option>
        )}
        {options.map((option) => (
            <option value={option.value} key={option.value} className="dark:bg-rc-dark">
                {option.text}
            </option>
        ))}
    </select>
);

export default DropdownRounded;
