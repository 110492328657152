import PlayerControls from "@components/player/player-controls/player-controls";
import PlayerVolumeControl from "@components/player/player-controls/player-volume-control";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import { msToTime } from "@helper/time-helper";
import React, { FC } from "react";

export interface Properties {
    showOnlySlider?: boolean;
    progress: number;
    handleSeek: (fraction: number) => void;
    handleFullscreen: () => void;
    showForwardIcon?: boolean;
    duration_ms?: number;
    current_ms?: number;
}

const VideoPlayerControls: FC<Properties> = ({
    progress,
    handleSeek,
    handleFullscreen,
    showOnlySlider = false,
    showForwardIcon = false,
    duration_ms,
    current_ms,
}: Properties) => {
    const handleOnClick = (event: any) => {
        const RECT = event.target.getBoundingClientRect();
        const CURSOR_POS = event.clientX - RECT.left;
        const ELEMENT_WIDTH = event.target.clientWidth;

        handleSeek(CURSOR_POS / ELEMENT_WIDTH);
    };

    const playerTime = PlayerPersistSettingsStore((state) => state.playerTime);

    return (
        <>
            <div
                className={`absolute -bottom-12 transition-all ease-in-out ${
                    !showOnlySlider ? "group-hover:bottom-0 group-hover:left-0 " : ""
                } w-full  bg-rc-dark`}
            >
                <div className="cursor-pointer pb-3" onClick={handleOnClick}>
                    <div className="relative h-1 w-full rounded bg-rc-pink">
                        <div className="absolute left-0 h-1 rounded bg-rc-blue" style={{ width: `${progress * 100}%` }} />
                    </div>
                </div>
                <div className="flex flex-row justify-between px-6  pb-2">
                    {!showOnlySlider ? (
                        <>
                            <div className="flex gap-2">
                                {showForwardIcon && <PlayerControls />}
                                {duration_ms ? (
                                    <div className=" self-center pl-3 text-white">
                                        {msToTime(current_ms || playerTime) || "0:00"}/ {msToTime(duration_ms) || " "}
                                    </div>
                                ) : (
                                    " "
                                )}
                            </div>
                            <div className="relative flex flex-row flex-nowrap items-center space-x-5">
                                <div className="flex items-center justify-center">
                                    <PlayerVolumeControl />
                                </div>
                                <div onClick={handleFullscreen} className="flex cursor-pointer text-xl">
                                    <FontAwesomeIcon icon={faExpand} className="self-center text-white" />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="p-4" />
                    )}
                </div>
            </div>
        </>
    );
};
export default VideoPlayerControls;
