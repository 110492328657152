import { useState } from "react";

export interface IUseOpenClose {
    isOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    toggleOpenClose: () => void;
}

const useOpenClose = (initialState = false): IUseOpenClose => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const toggleOpenClose = () => {
        setIsOpen(!isOpen);
    };

    return { isOpen, handleOpen, handleClose, toggleOpenClose };
};

export default useOpenClose;
