import { getLangParam } from "@helper/get-lang-param";
import axios from "axios";

export const apiClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND,
    withCredentials: true,
});

export const getGoogleNews = async (query: string, limit: number = 10, next: number = null) => {
    const res = await apiClient.get(`/google/search/results?${getLangParam()}&q=${encodeURI(query)}&limit=${limit}&next=${next || 1}`);
    return res.data;
};
