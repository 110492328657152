import { availableAuthServices } from "constants/index";
import React from "react";

const ServiceSignInOptions = () => {
    const authWithService = (serviceName: string) => {
        window.open(`${process.env.NEXT_PUBLIC_BACKEND}/auth/${serviceName}`, "_self");
    };

    return (
        <div className="flex flex-col space-y-4 pt-4 xl:flex-row xl:space-x-4 xl:space-y-0">
            {availableAuthServices.map((authService) => (
                <div
                    key={authService.id}
                    onClick={() => authWithService(authService.name)}
                    className="flex w-full cursor-pointer flex-row items-center justify-center space-x-4 rounded bg-rc-black py-2 text-center text-white hover:bg-rc-black-light"
                >
                    <img src={authService.iconSrc} className="h-6 w-6" alt={authService.name} />
                </div>
            ))}
        </div>
    );
};

export default ServiceSignInOptions;
