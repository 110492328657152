import UserCompaniesModal from "@components/companies/user_companies_modal/user_companies_modal";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getActiveCompany, getUserCompanies, isBusinessProfile } from "@helper/auth/companies-helper";
import useOpenClose from "@helper/custom-hooks/use-open-close";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import React, { FC, useMemo } from "react";
import { companyIcon, userIcon } from "styles/icons";

export interface Props {
    name: string;
    handleClose: () => void;
}

const CompanyProfileLink: FC<Props> = ({ handleClose }: Props) => {
    const router = useRouter();
    const openClose = useOpenClose();
    const activeCompany = getActiveCompany() || {
        name: "",
        slug: "",
    };
    const isBusiness = isBusinessProfile();
    const companies = getUserCompanies();
    const handleSwitchCompany = (e) => {
        e.preventDefault();
        e.stopPropagation();
        openClose.handleOpen();
    };

    const icon = isBusiness ? companyIcon("#fff", "w-6 h-6") : userIcon("#fff", "w-6 h-6");

    const menuLabel = useMemo(() => {
        if (isBusiness) {
            return `${activeCompany.name} - ${activeCompany.slug}`;
        }
        return "Profile";
    }, []);

    const onRedirect = (e) => {
        e.preventDefault();
        handleClose();
        if (isBusiness) {
            router.push(`/companies/${activeCompany.slug}/profile/info`);
        } else {
            router.push(`/profile/info`);
        }
    };
    return (
        <div>
            <Link href={"#"}>
                <a onClick={onRedirect}>
                    <div className="flex flex-row items-center gap-1.5 border-b border-rc-gray-light py-4 tracking-wide text-black dark:text-white lg:mb-2 lg:py-0 lg:pb-2">
                        <div className="flex w-10 items-center justify-center ">{icon}</div>
                        <div className="flex w-full grow flex-row justify-between">
                            <span className="ml-2 self-center text-xl lg:text-base">{menuLabel}</span>
                        </div>
                        {companies && companies.length ? (
                            <FontAwesomeIcon size="1x" icon={faRefresh} className="hover:text-rc-pink" onClick={handleSwitchCompany} />
                        ) : null}
                    </div>
                </a>
            </Link>
            <UserCompaniesModal companies={companies} activeCompany={activeCompany} openClose={openClose} handleCloseNavbar={handleClose} />
        </div>
    );
};
export default CompanyProfileLink;
