import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";

interface IAddCompanyProfile {
    onClick: () => void;
}

const AddCompanyProfile: FC<IAddCompanyProfile> = ({ onClick }: IAddCompanyProfile) => {
    return (
        <div className="flex flex-row gap-3.5 py-4 tracking-wide text-black dark:text-white lg:mb-2 lg:py-0 lg:pb-1" onClick={onClick}>
            <div className="flex w-10 items-center justify-center">
                <FontAwesomeIcon icon={faPlus} size="lg" />
            </div>

            <div className="flex w-full grow flex-row justify-between">Create a business profile</div>
        </div>
    );
};
export default AddCompanyProfile;
