import useFilesState, { IUseFilesState } from "@helper/custom-hooks/use-files-state";

export interface IUseFilesContext {
    makePlaylistImage?: IUseFilesState;
    makePostImages?: IUseFilesState;
    makePostAudio?: IUseFilesState;
    profileHeaderImage?: IUseFilesState;
    profilePictureImage?: IUseFilesState;
}

// TODO: Add states for audio, video and other images in other places.
const useFilesContext = (): IUseFilesContext => ({
    makePlaylistImage: useFilesState(),
    makePostImages: useFilesState(),
    makePostAudio: useFilesState(),
    profileHeaderImage: useFilesState(),
    profilePictureImage: useFilesState(),
});

export default useFilesContext;
