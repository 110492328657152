import { Dispatch, SetStateAction, useState } from "react";

export interface IUseFilesState {
    files: File[];
    fileAsObjectURL: string | undefined;
    setFileAsObjectURL: Dispatch<SetStateAction<string | undefined>>;
    setFiles: Dispatch<SetStateAction<File[]>>;
    errors: string[] | undefined;
    onFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    emptyFilesList: () => void;
    getFirstFileURLFromFileList: () => string | undefined;
    getFirstFileAsBlob: () => Blob | undefined;
    getAllFileURLsFromFileList: () => string[];
    removeSingleFileByIndex: (index: number) => void;
}

const useFilesState = (): IUseFilesState => {
    const [files, setFiles] = useState<File[]>([]);
    const [errors, setErrors] = useState<string[]>();
    const [fileAsObjectURL, setFileAsObjectURL] = useState<string | undefined>(undefined);

    const emptyFilesList = () => {
        setFiles([]);
        setFileAsObjectURL(undefined);
    };

    const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let tmpErrors: string[] = [];
        if (event.target.files) {
            [...event.target.files].forEach((file) => {
                if (file.size > 50_000_000) {
                    tmpErrors = [...tmpErrors, `The file must be smaller than 50MB. Your file is ${(file.size / 1_000_000).toFixed(2)} MB in size`];
                }
            });
            if (tmpErrors.length === 0) {
                setFiles([...files, ...event.target.files]);
                setErrors(undefined);
            } else {
                console.error(tmpErrors);
                setErrors(tmpErrors);
            }
        }
    };

    const getFirstFileURLFromFileList = (): string | undefined => {
        if (files[0]) return URL.createObjectURL(files[0]) || undefined;

        return undefined;
    };

    const getFirstFileAsBlob = (): Blob | undefined => {
        if (files[0]) return files[0] || undefined;

        return undefined;
    };

    const getAllFileURLsFromFileList = (): string[] => files.map((file) => URL.createObjectURL(file));

    const removeSingleFileByIndex = (index: number): void => setFiles(files.filter((_, i) => i !== index));

    return {
        files,
        setFiles,
        fileAsObjectURL,
        setFileAsObjectURL,
        errors,
        onFileUpload,
        emptyFilesList,
        getFirstFileURLFromFileList,
        getAllFileURLsFromFileList,
        removeSingleFileByIndex,
        getFirstFileAsBlob,
    };
};

export default useFilesState;
