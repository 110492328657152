import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { updatePlayingPosition } from "@helper/player/queue-handler";
import { msToTime } from "@helper/time-helper";
import { IPlayerItem } from "@models/player/player-item";
import React, { FC } from "react";

import PlayerTicker from "./player-ticker";

export interface Properties {
    item: IPlayerItem;

    index: number;
}

const PlayerListRow: FC<Properties> = ({ item, index }: Properties) => {
    const duration = typeof item?.duration_ms === "number" ? msToTime(item?.duration_ms) : " ";
    const currentPlayerList = PlayerStore((state) => state.currentPlayerList);
    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);
    const setCurrentPlayerItem = PlayerStore((state) => state.setCurrentPlayerItem);

    const handlePlayTrack = (): void => {
        setCurrentPlayerItem(item);
        setIsPlaying(true);
        if (
            currentPlayerList.type.toLocaleLowerCase() === "album" ||
            currentPlayerList.type.toLocaleLowerCase() === "apple_album" ||
            currentPlayerList.type.toLocaleLowerCase() === "spotify_playlist"
        ) {
            updatePlayingPosition(index + currentPlayerList.playing_position + 1);
        } else {
            updatePlayingPosition(item.position);
        }
    };

    return (
        <div className="flex flex-row p-2" id={item?.position?.toString()}>
            <img onClick={handlePlayTrack} className="my-auto h-10 w-10 cursor-pointer object-cover" alt={item?.name} src={item?.image_url} />
            <div onClick={handlePlayTrack} className="my-auto flex w-full cursor-pointer flex-col pl-2">
                <div>
                    <PlayerTicker onlyOnHover maxWidth="11.5rem" text={item?.name} />
                    {Array.isArray(item?.artists) && (
                        <div className="w-44 overflow-hidden truncate  text-xs text-rc-gray-light">
                            {item?.artists?.map((v) => v?.name).join(", ")}
                        </div>
                    )}
                </div>
            </div>
            <div className="flex flex-row space-x-2 self-center">
                <p className=" dark:text-white ">{duration}</p>
            </div>
        </div>
    );
};

export default PlayerListRow;
