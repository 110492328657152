import Modal from "@components/modal/modal";
import ProgressBulletBar from "@components/progress-bullet-bar/progress-bullet-bar";
import SignUpEmailPassword from "@components/sign-up-process/sign-up-email-password";
import SignUpInterests from "@components/sign-up-process/sign-up-interests";
import SignUpLanguage from "@components/sign-up-process/sign-up-language";
import SignUpNameBirthday from "@components/sign-up-process/sign-up-name-birthday";
import SignUpNetworks from "@components/sign-up-process/sign-up-networks";
import useSignUp from "@helper/custom-hooks/use-sign-up";
import { useTranslation } from "next-i18next";
import React, { FC, useEffect, useState } from "react";

const defaultBlocked = [1, 2, 3, 4];

const SignUpModal: FC = () => {
    const [blocked, setBlocked] = useState(defaultBlocked);
    const { step, setStep, openClose } = useSignUp();

    const { t } = useTranslation();

    const SIGN_UP_STEPS = [
        <SignUpEmailPassword key="SignUpEmailPassword" />,
        <SignUpNameBirthday key="SignUpNameBirthday" />,
        <SignUpLanguage key="SignUpLanguage" />,
        <SignUpInterests key="SignUpInterests" />,
        <SignUpNetworks key="SignUpNetworks" />,
    ];

    useEffect(() => {
        const newBlocked = blocked.filter((v) => v !== step);
        setBlocked(newBlocked);
    }, [step]);

    useEffect(() => {
        if (!openClose.isOpen) {
            setBlocked(defaultBlocked);
            setStep(0);
        }
    }, [openClose.isOpen]);

    return (
        <Modal backdropStatic handler={openClose} modalContenClass="">
            <div className="flex flex-col items-center gap-10">
                <img src={"/logo_white.png"} alt="Realclixx Logo" className="w-1/4 object-contain uwd:w-480"></img>
                <div>
                    <div className="mb-10 flex justify-center">
                        <span className={`font-headline text-6xl font-bold text-white`}>{t("generic.signup")}</span>
                    </div>
                    <ProgressBulletBar currentProgressPoint={step} steps={SIGN_UP_STEPS.length} blocked={blocked} />
                    {SIGN_UP_STEPS[step]}
                </div>
            </div>
        </Modal>
    );
};

export default SignUpModal;
