import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { ViewListIcon } from "@heroicons/react/solid";
import React, { FC } from "react";

import PlayerControls from "./player-controls/player-controls";
import PlayerMenu from "./player-controls/player-menu";
import PlayerTimeRage from "./player-controls/player-time-rage";
import PlayerVolumeControl from "./player-controls/player-volume-control";
import PlayerCoverImage from "./player-cover-image";
import PlayerHandler from "./player-handler";
import PlayerItemInfo from "./player-item-info";

export interface Properties {}

const PlayerController: FC<Properties> = () => {
    const setQueueListOpen = PlayerSettingsStore((state) => state.setQueueListOpen);
    const queueListOpen = PlayerSettingsStore((state) => state.queueListOpen);
    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);
    const setCurrentPlayerItem = PlayerStore((state) => state.setCurrentPlayerItem);
    const setCurrentPlayerList = PlayerStore((state) => state.setCurrentPlayerList);
    const setShuffleList = PlayerStore((state) => state.setShuffleList);
    const setHistory = PlayerStore((state) => state.setHistory);
    const setQueue = PlayerStore((state) => state.setQueue);

    const clearPlayer = () => {
        setIsPlaying(false);
        setQueueListOpen(false);
        setQueue([]);
        setCurrentPlayerList(undefined);
        setCurrentPlayerItem(undefined);
        setHistory(undefined);
        setShuffleList(undefined);
    };

    return (
        <div className="shadow-xl">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-rc-pink to-rc-blue lg:rounded-l-lg" />
            <div className="relative bg-white dark:bg-rc-dark lg:rounded-l-lg">
                <PlayerHandler />
                <div className="flex">
                    <PlayerCoverImage />
                    <div className="w-full px-2">
                        <div className="my-auto flex w-full flex-row pl-2 lg:flex-col">
                            <PlayerItemInfo />
                            <PlayerTimeRage />
                            <div className="flex flex-row justify-end pt-3 lg:justify-between">
                                <PlayerControls />

                                <div className="flex flex-row space-x-3">
                                    <PlayerMenu />
                                    <ViewListIcon
                                        className="h-7 w-7 cursor-pointer self-center text-white transition hover:text-rc-pink dark:hover:text-rc-pink lg:h-5 lg:w-5"
                                        onClick={() => setQueueListOpen(!queueListOpen)}
                                    />
                                    <PlayerVolumeControl />
                                    <FontAwesomeIcon
                                        onClick={clearPlayer}
                                        icon={faPowerOff}
                                        className="hidden h-5 w-5 cursor-pointer self-center transition hover:text-rc-pink dark:text-white dark:hover:text-rc-pink lg:block"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlayerController;
