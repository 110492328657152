import {
    DASHBOARD_VIRTUAL_TOUR_KEY,
    DASHBOARD_VIRTUAL_TOUR_STEPS,
    MULTIMEDIA_VIRTUAL_TOUR_KEY,
    MULTIMEDIA_VIRTUAL_TOUR_STEPS,
    PROFILE_VIRTUAL_TOUR_KEY,
    PROFILE_VIRTUAL_TOUR_STEPS,
    SETTING_VIRTUAL_TOUR_STEPS,
    SETTING_VIRTUAL_TOUR_KEY,
} from "constants/virtual-tour";

export const removeLocalStorageDashboardVirtualTour = () => {
    Object.values(DASHBOARD_VIRTUAL_TOUR_STEPS).forEach((step) => {
        const stepId = `${DASHBOARD_VIRTUAL_TOUR_KEY}_${step.target}`;
        localStorage.removeItem(stepId);
    });
};

export const removeLocalStorageProfileVirtualTour = () => {
    Object.values(PROFILE_VIRTUAL_TOUR_STEPS).forEach((step) => {
        const stepId = `${PROFILE_VIRTUAL_TOUR_KEY}_${step.target}`;
        localStorage.removeItem(stepId);
    });
};

export const removeLocalStorageMultimediaVirtualTour = () => {
    Object.values(MULTIMEDIA_VIRTUAL_TOUR_STEPS).forEach((step) => {
        const stepId = `${MULTIMEDIA_VIRTUAL_TOUR_KEY}_${step.target}`;
        localStorage.removeItem(stepId);
    });
};

export const removeLocalStorageSettingVirtualTour = () => {
    Object.values(SETTING_VIRTUAL_TOUR_STEPS).forEach((step) => {
        const stepId = `${SETTING_VIRTUAL_TOUR_KEY}_${step.target}`;
        localStorage.removeItem(stepId);
    });
};

export const removeLocalStorageVirtualTour = () => {
    removeLocalStorageDashboardVirtualTour();
    removeLocalStorageProfileVirtualTour();
    removeLocalStorageMultimediaVirtualTour();
    removeLocalStorageSettingVirtualTour();
};
