import useOnClickOutside from "@helper/custom-hooks/use-on-click-outside";
import useOpenClose from "@helper/custom-hooks/use-open-close";
import SearchStore from "@helper/search/search-store";
import { searchFilter } from "@helper/search-filter";
import { AdjustmentsIcon, XIcon } from "@heroicons/react/solid";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";

const SearchFilterDropdown: FC = () => {
    const setFilterType = SearchStore((state) => state.setFilterType);
    const filterType = SearchStore((state) => state.filterType);
    const openClose = useOpenClose();
    const reference = useRef(null);
    const { t } = useTranslation();
    useOnClickOutside(reference, openClose.handleClose);

    return (
        <div className="z-50 text-sm" ref={reference}>
            <div className={`flex h-8 cursor-pointer`} onClick={openClose.toggleOpenClose}>
                <div className="my-auto pr-2">
                    <AdjustmentsIcon
                        className={`right-2 top-2 h-6 w-6 transition hover:text-rc-pink ${
                            openClose.isOpen ? "rotate-180 text-rc-blue" : ""
                        } duration-300  ease-in-out`}
                    />
                </div>
            </div>
            {openClose.isOpen && (
                <div className=" absolute bg-rc-dark">
                    <div className="grid grid-cols-3 gap-2 pt-3">
                        {Object.keys(searchFilter).map((f) => (
                            <div
                                className={`cursor-pointer py-1 px-4  ${
                                    searchFilter[f].id === filterType?.id ? "text-rc-pink" : "hover:text-rc-pink"
                                }`}
                                key={searchFilter[f].name}
                                onClick={() => {
                                    openClose.handleClose();
                                    setFilterType(searchFilter[f]);
                                }}
                            >
                                <span className={`flex whitespace-nowrap ${searchFilter[f].id === filterType?.id ? "border-b border-rc-pink" : ""}`}>
                                    {t(searchFilter[f].name)}
                                    {searchFilter[f].id === filterType?.id && (
                                        <span className="w-full">
                                            <XIcon className=" float-right my-auto h-4" />
                                        </span>
                                    )}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SearchFilterDropdown;
