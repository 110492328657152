import { useState, useEffect } from "react";

export interface IUseFadeInOut {
    isOpen: boolean;
    className: string;
}

/**
 * To use this hook, feed it with the open/close boolean from your component and conditionally render it with the returned _.isOpen.
 * This way it will close after the fade-out animation finished.
 * The element that should fade in and out needs the additional className from _.className.
 * The animation classes "animate-fade-in" and "animate-fade-out" returned here needs to exist as tailwind or css class.
 * (Note: To avoid flickering set a slightly longer duration for the css animation than the hook timeout)
 */

const useFadeInOut = (open: boolean, fadeOutTimeoutInMS = 150, fadeOutSlow = false): IUseFadeInOut => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (open) setIsOpen(true);

        if (!open) setTimeout(() => setIsOpen(false), fadeOutTimeoutInMS);
    }, [open, fadeOutTimeoutInMS]);

    return open
        ? {
              isOpen,
              className: fadeOutSlow ? "animate-fade-in" : "animate-fade-in-fast",
          }
        : {
              isOpen,
              className: fadeOutSlow ? "animate-fade-out-500ms" : "animate-fade-out-fast",
          };
};

export default useFadeInOut;
