import React, { FC, KeyboardEventHandler, useState } from "react";

export interface Props {
    id?: string;
    name: string;
    label: string;
    type: string;
    symbolInFrontOfInput?: string;
    // Made temporarily optional. Should be required props later on.
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    value?: string;
    required?: boolean;
    onBlurCapture?: React.FocusEventHandler<HTMLInputElement>;
    onFocusCapture?: React.FocusEventHandler<HTMLInputElement>;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    defaultValue?: string;
    inputRef?: any;
    autocomplete?: string;
    customClassName?: string;
}

const InputField: FC<Props> = ({
    id,
    name,
    label,
    type,
    onChange,
    value,
    inputRef,
    symbolInFrontOfInput,
    required,
    onBlurCapture,
    onFocusCapture,
    defaultValue,
    onKeyDown,
    autocomplete,
    customClassName,
}: Props) => {
    const [hasFocus, setHasFocus] = useState(false);

    return (
        <div className={`relative flex w-full border-b-4 border-rc-gray-dark focus-within:border-blue-500 dark:text-white ${customClassName}`}>
            {symbolInFrontOfInput && (hasFocus || (value && value.length > 0)) && <span>{symbolInFrontOfInput}</span>}
            <input
                id={id}
                autoComplete={autocomplete}
                type={type}
                ref={inputRef}
                name={name}
                placeholder=" "
                onChange={onChange}
                value={value}
                defaultValue={defaultValue}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
                className="block w-full appearance-none bg-transparent focus:outline-none dark:text-white"
                required={required}
                onBlurCapture={onBlurCapture}
                onFocusCapture={onFocusCapture}
                onKeyDown={onKeyDown}
            />
            <label
                id={id}
                htmlFor={name}
                className="pointer-events-none absolute top-0 origin-0 truncate duration-300"
                dangerouslySetInnerHTML={{
                    __html: `${label} ${required ? '<span class="text-rc-pink">*</span>' : ""}`,
                }}
            />
        </div>
    );
};

export default InputField;
