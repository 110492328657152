import Button from "@components/buttons/button";
import ButtonSkip from "@components/buttons/button-skip";
import InterestsGrid from "@components/interests-grid/interests-grid";
import useSignUp from "@helper/custom-hooks/use-sign-up";
import { getListOfInterests, updateInterestsForUser } from "@helper/interests/interests-api-helper";
import { IInterests } from "@models/interests/interests-response";
import { useTranslation } from "next-i18next";
import React, { FC, useEffect, useState } from "react";

const SignUpInterests: FC = () => {
    const { user, setUser, nextStep } = useSignUp();
    const [loading, setLoading] = useState(false);
    const [newInterests, setNewInterests] = useState<string[]>([]);
    const [allInterests, setAllInterests] = useState<IInterests[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        getListOfInterests(user.gender)
            .then((e) => setAllInterests(e))
            .catch((e) => console.error(e));
    }, []);

    const handleOnClick = async () => {
        try {
            setLoading(true);
            await updateInterestsForUser(newInterests);
            setUser({ ...user, interests: newInterests });
            nextStep();
        } catch (error) {
            console.error("Fail to update interests", error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddInterests = (data: string[]) => {
        if (data.length <= 10) {
            setNewInterests(data);
        }
    };

    const title = newInterests.length < 5 ? `You need to pick at least ${5 - newInterests.length} more topics` : "Continue";

    return (
        <>
            <div className="flex flex-col justify-center py-5 ">
                <span className="font-headline px-10 text-center text-3xl text-white">{t("signup.which_topics_interesting")}</span>
                <span className="mt-2 text-center text-base text-white">This will customise your new home feed</span>
            </div>
            {/* TODO: Remove Mock */}
            <InterestsGrid
                tiles={allInterests}
                interests={{
                    newInterests,
                    setNewInterests: handleAddInterests,
                }}
            />
            {/* TODO Add slice for mobile */}
            <div className="mt-4 flex justify-between">
                <ButtonSkip className="text-white" title={"Skip"} onClick={nextStep} />
                <Button title={title} disabled={newInterests.length < 5 || loading} onClick={handleOnClick} loading={loading} />
            </div>
        </>
    );
};

export default SignUpInterests;
