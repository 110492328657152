/* eslint-disable no-underscore-dangle */
import Networks from "@helper/networks";
import { nextTrack } from "@helper/player/player-helper";
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { spotifyPlayTrack } from "@helper/spotify/spotify-api-helper";
import { msToTime } from "@helper/time-helper";
import { FC, useEffect } from "react";
import { useErrorState, usePlaybackState, usePlayerDevice, useSpotifyPlayer } from "react-spotify-web-playback-sdk";
import { toast } from "react-toastify";

const SpotifyHandler: FC = () => {
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);

    const seek = PlayerPersistSettingsStore((state) => state.seek);
    const volume = PlayerPersistSettingsStore((state) => state.volume);
    const setPlayerTime = PlayerPersistSettingsStore((state) => state.setPlayerTime);
    const isPlaying = PlayerSettingsStore((state) => state.isPlaying);

    const device = usePlayerDevice();
    const playbackState = usePlaybackState(true, 0.5);
    const player = useSpotifyPlayer();

    const isSpotify = Networks.spotify.name === currentPlayerItem?.platform;

    const errorState = useErrorState();

    useEffect(() => {
        if (errorState && errorState.type === "account_error" && isSpotify) {
            toast("You need the Spotify Premium subscription to listen to Spotify songs", {
                toastId: "spotify-premium-required",
            });
        }
    }, [errorState]);

    useEffect(() => {
        if (!player || !isSpotify) return;
        player.connect();
    }, [player]);

    useEffect(() => {
        if (!player || !isSpotify) return;
        if (isPlaying && !device)
            spotifyPlayTrack(currentPlayerItem.uri)
                .then(() => {
                    player.resume();
                })
                .catch((e) => {
                    if (e?.response?.data?.error?.reason === "PREMIUM_REQUIRED" && isSpotify) {
                        toast("You need the Spotify Premium subscription to listen to Spotify songs", { toastId: "spotify-premium-required" });
                    }
                });
        else player.pause();
    }, [isPlaying, device]);

    useEffect(() => {
        if (!player || !isSpotify) return;
        player.seek(currentPlayerItem.duration_ms * seek);
    }, [seek]);

    useEffect(() => {
        if (!player || !isSpotify) return;
        player.setVolume(volume);
    }, [volume]);

    useEffect(() => {
        if (player && !isSpotify) {
            player.pause();
            return;
        }
        if (!device || device.status !== "ready" || !player || !isPlaying) return;
        if (currentPlayerItem.uri === playbackState?.track_window?.current_track?.uri) {
            player.resume();
        } else {
            spotifyPlayTrack(currentPlayerItem.uri, device.device_id)
                .then(() => {
                    player.resume();
                })
                .catch((e) => {
                    if (e?.response?.data?.error?.reason === "PREMIUM_REQUIRED" && isSpotify) {
                        toast("You need the Spotify Premium subscription to listen to Spotify songs", { toastId: "spotify-premium-required" });
                    }
                });
        }
    }, [currentPlayerItem, device, isPlaying]);

    useEffect(() => {
        if (!playbackState) return;
        if (isSpotify) setPlayerTime(playbackState.position);

        // Play next Track
        if (msToTime(playbackState.position) === msToTime(currentPlayerItem?.duration_ms) && isSpotify) {
            player
                .pause()
                .then(() => nextTrack())
                .catch(console.error);
        }

        // Fetches the current track that is playing on spotify
        // if (
        //   currentPlayerItem.uri !== playbackState.track_window.current_track?.uri &&
        //   !playbackState.paused
        // ) {
        //   const spotifyTrack = playbackState.track_window.current_track
        //   setCurrentPlayerItem({
        //     resource_id: spotifyTrack.id,
        //     platform: 'Spotify',
        //     name: spotifyTrack.name,
        //     uri: spotifyTrack.uri,
        //     media_type: 'audio',
        //     type: spotifyTrack.type,
        //     image_url:
        //       (spotifyTrack.album?.images && spotifyTrack.album.images[0]?.url) ||
        //       '',
        //     album: {
        //       resource_id: spotifyTrack.album.uri?.replace('spotify:album:', ''),
        //       name: spotifyTrack.album.name,
        //     },
        //     artists: Array.isArray(spotifyTrack.artists)
        //       ? spotifyTrack.artists.map((a) => ({
        //           name: a?.name,
        //           uri: a?.uri,
        //           resource_id: a.uri.replace('spotify:artist:', ''),
        //         }))
        //       : undefined,
        //     duration_ms: spotifyTrack.duration_ms,
        //     is_list_item: false,
        //   })
        // }
    }, [playbackState]);

    return <></>;
};

export default SpotifyHandler;
