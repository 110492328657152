import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";

export interface Properties {
    title: string;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
}

const ButtonSkip: FC<Properties> = ({ onClick, className, disabled, title, loading }: Properties) => (
    <button
        disabled={disabled}
        type="button"
        className={`relative px-5 py-2 text-base font-bold uppercase disabled:cursor-not-allowed disabled:opacity-50 ${
            className ? ` ${className}` : ""
        }`}
        onClick={onClick}
    >
        {" "}
        {loading ? <FontAwesomeIcon icon={faSpinner} className="ml-1 animate-spin" /> : title}
    </button>
);
export default ButtonSkip;
