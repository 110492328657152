import { apiClient } from "@helper/auth/auth-api-helper";
import { IPlayerItem } from "@models/player/player-item";

export async function createPlaylist(name: string) {
    const data = await apiClient.post("/playlist", { name });
    return data;
}
export async function removePlaylist(id: string) {
    const data = await apiClient.delete(`/playlist/${id}`);
    return data;
}
export async function updatePlaylistName(id: string, name: string) {
    const data = await apiClient.patch(`/playlist/${id}/name`, { name });
    return data;
}

export async function getPlaylists(username?: string) {
    const url = username ? `/playlist?username=${username}` : "/playlist";
    const data = await apiClient.get(url);
    return data.data;
}
export async function getPlaylistById(id: string) {
    const data = await apiClient.get(`/playlist/${id}`);
    return data.data;
}
export async function addItemInPlaylist(id: string, item: IPlayerItem) {
    const data = await apiClient.patch(`/playlist/${id}`, item);
    return data.data;
}

export async function newPlaylistItems(id: string, items: IPlayerItem[]) {
    const data = await apiClient.patch(`/playlist/${id}/items`, { items });
    return data.data;
}

export async function deleteItemFromPlaylist(playlist_id: string, item_id: string) {
    const data = await apiClient.delete(`/playlist/${playlist_id}/items/${item_id}`);
    return data.data;
}
