import Button from "@components/buttons/button";
import Modal from "@components/modal/modal";
import useOpenClose from "@helper/custom-hooks/use-open-close";
import { addItemInPlaylist, getPlaylists } from "@helper/playlist/playlist-api-helper";
import { PlusIcon } from "@heroicons/react/solid";
import { IPlayerItem } from "@models/player/player-item";
import { useTranslation } from "next-i18next";
import React, { FC, useEffect, useState } from "react";
import { happyEmoji } from "styles/icons";

import CoverBuilder from "./cover-builder";
import CreatePlaylist from "./create-playlist";

interface IAddToPlaylistModal {
    item: IPlayerItem | undefined;
    isOpen: boolean;
    handleClose: () => void;
}

// TODO wip
const AddToPlaylistModal: FC<IAddToPlaylistModal> = ({ item, isOpen, handleClose }: IAddToPlaylistModal) => {
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [playlists, setPlaylists] = useState([]);
    const createPlaylistComponent = useOpenClose();
    const { t } = useTranslation();

    const handleAddToPlaylist = async (id: string, newItem: IPlayerItem) => {
        try {
            await addItemInPlaylist(id, newItem);
            setShowSuccessMessage(true);
            handleClose();
        } catch (e) {
            console.error("Fail to add item in playlist", e);
        }
    };
    const closeCreate = async () => {
        try {
            const data = await getPlaylists();
            setPlaylists(data);
            createPlaylistComponent.handleClose();
        } catch (e) {
            console.error("Fail to get playlist", e);
        }
    };
    useEffect(() => {
        getPlaylists()
            .then((p) => setPlaylists(p))
            .catch(console.error);
    }, []);

    return (
        <Modal
            title={t("playlist.add")}
            handler={{
                isOpen,
                handleClose,
                handleOpen: () => {},
                toggleOpenClose: () => {},
            }}
        >
            <div className="flex flex-col">
                <div>
                    {createPlaylistComponent.isOpen ? (
                        <CreatePlaylist handleClose={closeCreate} />
                    ) : (
                        <div className="my-3 grid grid-cols-3 gap-3">
                            <div className="group cursor-pointer" onClick={createPlaylistComponent.handleOpen}>
                                <div className="flex h-60 justify-center bg-rc-gray-dark group-hover:bg-opacity-50">
                                    <PlusIcon className="size-20 self-center" />
                                </div>
                            </div>
                            {playlists?.map((v) => {
                                return (
                                    <div
                                        key={`AddToPlaylistModalItem_${v?.id}`}
                                        className="group relative cursor-pointer"
                                        onClick={() => handleAddToPlaylist(v?.id, item)}
                                    >
                                        <div>
                                            {showSuccessMessage ? (
                                                <div className="absolute hidden size-full group-hover:block">
                                                    <div className="flex h-60 w-full flex-col justify-center bg-rc-black bg-opacity-75 text-center">
                                                        {happyEmoji("h-10 w-10 opacity-100 inline-block mx-auto")}
                                                        <span>{t("playlist.add_success")}</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="absolute hidden size-full group-hover:block">
                                                    <div className="flex h-60 w-full flex-col justify-center bg-rc-black bg-opacity-75 text-center">
                                                        <PlusIcon className="size-14 self-center" />
                                                        <span>{t("playlist.add_to", { playlistname: v.name })}</span>
                                                    </div>
                                                </div>
                                            )}
                                            {/* <CollectionIcon className="h-60 w-full object-cover " /> */}
                                            {v?.cover_picture ? (
                                                <img src={v.cover_picture} alt="" className="h-60 w-full object-cover " />
                                            ) : (
                                                <CoverBuilder playlist={v} className="h-60 overflow-hidden" />
                                            )}
                                        </div>
                                        <span>{v?.name}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
                {!createPlaylistComponent.isOpen && <Button title={t("generic.close")} onClick={handleClose} />}
            </div>
        </Modal>
    );
};

export default AddToPlaylistModal;
