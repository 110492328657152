import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, ReactNode } from "react";

export interface IButtonOutline {
    children: ReactNode;
    onClick?: () => void;
    small?: boolean;
    className?: string;
    loading?: boolean;
}

const ButtonOutline: FC<IButtonOutline> = ({ children, onClick, className = "", small = false, loading }: IButtonOutline) => {
    return (
        <div
            onClick={onClick}
            className={`${className} cursor-pointer rounded-md bg-gradient-to-r from-rc-pink to-rc-blue p-0.5 tracking-wider text-black dark:text-white`}
        >
            <div
                className={`${
                    !small ? "px-6 text-sm" : "text-xs"
                }  flex justify-center  rounded-md bg-rc-dark px-2 py-1 transition hover:bg-transparent`}
            >
                {loading ? <FontAwesomeIcon icon={faSpinner} className="ml-1 animate-spin" /> : children}
            </div>
        </div>
    );
};
export default ButtonOutline;
