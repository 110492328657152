import { IAyrshareJWTLink } from "@models/ayrshare/ayrshare";
import axios from "axios";

export const ayrshareClient = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_BACKEND}/ayrshare`,
    withCredentials: true,
});

export const getGeneratedJWTLink = async (redirect: string = "close") => {
    const res = await ayrshareClient.get<IAyrshareJWTLink>(`/profiles-generated-jwt?redirect=${redirect}`);

    if (res?.status === 200) {
        return res.data?.url;
    }

    return "";
};

export const getLinkedSocial = async () => {
    const res = await ayrshareClient.get<string[]>("/linked-social");

    if (res?.status === 200) {
        return res.data || [];
    }

    return [];
};

export const createProfile = async () => {
    const res = await ayrshareClient.post("/profile-create");

    if (res?.status === 200) {
        return res.data;
    }

    return [];
};

export const makePost = async (content: { post: string; platforms: string[] }, images?: File[]) => {
    const formData = new FormData();

    formData.append("content", JSON.stringify(content));
    images?.forEach((image) => formData.append("media", image));

    const res = await ayrshareClient.post("/post", formData);

    if (res?.status === 200) {
        return res.data;
    }

    return [];
};
