import Modal from "@components/modal/modal";
import NetworkConnector from "@components/network-connector-content/network-connector";
import { refreshUserData } from "@helper/auth/auth-api-helper";
import AuthStore from "@helper/auth/auth-store";
import { getUser } from "@helper/auth/user-helpers";
import useFadeInOut from "@helper/custom-hooks/use-fade-in-out";
import useOnClickOutside from "@helper/custom-hooks/use-on-click-outside";
import useOpenClose from "@helper/custom-hooks/use-open-close";
import useVisual from "@helper/custom-hooks/use-visual";
import { ArchiveIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { VIRTUAL_TOUR_STEPS } from "constants/virtual-tour";
import { activeCompany } from "helper/companies/companies-api-helper";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useRef } from "react";
import { toast } from "react-toastify";
import { connectorIcon, menuCloseIcon, menuOpenIcon } from "styles/icons";

import NavbarUserMenu from "./navbar-user-menu";

const Navbar: FC = () => {
    const router = useRouter();
    const { navbarOpenClose, notificationOpenClose } = useVisual();
    const navbarFade = useFadeInOut(navbarOpenClose?.isOpen || false);
    const currentUser = getUser();

    const reference = useRef(null);
    const handleClickOutside = () => {
        navbarOpenClose?.handleClose();
        notificationOpenClose?.handleClose();
    };

    const handleToggleUserNavBar = () => {
        navbarOpenClose?.toggleOpenClose();
        notificationOpenClose?.handleClose();
    };

    const user = AuthStore((state) => state.user);
    const isAdmin = user?.role === "admin";

    useOnClickOutside(reference, handleClickOutside);

    const openClose = useOpenClose();

    const handleRedirect = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            if (currentUser?.profile_type === "business") {
                await activeCompany(currentUser?.company_slug, "personal");
                await refreshUserData();
            }
            router.push("/profile/dashboard");
        } catch (error) {
            toast.error("Failed to switch profile");
        }
    };
    return (
        <div className="static" ref={reference}>
            <nav className={classNames("relative float-right grid gap-8 self-center justify-self-end", isAdmin ? "grid-cols-1" : "grid-cols-4")}>
                {!isAdmin && (
                    <>
                        <div
                            id={VIRTUAL_TOUR_STEPS.NAVBAR_SOCIALS_ICON.target}
                            className="my-auto cursor-pointer self-center "
                            onClick={openClose.toggleOpenClose}
                        >
                            {connectorIcon("#fff", "30", "30")}
                        </div>

                        <Link href="/reminder">
                            <a>
                                <ArchiveIcon className="my-auto size-7  cursor-pointer self-center text-white" />
                            </a>
                        </Link>

                        <a id={VIRTUAL_TOUR_STEPS.MY_PROFILE_ICON.target} onClick={handleRedirect}>
                            <img
                                src={user?.profile_picture || `https://ui-avatars.com/api/?name=${user.username}`}
                                alt={user?.username}
                                className="size-7 cursor-pointer self-end rounded-full"
                            />
                        </a>
                    </>
                )}
                {navbarOpenClose.isOpen ? (
                    <div id={VIRTUAL_TOUR_STEPS.NAVBAR_MENU_ICON.target} className="cursor-pointer" onClick={handleClickOutside}>
                        {menuCloseIcon("h-7 w-7", "#fff")}
                    </div>
                ) : (
                    <div id={VIRTUAL_TOUR_STEPS.NAVBAR_MENU_ICON.target} className="cursor-pointer" onClick={handleToggleUserNavBar}>
                        {menuOpenIcon("h-7 w-7", "#fff")}
                    </div>
                )}
            </nav>

            {navbarFade.isOpen && <NavbarUserMenu handleClose={handleClickOutside} />}
            <Modal handler={openClose} title="Network Connector">
                <NetworkConnector />
            </Modal>
        </div>
    );
};
export default Navbar;
