import { Dispatch, SetStateAction, useState } from "react";

export interface IUseSettingsContext {
    globalVolume: {
        volume: number;
        setVolume: Dispatch<SetStateAction<number>>;
        muted: boolean;
        setMuted: Dispatch<SetStateAction<boolean>>;
    };
}

const useSettingsContext = (): IUseSettingsContext => {
    const [volume, setVolume] = useState<number>(0.8);
    const [muted, setMuted] = useState<boolean>(false);

    return { globalVolume: { volume, setVolume, muted, setMuted } };
};

export default useSettingsContext;
