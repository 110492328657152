import H2 from "@components/helper/h2";
import Modal from "@components/modal/modal";
import YoutubeSubButton from "@components/youtube/youtube-sub-button";
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findProvider } from "@helper/auth/provider-check";
import Networks from "@helper/networks";
import { formatNumber } from "@helper/number-separator";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import { getVideoInformation, getVideoRating, rateVideo } from "@helper/youtube/youtube-api-helper";
import { AnnotationIcon, EyeIcon, ThumbDownIcon, ThumbUpIcon, XIcon } from "@heroicons/react/solid";
import { IPlayerItem } from "@models/player/player-item";
import { Post, PostAuthor } from "@models/post";
import { EProviderIds } from "@models/provider-ids";
import { IYoutubeRating } from "@models/youtube/youtube";
import Link from "next/link";
import React, { FC, useEffect, useState } from "react";

import LargeVideoPlayer from "../large-video-player";

interface IYoutubePlayer {
    playerItem: IPlayerItem;
}

const YoutubePlayer: FC<IYoutubePlayer> = ({ playerItem }: IYoutubePlayer) => {
    const [youTubeInformation, setYouTubeInformation] = useState<Post>();
    const [videoRating, setVideoRating] = useState<IYoutubeRating>();
    const youtubeUser = findProvider(EProviderIds.youtube);

    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);
    const setIsLargeVideoPlayer = PlayerSettingsStore((state) => state.setIsLargeVideoPlayer);
    const videoModal = PlayerSettingsStore((state) => state.videoModal);
    const setVideoModal = PlayerSettingsStore((state) => state.setVideoModal);

    const ratingHandler = async (rating: "none" | "like" | "dislike") => {
        try {
            if (youTubeInformation.id && videoRating) {
                if (videoRating?.items[0]?.rating === rating) {
                    await rateVideo(youTubeInformation.id, "none");
                    setVideoRating({
                        ...videoRating,
                        items: [{ videoId: youTubeInformation.id, rating: "none" }],
                    });
                } else {
                    await rateVideo(youTubeInformation.id, rating);
                    setVideoRating({
                        ...videoRating,
                        items: [{ videoId: youTubeInformation.id, rating }],
                    });
                }
            }
        } catch (e) {
            console.error("Error while getting video rating", e);
        }
    };
    useEffect(() => {
        if (youtubeUser) {
            getVideoInformation(playerItem.resource_id)
                .then((data) => setYouTubeInformation(data.posts[0]))
                .catch((error) => console.log(error));
            getVideoRating(playerItem.resource_id)
                .then((data) => setVideoRating(data))
                .catch((error) => console.log(error));
        } else setVideoModal(false);
    }, [playerItem?.resource_id, youtubeUser]);
    return (
        <Modal
            handler={{
                isOpen: videoModal,
                handleClose: () => {},
                handleOpen: () => {},
                toggleOpenClose: () => {},
            }}
        >
            <div className="flex flex-col px-4 lg:px-0">
                {youTubeInformation && (
                    <div className="flex flex-row flex-nowrap items-center justify-between py-5">
                        <H2>{youTubeInformation?.postContent?.text}</H2>
                        <div className="my-5 flex flex-row flex-nowrap items-center space-x-2">
                            <FontAwesomeIcon
                                icon={faWindowMinimize}
                                className="mr-1 cursor-pointer text-white"
                                onClick={() => {
                                    setVideoModal(false);
                                    setIsLargeVideoPlayer(false);
                                }}
                            />
                            <XIcon
                                className="size-6 cursor-pointer "
                                onClick={() => {
                                    setVideoModal(false);
                                    setIsPlaying(false);
                                    setIsLargeVideoPlayer(false);
                                }}
                            />
                        </div>
                    </div>
                )}
                {youTubeInformation && (
                    <>
                        <LargeVideoPlayer youtubeItem={youTubeInformation} />
                        <div className="grid-cols-12 border-b border-rc-gray-dark pt-4 text-black dark:text-white lg:grid">
                            <div>
                                <Link href={`/youtube/channel/${(youTubeInformation?.user as PostAuthor)?.id}`}>
                                    <img
                                        src={Networks.youTube.iconUrl}
                                        alt={Networks.youTube.name}
                                        className="size-12 cursor-pointer rounded-full object-cover lg:mx-auto"
                                    />
                                </Link>
                            </div>

                            <div className="col-span-8">
                                <div className="flex flex-col   pb-10 ">
                                    <div className="flex flex-col lg:flex-row">
                                        <div className="flex flex-row pb-4 lg:h-12 lg:self-center lg:pb-0">
                                            <Link href={`/youtube/channel/${(youTubeInformation?.user as PostAuthor)?.id}`}>
                                                <a className="my-auto self-center pl-2 text-xl font-bold text-white lg:pl-0">
                                                    {(youTubeInformation?.user as PostAuthor)?.name}
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="flex flex-row space-x-3  self-start lg:space-x-6 ">
                                        <div className="flex flex-row">
                                            <EyeIcon className="mr-2 size-5 self-center" />

                                            {youTubeInformation?.postContent?.viewCount && formatNumber(youTubeInformation?.postContent?.viewCount)}
                                        </div>
                                        <div className="flex flex-row">
                                            <AnnotationIcon className="mr-2 size-5 self-center" />
                                            {youTubeInformation?.postContent?.replyCount && formatNumber(youTubeInformation?.postContent?.replyCount)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="grid grid-cols-2 gap-2 pt-2 text-sm">
                                    <button
                                        type="button"
                                        className={`  flex flex-row justify-center rounded-md border px-4 py-1 hover:border-rc-pink hover:text-rc-pink ${
                                            videoRating?.items[0]?.rating === "like"
                                                ? "border-rc-pink text-rc-pink"
                                                : "border-rc-gray-light text-rc-white"
                                        }`}
                                        onClick={() => ratingHandler("like")}
                                    >
                                        <ThumbUpIcon className="mr-2 size-5 self-center" />
                                        {youTubeInformation?.postContent?.favoriteCount &&
                                            formatNumber(youTubeInformation?.postContent?.favoriteCount)}
                                    </button>
                                    <button
                                        type="button"
                                        className={`  px-auto flex flex-row justify-center rounded-md border py-1 hover:border-rc-blue hover:text-rc-blue ${
                                            videoRating?.items[0]?.rating === "dislike"
                                                ? "border-rc-blue text-rc-blue"
                                                : "border-rc-gray-light text-rc-white"
                                        }`}
                                        onClick={() => ratingHandler("dislike")}
                                    >
                                        <ThumbDownIcon className={` size-5 self-center  `} />
                                    </button>
                                </div>
                                <div className="mt-2 w-full self-center dark:text-white">
                                    <YoutubeSubButton channelId={(youTubeInformation?.user as PostAuthor)?.id} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default YoutubePlayer;
