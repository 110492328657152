import { IInterests } from "@models/interests/interests-response";
import React, { Dispatch, FC, SetStateAction } from "react";

import InterestTile from "./interest-tile";

export interface Props {
    tiles: IInterests[];
    searchPlaceholder?: string;
    interests: {
        newInterests: string[] | undefined;
        setNewInterests: Dispatch<SetStateAction<string[]>> | Dispatch<SetStateAction<string[] | undefined>>;
    };
    specs?: boolean;
    customClass?: string;
}

const InterestsGrid: FC<Props> = ({ tiles, interests, customClass = "" }: Props) => {
    const clickHandler = (name: string) => {
        if (interests.newInterests)
            if (interests.newInterests.includes(name)) interests.setNewInterests(interests.newInterests.filter((i) => i !== name));
            else interests.setNewInterests([...interests.newInterests, name]);
    };

    return (
        <div className={`interests-grid w-full px-6 lg:px-2 ${customClass}`}>
            <div className="grid max-h-[400px] w-full grid-cols-2 gap-4 overflow-y-auto overflow-x-hidden pt-10 md:grid-cols-5">
                {tiles.map((v) => (
                    <InterestTile
                        key={`InterestTitle_${v.id}`}
                        title={v.name}
                        bgColor={v.background_color}
                        onClick={() => clickHandler(v.name)}
                        selected={interests.newInterests?.includes(v.name)}
                    />
                ))}
            </div>
        </div>
    );
};

export default InterestsGrid;
