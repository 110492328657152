import { apiClient } from "@helper/auth/auth-api-helper";

export const getReminder = async () => {
    const data = await apiClient.get("/reminder");
    return data.data;
};
export const getOutNowReminder = async () => {
    const data = await apiClient.get("/reminder/out/now");
    return data.data;
};
export const addToReminder = async (item: any) => {
    const data = await apiClient.post("/reminder", item);
    return data.data;
};
export const removeReminder = async (id: number) => {
    const data = await apiClient.delete(`/reminder/${id}`);
    return data.data;
};
