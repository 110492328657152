/* eslint-disable no-underscore-dangle */
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import { FC, useCallback } from "react";
import { WebPlaybackSDK } from "react-spotify-web-playback-sdk";

import SpotifyHandler from "./spotify-handler";

interface Props {
    token: string;
}
const SpotifyPlayer: FC<Props> = ({ token }: Props) => {
    const volume = PlayerPersistSettingsStore((state) => state.volume);

    const getOAuthToken = useCallback((callback) => callback(token), []);

    return (
        <div>
            <WebPlaybackSDK
                initialDeviceName="Realclixx - All networks. One place"
                getOAuthToken={getOAuthToken}
                initialVolume={volume}
                connectOnInitialized
            >
                <SpotifyHandler />
            </WebPlaybackSDK>
        </div>
    );
};

export default SpotifyPlayer;
