import { updateProfileOnBoarding } from "@helper/auth/auth-api-helper";
import AuthStore from "@helper/auth/auth-store";
import { IVirtualTourStep } from "constants/virtual-tour";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

export interface IUseVirtualTourContext {
    stepIndex: number;
    steps: IVirtualTourStep[];
    setSteps: Dispatch<SetStateAction<IVirtualTourStep[]>>;
    onStart: (steps: IVirtualTourStep[], tourKey: string) => void;
    onFinish: () => void;
    onNextStep: () => void;
    tourKey: string;
    setTourKey: (tourKey: string) => void;
}

const useVirtualTourContext = (): IUseVirtualTourContext => {
    const router = useRouter();

    const user = AuthStore((state) => state.user);
    const { tours, onboarding, name: userName } = user || {};

    const [stepIndex, setStepIndex] = useState(-1);
    const [steps, setSteps] = useState([]);
    const [tourKey, setTourKey] = useState("");

    const onFinish = useCallback(() => {
        updateProfileOnBoarding(false);
        setStepIndex(-1);
    }, []);

    const onNextStep = useCallback(() => {
        setStepIndex((oldStepIndex) => oldStepIndex + 1);
    }, []);

    const onStart = useCallback(
        (listSteps, tourKey) => {
            if (onboarding) {
                // - wait for reseting [stepIndex] when [pathname] was changed
                // - will make hint tooltip more noticeable
                setTimeout(() => {
                    const remainingSteps = listSteps?.filter((step) => {
                        const stepId = `${tourKey}_${step.target}`;
                        return !tours?.[tourKey]?.[stepId];
                    });
                    setTourKey(tourKey);
                    setSteps(remainingSteps);
                    setStepIndex(0);
                }, 500);
            }
        },
        [onboarding, JSON.stringify(tours)],
    );

    // reset step when [pathname] was changed
    useEffect(() => {
        setStepIndex(-1);
    }, [router.pathname]);

    useEffect(() => {
        if (!userName) {
            setSteps([]);
        }
    }, [userName]);

    return {
        stepIndex,
        tourKey,
        setTourKey,
        steps,
        setSteps,
        onStart,
        onFinish,
        onNextStep,
    };
};

export default useVirtualTourContext;
