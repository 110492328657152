export const LANGUAGES = [
    {
        value: "sqi",
        text: "Albanian",
    },
    {
        value: "ara",
        text: "Arabic",
    },
    {
        value: "hye",
        text: "Armenian",
    },
    {
        value: "aze",
        text: "Azerbaijani",
    },
    {
        value: "eus",
        text: "Basque",
    },
    {
        value: "bel",
        text: "Belarusian",
    },
    {
        value: "ben",
        text: "Bengali",
    },
    {
        value: "bul",
        text: "Bulgarian",
    },
    {
        value: "cat",
        text: "Catalan",
    },
    {
        value: "zho",
        text: "Chinese",
    },
    {
        value: "hrv",
        text: "Croatian",
    },
    {
        value: "ces",
        text: "Czech",
    },
    {
        value: "dan",
        text: "Danish",
    },
    {
        value: "nld",
        text: "Dutch",
    },
    {
        value: "eng",
        text: "English",
    },
    {
        value: "est",
        text: "Estonian",
    },
    {
        value: "fin",
        text: "Finnish",
    },
    {
        value: "fra",
        text: "French",
    },
    {
        value: "kat",
        text: "Georgian",
    },
    {
        value: "deu",
        text: "German",
    },
    {
        value: "ell",
        text: "Greek",
    },
    {
        value: "guj",
        text: "Gujarati",
    },
    {
        value: "heb",
        text: "Hebrew",
    },
    {
        value: "hin",
        text: "Hindi",
    },
    {
        value: "hun",
        text: "Hungarian",
    },
    {
        value: "isl",
        text: "Icelandic",
    },
    {
        value: "ind",
        text: "Indonesian",
    },
    {
        value: "gle",
        text: "Irish",
    },
    {
        value: "ita",
        text: "Italian",
    },
    {
        value: "jpn",
        text: "Japanese",
    },
    {
        value: "kaz",
        text: "Kazakh",
    },
    {
        value: "kan",
        text: "Kannada",
    },
    {
        value: "kor",
        text: "Korean",
    },
    {
        value: "kur",
        text: "Kurdish",
    },
    {
        value: "lav",
        text: "Latvian",
    },
    {
        value: "lit",
        text: "Lithuanian",
    },
    {
        value: "mkd",
        text: "Macedonian",
    },
    {
        value: "mal",
        text: "Malay",
    },
    {
        value: "nob",
        text: "Norwegian",
    },
    {
        value: "pan",
        text: "Punjabi",
    },
    {
        value: "fas",
        text: "Persian",
    },
    {
        value: "pol",
        text: "Polish",
    },
    {
        value: "por",
        text: "Portuguese",
    },
    {
        value: "ron",
        text: "Romanian",
    },
    {
        value: "rus",
        text: "Russian",
    },
    {
        value: "srp",
        text: "Serbian",
    },
    {
        value: "slk",
        text: "Slovak",
    },
    {
        value: "slv",
        text: "Slovenian",
    },
    {
        value: "spa",
        text: "Spanish",
    },
    {
        value: "swe",
        text: "Swedish",
    },
    {
        value: "gsw",
        text: "Swiss German",
    },
    {
        value: "tam",
        text: "Tamil",
    },
    {
        value: "tha",
        text: "Thai",
    },
    {
        value: "tur",
        text: "Turkish",
    },
    {
        value: "ukr",
        text: "Ukrainian",
    },
    {
        value: "urd",
        text: "Urdu",
    },
    {
        value: "vie",
        text: "Vietnamese",
    },
];
