export enum EMultimediaFilter {
    all = "All",
    videos = "Videos",
    images = "Images",
    tvMovies = "TV / Movies",
    music = "Music",
    podcast = "Podcasts",
    live = "Videos Live",
    news = "News",
    products = "Products",
    posts = "Posts",
    events = "Events",
}
