import axios from "axios";
import { SocialFeedParams, SocialFeedType } from "components/companies/type";
import { IProfileType } from "models/session/current-user";

export const apiClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND,
    withCredentials: true,
});

apiClient.interceptors.response.use(null, (error) => {
    // Check if error from AbortController
    if (error.message === "canceled") {
        return;
    }
    return Promise.reject(error.response.data.message || error.response.data.message);
});

export const getCompanyProfileBySlug = async (slug: any) => {
    const res = await apiClient.get(`/companies/profile-by-slug/${slug}`);
    return res.data;
};

export const createCompanyProfile = async (payload: any) => {
    const res = await apiClient.post(`/companies/profile`, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return res.data;
};

export const activeCompany = async (slug: string, profileType: IProfileType) => {
    const res = await apiClient.put(`/companies/profile/${slug}/active`, {
        profileType,
    });
    return res.data;
};

export const editCompanyProfile = async (payload: any) => {
    const res = await apiClient.patch(`/companies/profile`, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return res.data;
};

export const createCompanyPlace = async (payload: any) => {
    const res = await apiClient.post(`/companies/place`, payload);
    return res.data;
};

export const deleteCompanyPlace = async (companyId: string, placeId: string) => {
    const res = await apiClient.delete(`/companies/profile/${companyId}/place/${placeId}`);
    return res.data;
};

export const getCompanySocialFeed = async (
    slug: string,
    {
        twitterPageToken,
        youtubePageToken,
        pinterestPageToken,
        tiktokPageToken,
        instagramPageToken,
        filter = [SocialFeedType.TWITTER, SocialFeedType.YOUTUBE, SocialFeedType.PINTEREST, SocialFeedType.TIKTOK, SocialFeedType.INSTAGRAM],
    }: SocialFeedParams,
) => {
    const paramsObj = {
        twitterPageToken,
        youtubePageToken,
        pinterestPageToken,
        tiktokPageToken,
        instagramPageToken,
        filter: filter.join(","),
    };

    // Remove undefined properties from the params object
    const filteredParams = Object.fromEntries(Object.entries(paramsObj).filter(([_, value]) => value !== null && value !== undefined));
    const params = new URLSearchParams(filteredParams);
    const response = await apiClient.get(`/companies/${slug}/feed?${params}`);

    return {
        data: response.data.data,
        pageToken: response.data.pageToken,
        hasNextPage: response.data.hasNextPage,
    };
};
