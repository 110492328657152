import React, { FC } from "react";

import NavbarMenuContent from "./navbar-menu/navbar-menu-content";

const NavbarUserMenu: FC<{ handleClose: () => void }> = ({ handleClose }) => {
    return (
        <div className="absolute right-8 top-11">
            <div className="relative w-72">
                <div className=" w-full rounded-lg bg-white px-4 pb-1 pt-4 shadow-md dark:bg-rc-black-light">
                    <NavbarMenuContent handleCloseNavbar={handleClose} />
                </div>
            </div>
        </div>
    );
};
export default NavbarUserMenu;
