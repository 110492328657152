import PlayerStore from "@helper/player/player-store";
import React from "react";

import TwitterPlayer from "./twitter-player";
import YoutubePlayer from "./youtube-player";

const VideoPlayerModal = () => {
    const playerItem = PlayerStore((state) => state.currentPlayerItem);
    return <>{playerItem.platform === "Youtube" ? <YoutubePlayer playerItem={playerItem} /> : <TwitterPlayer playerItem={playerItem} />}</>;
};

export default VideoPlayerModal;
