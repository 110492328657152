import AuthStore from "./auth-store";

export const getUser = () => {
    const { user } = AuthStore.getState();
    return user;
};

export const getCurrentSlug = () => {
    const { user } = AuthStore.getState();
    return user?.company_slug;
};
