import Modal from "@components/modal/modal";
import useCompanyProfile from "@helper/custom-hooks/use-company-profile";
import BaseProfileForm, { BaseProfileFormMode } from "components/companies/forms/new-profile-form";
import React, { FC } from "react";

const NewCompanyProfileModal: FC = () => {
    const { openCloseAddCompany: openClose } = useCompanyProfile();

    return (
        <Modal backdropStatic handler={openClose} modalContenClass="">
            <div>
                <div className={`font-headline mb-5 text-center text-4xl font-bold text-white`}>Add new company profile</div>
                <BaseProfileForm mode={BaseProfileFormMode.ADD} />
            </div>
        </Modal>
    );
};

export default NewCompanyProfileModal;
