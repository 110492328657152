import NewCompanyProfileModal from "@components/companies/new-company-profile-modal/new-company-profile-modal";
import { handleLogOut } from "@helper/auth/auth-api-helper";
import AuthStore from "@helper/auth/auth-store";
import useCompanyProfile from "@helper/custom-hooks/use-company-profile";
import useVisual from "@helper/custom-hooks/use-visual";
import Router from "next/router";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";
import { settingsIcon } from "styles/icons";

import AddCompanyProfile from "./add-company-profile";
import CompanyProfile from "./company-profiles";
import LogOut from "./logout";
import NavbarMenuLink from "./navbar-menu-link";

export interface Props {
    onClick?: () => void;
    handleCloseNavbar: () => void;
}

const NavbarMenuContent: FC<Props> = ({ onClick, handleCloseNavbar }: Props) => {
    const { openCloseAddCompany: openClose } = useCompanyProfile();
    const { navbarOpenClose } = useVisual();
    const user = AuthStore((state) => state.user);
    const isAdmin = user?.role === "admin";

    const { t } = useTranslation();

    const handleLogOutButton = () => {
        const doA = async () => {
            const data = await handleLogOut();
            if (data) {
                Router.push("/");
            }
        };
        doA();
        // Router.reload()

        // signUp.setStep(0)
        // signUp.clearUserState()
        // auth.handleLogOut()
        // setUser(undefined)
    };

    const handleAddCompanyProfile = () => {
        openClose.handleOpen();
    };

    const handleCloseCompanyProfile = () => {
        handleCloseNavbar();
        navbarOpenClose.handleClose();
    };
    return (
        <>
            {!isAdmin && <CompanyProfile name={t("generic.profile")} handleClose={handleCloseCompanyProfile} />}
            <button type="button" className="w-full" onClick={onClick || navbarOpenClose?.handleClose}>
                <NavbarMenuLink name={t("generic.settings")} href="/settings" icon={settingsIcon("#fff", "w-6 h-6")} />
            </button>
            {!isAdmin && (
                <button className="text-white" type="button" onClick={onClick}>
                    <AddCompanyProfile onClick={handleAddCompanyProfile} />
                </button>
            )}
            <button type="button" className="w-full" onClick={onClick || navbarOpenClose?.handleClose}>
                <LogOut onClick={handleLogOutButton} />
            </button>
            <NewCompanyProfileModal />
        </>
    );
};
export default NavbarMenuContent;
