/* eslint-disable no-underscore-dangle */
import { Post, PostAPIResponse, PostAuthor, PostTopicResponse, UsersAPIResponse } from "@models/post";
import { IYoutubeRating } from "@models/youtube/youtube";
import axios from "axios";
import { toast } from "react-toastify";

let isFirstRefreshRequest = true;

export const youtubeClient = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_BACKEND}/youtube`,
    withCredentials: true,
});
youtubeClient.interceptors.response.use(null, (error) => {
    if (error?.response?.status === 409 && isFirstRefreshRequest) {
        isFirstRefreshRequest = false;
        toast.error("Please reconnect your YouTube account");
    }

    if (error?.response?.status === 401 && isFirstRefreshRequest) {
        isFirstRefreshRequest = false;
        axios
            .get(`${process.env.NEXT_PUBLIC_BACKEND}/youtube/refresh`, { withCredentials: true })
            .then(() => {
                return axios.request(error.config);
            })
            .catch((err) => {
                if (error.message === "canceled") {
                    return;
                }
                if (err?.response?.status === 409) {
                    toast.error("Please reconnect your YouTube account");
                } else {
                    toast.error("Failed to refresh token, please try again");
                }
                return Promise.reject(err);
            })
            .finally(() => {
                isFirstRefreshRequest = true;
            });
    }

    return Promise.reject(error);
});

export async function getMostPopularVideos(nextToken?: string): Promise<PostAPIResponse> {
    const result = await youtubeClient.get(`/videos/most-popular`, {
        params: { nextToken },
    });
    return result.data;
}

export async function getFeedVideos(): Promise<PostAPIResponse> {
    const feeds = await youtubeClient.get("/videos/feed");
    return feeds.data;
}

export async function getListOfTopic(): Promise<PostTopicResponse> {
    const result = await youtubeClient.get("/topicList");
    return result.data;
}

export async function getVideoInformation(id: string): Promise<PostAPIResponse | undefined> {
    const videoInfo = await youtubeClient.get(`/video/${id}`);
    return videoInfo.data;
}

export async function getVideoRating(id: string): Promise<IYoutubeRating | undefined> {
    const rate = await youtubeClient.get(`/rate/${id}`);
    return rate.data;
}

export async function getSubInfos(id: string): Promise<PostAuthor | undefined> {
    const similar = await youtubeClient.get(`/subscribe/${id}`);
    if (similar?.data?.items?.length > 0) {
        return similar.data.items[0];
    }
}

export async function getVideoComments(id: string): Promise<PostAPIResponse | undefined> {
    const [comments, internalComments] = await Promise.all([
        youtubeClient.get<PostAPIResponse>(`/video/comments/${id}`),
        youtubeClient.get<PostAPIResponse>(`/video/comments/internal/${id}`),
    ]);
    return {
        ...comments.data,
        posts: [...(comments.data?.posts ?? []), ...(internalComments?.data?.posts ?? [])].sort((a, b) => b.createdAt - a.createdAt),
    };
}

export async function rateVideo(id: string, rating: "none" | "like" | "dislike") {
    const rate = await youtubeClient.post(`/rate/${id}`, {
        rating,
    });
    return rate.data;
}

export async function subscription(channelId: string): Promise<PostAuthor | undefined> {
    const channel = await youtubeClient.post(`/subscribe/${channelId}`);
    return channel.data;
}
export async function deleteSubscription(id: string) {
    const subscribed = await youtubeClient.delete(`/subscribe/${id}`);
    return subscribed.data;
}

export async function getChannelInfos(id: string): Promise<PostAuthor | undefined> {
    const channel = await youtubeClient.get(`/channel/${id}`);
    return channel.data;
}
export async function getPlaylist(playlistId: string, nextToken?: string, limit = 20): Promise<PostAPIResponse | undefined> {
    const subscribed = await youtubeClient.get(`/playlist/${playlistId}`, {
        params: { nextToken, limit },
    });
    return subscribed.data;
}

function getRandomElements(arr, num) {
    const shuffled = arr.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled.slice(0, num);
}

export async function getSimilarVideos(channelId: string, videoId): Promise<PostAPIResponse> {
    const playlistId = channelId.replace("UC", "UULF");
    const result = await getPlaylist(playlistId, null, 20);
    if (!result.posts?.length) return result;

    const videos = result.posts.filter((video) => video.id !== videoId);
    result.posts = getRandomElements(videos, 3);
    return result;
}

export async function getItemsByVideoCategory(videoCategoryId, limit = 50, nextToken = undefined) {
    const videos = await youtubeClient.get("/videos/topic", {
        params: { videoCategoryId, limit, nextToken },
    });
    return videos.data;
}

export async function youtubeSearch(query: string | undefined, limit?: number, moviesOnly?: boolean, nextToken?: string): Promise<PostAPIResponse> {
    const search = await youtubeClient.get("/search", {
        params: { query, limit, moviesOnly, nextToken },
    });
    return search.data;
}

export const getSubscribedChannels = async (nextToken?: string, userId = ""): Promise<UsersAPIResponse> => {
    const subscribed = await youtubeClient.get("/subscribe", {
        params: { nextToken, userId },
    });
    return subscribed.data;
};

export const videosFromChannel = async (channelId: string): Promise<PostAPIResponse> => {
    const videos = await youtubeClient.get(`/channel/videos/${channelId}`);
    return videos.data;
};

export async function getMyVideos(nextToken: string = undefined) {
    const response = await youtubeClient.get(nextToken ? `/my-upload?nextToken=${nextToken}` : "/my-upload");
    return {
        data: (response.data.videos || []).map((v) => ({
            id: v.id,
            fullName: v.snippet.channelTitle,
            username: v.snippet.channelTitle,
            caption: v.snippet.description,
            videoPreviewImage: v.snippet.thumbnails.default.url,
            videoUrl: `https://www.youtube.com/watch?v=${v.id}`,
            takenAt: v.snippet.publishedAt,
            videoDuration: v.contentDetails.duration,
            title: v.snippet.title,
            likeCount: v.statistics.likeCount,
            commentCount: v.statistics.commentCount,
            shareCount: v.statistics.shareCount,
            viewCount: v.statistics.viewCount,
        })),
        nextPageToken: response.data?.nextPageToken || undefined,
        hasMore: !!response.data?.nextPageToken,
    };
}

export async function makeReply({ videoId, text }: any) {
    const result = await youtubeClient.post<Post>(`/video/${videoId}/comment`, {
        content: text,
    });

    return result.data;
}
