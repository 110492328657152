import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { ArrowSmLeftIcon, PauseIcon, PlayIcon } from "@heroicons/react/solid";
import React, { FC } from "react";

const PlayerCoverImage: FC = () => {
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const isPlaying = PlayerSettingsStore((state) => state.isPlaying);
    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);

    const setQueueListOpen = PlayerSettingsStore((state) => state.setQueueListOpen);
    const playerSlideIn = PlayerSettingsStore((state) => state.playerSlideIn);
    const setPlayerSlideIn = PlayerSettingsStore((state) => state.setPlayerSlideIn);
    const setVideoMinimize = PlayerSettingsStore((state) => state.setVideoMinimize);

    const arrowHandler = () => {
        if (playerSlideIn) {
            setPlayerSlideIn(false);
            setVideoMinimize(false);
        } else {
            setPlayerSlideIn(true);
            setVideoMinimize(true);
            setQueueListOpen(false);
        }
    };
    if (!currentPlayerItem) return <></>;

    return (
        <div
            className="group relative h-36 w-60 lg:rounded-l-lg"
            style={{
                backgroundImage: `url(${currentPlayerItem.image_url})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
        >
            <div onClick={arrowHandler} className={"absolute top-0 z-30 hidden rounded-tl-lg bg-rc-dark group-hover:block"}>
                <ArrowSmLeftIcon className={` h-7 w-7 cursor-pointer  text-rc-pink transition duration-500 ${!playerSlideIn ? "rotate-180" : ""}`} />
            </div>
            <div className="absolute hidden h-full w-full bg-rc-dark text-white opacity-50 group-hover:block lg:rounded-l-lg" />
            {isPlaying ? (
                <PauseIcon
                    onClick={() => setIsPlaying(!isPlaying)}
                    className="absolute left-1/2 top-1/2 z-10  hidden h-full w-full -translate-x-1/2  -translate-y-1/2 cursor-pointer  p-2 text-white  transition group-hover:block lg:p-8"
                />
            ) : (
                <PlayIcon
                    onClick={() => setIsPlaying(!isPlaying)}
                    className="absolute left-1/2 top-1/2 z-10 hidden h-full w-full -translate-x-1/2  -translate-y-1/2 cursor-pointer  p-2 text-white  transition group-hover:block lg:p-8"
                />
            )}
        </div>
    );
};

export default PlayerCoverImage;
