import { useState } from "react";

export interface IIndexState {
    index: number;
    setIndex: (index: number) => void;
    resetIndex: () => void;
}

const useIndexState = (initialState = 0): IIndexState => {
    const [index, setIndex] = useState<number>(initialState);

    const resetIndex = () => {
        setIndex(initialState);
    };

    return { index, setIndex, resetIndex };
};

export default useIndexState;
