/* eslint-disable @next/next/no-html-link-for-pages */
import { XIcon } from "@heroicons/react/solid";
import React, { FC } from "react";

interface Props {
    onClick: () => void;
}

const SignupLoginModule: FC<Props> = ({ onClick }: Props) => {
    return (
        <div className="flex h-screen w-screen flex-col bg-rc-dark p-4 text-white">
            <span className="flex w-full justify-between ">
                <a href="/">
                    <img src="/logo_white.png" alt="Realclixx" className="h-8 cursor-pointer pt-1 transition-opacity duration-500" />
                </a>
                <XIcon onClick={onClick} className="h-8 w-8 cursor-pointer text-white" />
            </span>
            <div className="flex h-full w-full flex-col justify-center space-y-4 self-center">
                <a href="/setup" className="rounded-lg bg-rc-blue px-8 py-4 text-center transition duration-200 hover:bg-blue-600">
                    Sign Up
                </a>
                <a href="/login" className="rounded-lg bg-rc-pink px-8  py-4 text-center transition duration-200 hover:bg-pink-600">
                    Login
                </a>
            </div>
        </div>
    );
};

export default SignupLoginModule;
