import { CompanyProfile } from "components/companies/type";

import { IPlace } from "../../components/companies/company-profile-info/company-places.interface";
import AuthStore from "./auth-store";

export const getActiveCompany = (): CompanyProfile => {
    const { user } = AuthStore.getState();
    const companies = user?.companies || [];

    const activeCompany = companies.find((company) => company.active);
    return activeCompany;
};

export const updatePlaces = (places: IPlace[]): boolean => {
    const { user } = AuthStore.getState();
    const setUser = AuthStore((state) => state.setUser);

    const companies = user?.companies || [];

    const activeCompanyIndex = companies.findIndex((company) => company.active);
    companies[activeCompanyIndex].places = places;
    setUser({ ...user, companies });
    return true;
};

export const getUserCompanies = () => {
    const { user } = AuthStore.getState();
    return user?.companies || [];
};

export const isBusinessProfile = () => {
    const { user } = AuthStore.getState();
    return user.profile_type === "business";
};
