import Navbar from "@components/navbar/navbar";
import Searchbar from "@components/search/searchbar";
import AuthStore from "@helper/auth/auth-store";
import Link from "next/link";
import React, { FC } from "react";

const UserHeader: FC = () => {
    const {
        user: { role = "user" },
    } = AuthStore.getState();
    const isAdmin = role === "admin";
    const CONFIG = {
        logo: { href: "/logo_white.png", alt: "Realclixx Logo" },
        logoDark: { href: "/logo_black.png", alt: "Realclixx Logo" },
        signet: { href: "/signet.svg", alt: "Realclixx-Signet" },
    };

    return (
        <div className="grid grid-cols-3 px-9 py-2">
            <Link href={isAdmin ? "/admin-dashboard/users" : "/multimedia/videos"}>
                <a>
                    <img src={CONFIG.logo.href} alt={CONFIG.logo.alt} className="duration-500 h-8 cursor-pointer pt-1 transition-opacity" />
                </a>
            </Link>
            <div>{!isAdmin && <Searchbar />}</div>
            <div>
                <Navbar />
            </div>
        </div>
    );
};

export default UserHeader;
