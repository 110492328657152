import Carousel from "@components/slider/carousel";
import NetworkSlide from "@components/slides/network-slide";
import AppleStore from "@helper/apple/apple-store";
import Networks from "@helper/networks";
import { EProviderIds } from "@models/provider-ids";
import { getProviders } from "helper/auth/provider-check";
import { useTranslation } from "next-i18next";
import { FC, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { searchIcon } from "styles/icons";

import { MusicKitAuthStatus } from "./interface";

const NetworkConnector: FC = () => {
    const providers = getProviders();
    const { t } = useTranslation();
    const mediaContext = AppleStore((state) => state.mediaContext);
    const [appleMusicConnected, setAppleMusicConnected] = useState(mediaContext?.isAuthorized);

    const authorizationStatusDidChange = (data: any) => {
        switch (data.authorizationStatus) {
            case MusicKitAuthStatus.AUTHORIZED:
                setAppleMusicConnected(true);
                break;
            default:
                setAppleMusicConnected(false);
                break;
        }
    };

    useEffect(() => {
        MusicKit.getInstance()?.addEventListener("authorizationStatusDidChange", authorizationStatusDidChange);
    }, []);

    const rows = [];
    for (let index = 0; index < 20; index += 1) {
        rows.push(
            <NetworkSlide
                key={`NetworkSlide_${index}`}
                iconUrl="/demo/200.png"
                loginUrl="/"
                name="Netflix"
                slug="netflix"
                isConnected={false}
                providerId={0}
            />,
        );
    }

    return (
        <div className="w-full ">
            <div className="relative ">
                {/* TODO Make proper form */}
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button type="submit" className="focus:shadow-outline focus:outline-none">
                        <>{searchIcon("#fff", "h-4 w-4 self-start")}</>
                    </button>
                </span>
                <input
                    className="h-8  w-full rounded-md border border-white bg-transparent pl-10 text-sm tracking-wide opacity-50 placeholder:text-white"
                    placeholder={t("networks.look_for_service")}
                />
            </div>
            <div className="space-y-10 py-8">
                <Carousel
                    title={t("generic.music")}
                    infinite
                    slidesToScroll={1}
                    slidesToShow={10}
                    slidesToShowXl={6}
                    slidesToShowLg={6}
                    slidesToShowMd={5}
                    slidesToShowSm={3}
                >
                    <NetworkSlide
                        iconUrl={Networks.spotify.iconUrl}
                        slug={Networks.spotify.name}
                        name={Networks.spotify.name}
                        loginUrl={Networks.spotify.loginUrl}
                        isConnected={providers?.some((e) => e.provider_id === EProviderIds.spotify)}
                        providerId={EProviderIds.spotify}
                    />
                    <div
                        data-tooltip-id="apple-connector-tooltip"
                        data-tooltip-content="Your Apple account must be paid subscription, or it won't work"
                        data-tooltip-place="top"
                    >
                        <NetworkSlide
                            iconUrl={Networks.apple.iconUrl}
                            slug={Networks.apple.name}
                            name={Networks.apple.name}
                            loginUrl={undefined}
                            isConnected={appleMusicConnected}
                            providerId={EProviderIds.apple}
                        />
                    </div>
                    {rows}
                </Carousel>
                <Carousel
                    title={t("generic.social")}
                    infinite
                    slidesToScroll={1}
                    slidesToShow={10}
                    slidesToShowXl={6}
                    slidesToShowLg={6}
                    slidesToShowMd={5}
                    slidesToShowSm={3}
                >
                    <NetworkSlide
                        iconUrl={Networks.twitter.iconUrl}
                        slug={Networks.twitter.name}
                        name={Networks.twitter.name}
                        loginUrl={Networks.twitter.loginUrl}
                        providerId={EProviderIds.twitter}
                        isConnected={providers?.some((e) => e.provider_id === EProviderIds.twitter)}
                    />
                    <NetworkSlide
                        iconUrl={Networks.facebook.iconUrl}
                        slug={Networks.facebook.name}
                        name={Networks.facebook.name}
                        loginUrl={Networks.facebook.loginUrl}
                        providerId={EProviderIds.facebook}
                        isConnected={providers?.some((e) => e.provider_id === EProviderIds.facebook)}
                    />
                    <NetworkSlide
                        iconUrl={Networks.instagrambasic.iconUrl}
                        slug={Networks.instagrambasic.name}
                        name={Networks.instagrambasic.name}
                        loginUrl={Networks.instagrambasic.loginUrl}
                        providerId={EProviderIds.instagram}
                        isConnected={providers?.some((e) => e.provider_id === EProviderIds.instagram)}
                    />
                    <NetworkSlide
                        iconUrl={Networks.pinterest.iconUrl}
                        slug={Networks.pinterest.name}
                        name={Networks.pinterest.name}
                        loginUrl={Networks.pinterest.loginUrl}
                        providerId={EProviderIds.pinterest}
                        isConnected={providers?.some((e) => e.provider_id === EProviderIds.pinterest)}
                    />
                    <NetworkSlide
                        iconUrl={Networks.linkedin.iconUrl}
                        slug={Networks.linkedin.name}
                        name={Networks.linkedin.name}
                        loginUrl={Networks.linkedin.loginUrl}
                        providerId={EProviderIds.linkedin}
                        isConnected={providers?.some((e) => e.provider_id === EProviderIds.linkedin)}
                    />
                    {rows}
                </Carousel>
                <Carousel
                    title={t("generic.videos")}
                    infinite
                    slidesToScroll={1}
                    slidesToShow={10}
                    slidesToShowXl={6}
                    slidesToShowLg={6}
                    slidesToShowMd={5}
                    slidesToShowSm={3}
                >
                    <NetworkSlide
                        iconUrl={Networks.youTube.iconUrl}
                        slug={Networks.youTube.name}
                        name={Networks.youTube.name}
                        loginUrl={Networks.youTube.loginUrl}
                        isConnected={providers?.some((e) => e.provider_id === EProviderIds.youtube)}
                        providerId={EProviderIds.youtube}
                    />
                    <NetworkSlide
                        iconUrl={Networks.twitch.iconUrl}
                        slug={Networks.twitch.name}
                        name={Networks.twitch.name}
                        loginUrl={Networks.twitch.loginUrl}
                        isConnected={providers?.some((e) => e.provider_id === EProviderIds.twitch)}
                        providerId={EProviderIds.twitch}
                    />
                    <NetworkSlide
                        iconUrl={Networks.tiktok.iconUrl}
                        slug={Networks.tiktok.name}
                        name={Networks.tiktok.name}
                        loginUrl={Networks.tiktok.loginUrl}
                        isConnected={providers?.some((e) => e.provider_id === EProviderIds.tiktok)}
                        providerId={EProviderIds.tiktok}
                    />
                    {rows}
                </Carousel>
                <Carousel
                    title={t("generic.movies")}
                    infinite
                    slidesToScroll={1}
                    slidesToShow={10}
                    slidesToShowXl={6}
                    slidesToShowLg={6}
                    slidesToShowMd={5}
                    slidesToShowSm={3}
                >
                    {rows}
                </Carousel>
                <Carousel
                    title={t("generic.podcasts")}
                    infinite
                    slidesToScroll={1}
                    slidesToShow={10}
                    slidesToShowXl={6}
                    slidesToShowLg={6}
                    slidesToShowMd={5}
                    slidesToShowSm={3}
                >
                    {rows}
                </Carousel>
                <Carousel
                    title={t("generic.messenger")}
                    infinite
                    slidesToScroll={1}
                    slidesToShow={10}
                    slidesToShowXl={6}
                    slidesToShowLg={6}
                    slidesToShowMd={5}
                    slidesToShowSm={3}
                >
                    {rows}
                </Carousel>
            </div>
            <Tooltip id="apple-connector-tooltip" place="top" variant="light" />
        </div>
    );
};

export default NetworkConnector;
