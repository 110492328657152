import { apiClient } from "@helper/api-client";
import { appleSearchCatalog, getAppleAlbumOrPlaylistOrLibrary, getAppleArtistById } from "@helper/apple/apple-api-helper";
import { findProvider } from "@helper/auth/provider-check";
import { getLangParam } from "@helper/get-lang-param";
import { getGoogleNews } from "@helper/google/google-api-helper";
import { getInstagramImages, getInstagramVideos } from "@helper/instagram/instagram-api-helper";
import { getNewsByQuery } from "@helper/news/news-api-helper";
import { getAlbumsForArtist, getTopTracksForArtist, searchQuerySpotify } from "@helper/spotify/spotify-api-helper";
import {
    getAllFilmsForImdb,
    getKnowForImdb,
    getMovieDetails,
    getPersonById,
    getPersonWork,
    getSeriesDetails,
    searchMovie,
    searchTv,
} from "@helper/tmdb/tmdb-api-helper";
import { searchTwitchCategories, searchTwitchChannels } from "@helper/twitch/twitch-api-helper";
import { getTweetsFromUserName } from "@helper/twitter/api-helper";
import { wait } from "@helper/wait";
import { getPlaylist } from "@helper/youtube/youtube-api-helper";
import { InstagramData } from "@models/instagram/search-data";
import { IProduct } from "@models/marketplace/product";
import { NewsResponse } from "@models/news/news";
import { PostAuthor, Topic } from "@models/post";
import { EProviderIds } from "@models/provider-ids";
import { IWikiData } from "@models/wiki/wiki-data";

import { ISpotifySearchResults } from "./models/spotify/spotiy-search-results";
import { ITmdbMovieDetails } from "./models/tmdb/movie";
import { ITmdbSearch } from "./models/tmdb/tmdb-search";
import { ITmdbTvShowDetails } from "./models/tmdb/tv";

export async function spotifySearch(name: string, spotifyId?: string): Promise<ISpotifySearchResults | undefined> {
    if (spotifyId) {
        const [albums, topTracks, playlists, shows] = await Promise.all([
            getAlbumsForArtist(spotifyId),
            getTopTracksForArtist(spotifyId),
            searchQuerySpotify(name, "playlist"),
            searchQuerySpotify(name, "show"),
        ]);

        return {
            albums,
            topTracks,
            playlists: playlists?.playlists,
            shows: shows?.shows,
        };
    }

    const [tracks, albums, playlists, shows] = await Promise.all([
        searchQuerySpotify(name, "track"),
        searchQuerySpotify(name, "album"),
        searchQuerySpotify(name, "playlist"),
        searchQuerySpotify(name, "show"),
    ]);

    return {
        tracks: tracks?.tracks,
        albums: albums?.albums,
        playlists: playlists?.playlists,
        shows: shows?.shows,
    };
}

export async function tmdbSearch(
    query: string,
    personId?: string,
    tvId?: string,
    movieId?: string,
    imdbId?: string,
): Promise<ITmdbSearch | undefined> {
    if (!personId && !tvId && !movieId) {
        const [movies, tv] = await Promise.all([searchMovie(query), searchTv(query)]);
        return { tv: tv.results, movies: movies.results };
    }
    if (movieId) {
        const [movies, tv, movie] = await Promise.all([searchMovie(query), searchTv(query), getMovieDetails(movieId)]);
        return { movie, movies: movies.results, tv: tv.results };
    }
    if (tvId) {
        const [tv, movies, series] = await Promise.all([searchTv(query), searchMovie(query), getSeriesDetails(tvId)]);
        return { tv_show: series, movies: movies.results, tv: tv.results };
    }
    if (personId) {
        const [info, personWork, knowFor, filmography] = await Promise.all([
            getPersonById(personId),
            getPersonWork(personId),
            imdbId ? getKnowForImdb(imdbId) : Promise.resolve(undefined),
            imdbId ? getAllFilmsForImdb(imdbId) : Promise.resolve(undefined),
        ]);

        return {
            person: {
                infos: info,
                knowFor,
                personWork,
            },
            tv: (filmography as ITmdbTvShowDetails[])?.filter((f) => f.media_type === "tv"),
            movies: (filmography as ITmdbMovieDetails[])?.filter((f) => f.media_type === "movie"),
        };
    }
}

export async function youtubeSearch(channel: { id: string; name: string }, nextPageToken?: string) {
    try {
        const playlistId = channel?.id?.replace("UC", "UU");
        const playlistInfos = await getPlaylist(playlistId, nextPageToken);
        if (!playlistInfos) return undefined;
        return {
            posts: playlistInfos.posts,
            id: channel.id,
            name: channel.name,
            meta: playlistInfos.meta,
        };
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export async function twitterSearch(userName: string, nextToken = null) {
    if (!userName) return null;
    const { posts: tweets, meta } = await getTweetsFromUserName(userName, nextToken);
    return {
        tweets,
        images: tweets.filter((f) => f.postContent?.entities?.find((g) => g.type === "photo")),
        videos: tweets.filter((f) => f.postContent?.entities?.find((g) => g.type === "video")),
        meta,
    };
}

export async function twitchSearch(query: string) {
    const [channels, categories] = await Promise.all([searchTwitchChannels(query), searchTwitchCategories(query)]);
    return { channels, categories };
}

export async function newsSearch(query: string, limit: number = 5, page: number = 1) {
    const data: NewsResponse = await getNewsByQuery(query, limit, page);
    return data;
}

export async function googleNewsSearch(query: string, limit: number = 10, next: number = null) {
    const data = await getGoogleNews(query, limit, next);
    return data;
}

export async function productsSearch(query: string) {
    return undefined;

    // temporary disabled shopping section
    try {
        const amazonResults = apiClient.get(`/amazon/search-items?artist=${query}`);
        const ebayResults = apiClient.get<any>(`/ebay/search-items?artist=${query}`);

        return [
            (await amazonResults).data.map(
                (rawResult) =>
                    ({
                        affiliateLink: rawResult.detailPageURL,
                        imgUrl: rawResult.imageUrl.replace("SL160", "SL500"),
                        id: rawResult.ASIN,
                        name: rawResult.title,
                        price: rawResult.price,
                        seller: "Amazon",

                        sellerIcon: "/media-icons/amazon.png",
                    } as IProduct),
            ),

            (await ebayResults).data.findItemsByKeywordsResponse[0]?.searchResult[0].item.map(
                (rawResult) =>
                    ({
                        affiliateLink: rawResult.viewItemURL[0],
                        imgUrl: rawResult.galleryURL[0].replace("s-l140", "s-l500"),
                        id: rawResult.itemId[0],
                        name: rawResult.title[0],
                        price: `${Number.parseFloat(
                            // eslint-disable-next-line no-underscore-dangle
                            rawResult.sellingStatus[0]?.convertedCurrentPrice.find((item) => item["@currencyId"] === "EUR")?.__value__,
                        )
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")} €`,
                        seller: "eBay",
                        sellerIcon: "/media-icons/ebay.png",
                    } as IProduct),
            ),
        ].flat();
    } catch (error) {
        return undefined;
    }
}

export function toCapitalize(sentence) {
    return sentence
        .toLowerCase()
        .split(" ")
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
}

export async function appleSearch(appleId?: string, query?: string) {
    const defaultResponse = {
        albums: null,
        playlists: null,
        musicVideos: null,
        tracks: null,
    };
    if (appleId && query) {
        const [artist, search] = await Promise.all([getAppleArtistById(appleId), appleSearchCatalog(query)]);

        if (artist?.relationships?.albums.data) {
            const albums = artist.relationships.albums.data.map((a) => getAppleAlbumOrPlaylistOrLibrary(a.id));
            const resultAlbums = await Promise.all(albums);

            return {
                albums: { data: resultAlbums },
                playlists: search.results.playlists,
                musicVideos: search.results["music-videos"],
                tracks: search.results.songs,
            };
        }
        return defaultResponse;
    }

    if (query) {
        const search = await appleSearchCatalog(query);

        return {
            albums: search.results.albums,
            playlists: search.results.playlists,
            musicVideos: search.results["music-videos"],
            tracks: search.results.songs,
        };
    }
}

export async function completeSearch(query: string) {
    const result = await apiClient.get(`/search?${getLangParam()}&keyword=${encodeURI(query)}`);
    return result.data;
}

export async function youtubeChannelSearch(wikiData: IWikiData) {
    const youtubePromise = wikiData?.socials?.youtube_channels?.map((c) => youtubeSearch(c));
    return (youtubePromise && Promise.all(youtubePromise)) || [];
}

export async function instagramSearch(
    username: string,
    hashtag: string,
    pageInfo?: {
        nextToken?: string;
    },
): Promise<InstagramData> {
    try {
        const [videos, images] = await Promise.all([
            getInstagramVideos(`${username}`, `${hashtag}video`, 7, pageInfo),
            // timeout to NOT make 2 instagram api calls at the same time (we have a limit: 1 inst api call per second)
            wait(1500).then(() => getInstagramImages(username, hashtag, 7, pageInfo)),
        ]);
        return {
            videos,
            images,
        };
    } catch (error) {
        console.error(error);
    }
}

export type MultiSearchResponse = {
    apple: any;
    twitch: { categories: Topic[]; channels: PostAuthor[] };
    twitter: any;
    google: any;
    products: any;
};
export async function multiSearch(wikiData?: IWikiData, query?: string): Promise<MultiSearchResponse> {
    console.log("🚀 ~ multiSearch ~ wikiData:", wikiData);
    try {
        const twitchToken = findProvider(EProviderIds.twitch);

        const twitterUser = findProvider(EProviderIds.twitter);

        let twitterPromise;
        const nameQuery = wikiData?.name || query;

        const twitchPromise = twitchToken ? twitchSearch(nameQuery) : undefined;
        if (wikiData?.socials?.twitter_id) {
            twitterPromise = twitterUser ? twitterSearch(wikiData.socials.twitter_id) : undefined;
        }

        let productsPromise;
        if (query) productsPromise = productsSearch(query);
        const applePromise = appleSearch(wikiData?.socials?.apple_id, query);
        const googlePromise = googleNewsSearch(query);

        const [apple, twitch, twitter, google, products] = await Promise.all([
            applePromise,
            twitchPromise,
            twitterPromise,
            googlePromise,
            productsPromise,
        ]);

        return {
            apple,
            twitch,
            twitter,
            google,
            products,
        };
    } catch (error) {
        console.error(error);
    }
}
