import React from "react";
import { Controller } from "react-hook-form";

const InputField = ({ name, control, label, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div className="mb-4">
                    {label && (
                        <label htmlFor={name} className="mb-2 block text-sm font-bold text-white">
                            {label}
                        </label>
                    )}
                    <input
                        {...field}
                        {...props}
                        id={name}
                        className={`w-full border p-2 text-black ${error ? "border-red-500" : "border-gray-300"} rounded`}
                    />
                    {error && <span className="text-sm text-red-500">{error.message}</span>}
                </div>
            )}
        />
    );
};

export default InputField;
