import AppleStore from "@helper/apple/apple-store";
import { findProvider } from "@helper/auth/provider-check";
import GlobalHistoryStore from "@helper/global-history-store";
import Networks from "@helper/networks";
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import PlayerStore from "@helper/player/player-store";
import { getSpotifyToken } from "@helper/spotify/spotify-api-helper";
import { EProviderIds } from "@models/provider-ids";
import axios from "axios";
import moment from "moment";
import { FC, useEffect, useState } from "react";

import ApplePlayer from "./external-player/apple-player";
import AudioPlayer from "./external-player/audio-player";
import SpotifyPlayer from "./external-player/spotify/spotify-player";
import TwitchPlayer from "./external-player/twitch-player";
import VideoLinkPlayer from "./external-player/video-link-player";
import YoutubePlayer from "./external-player/youtube-player";

const PlayerHandler: FC = () => {
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const setPlayerTime = PlayerPersistSettingsStore((state) => state.setPlayerTime);
    const setSeek = PlayerPersistSettingsStore((state) => state.setSeek);
    const [token, setToken] = useState(undefined);
    const spotifyToken = findProvider(EProviderIds.spotify);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;

        const asyncFunc = async () => {
            try {
                if (!spotifyToken) {
                    setToken(undefined);
                    return;
                }
                await axios.get(`${process.env.NEXT_PUBLIC_BACKEND}/spotify/refresh`, {
                    withCredentials: true,
                    signal,
                });
                const data = await getSpotifyToken();
                setToken(data);
            } catch (e) {
                console.log("Failed to refresh spotify token", e);
            }
        };
        if (spotifyToken) {
            asyncFunc();
        } else {
            setToken(undefined);
        }

        return () => {
            controller.abort();
        };
    }, [spotifyToken]);

    const setPlayerHistory = GlobalHistoryStore((state) => state.setPlayerHistory);

    useEffect(() => {
        setPlayerTime(0);
        setSeek(0);
        if (currentPlayerItem) {
            setPlayerHistory({ item: currentPlayerItem, create_date: moment() });
        }
    }, [currentPlayerItem]);

    const [appleLogIn, setAppleLogIn] = useState<boolean>(false);
    const mediaContext = AppleStore((state) => state.mediaContext);

    useEffect(() => {
        if (mediaContext) {
            const LoginStatus = mediaContext.isAuthorized;

            setAppleLogIn(LoginStatus);
        }
    }, [mediaContext]);

    return (
        <>
            {/* Youtube Stuff here */}
            {currentPlayerItem?.platform === Networks.youTube.internalName && <YoutubePlayer />}
            {/* Apple Stuff here */}
            {currentPlayerItem?.platform === "Apple_Video" && <VideoLinkPlayer />}
            {appleLogIn && <ApplePlayer />}
            {/* Spotify Stuff here */}
            {token && <SpotifyPlayer token={token} />}
            {/* Preview Player */}
            {!appleLogIn && currentPlayerItem?.preview_url && currentPlayerItem?.platform === "Apple_Music" && <AudioPlayer />}
            {!token && currentPlayerItem?.preview_url && currentPlayerItem?.platform === "Spotify" && <AudioPlayer />}
            {/* Twitch stuff here */}
            {currentPlayerItem?.platform === Networks.twitch.internalName && <TwitchPlayer />}
            {/* Twitter stuff here */}
            {currentPlayerItem?.platform === Networks.twitter.name && <VideoLinkPlayer />}
            {/* Instagram stuff here */}
            {currentPlayerItem?.platform === Networks.instagrambasic.name && <VideoLinkPlayer />}
            {currentPlayerItem?.platform === Networks.tiktok.name && <VideoLinkPlayer />}
            {currentPlayerItem?.platform === Networks.pinterest.name && (
                <>
                    <VideoLinkPlayer />
                    <AudioPlayer />
                </>
            )}
        </>
    );
};

export default PlayerHandler;
