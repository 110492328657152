import H2 from "@components/helper/h2";
import { IUseOpenClose } from "@helper/custom-hooks/use-open-close";
import { XIcon } from "@heroicons/react/solid";
import React, { FC, ReactNode, useEffect, useRef, useState } from "react";

interface IModal {
    handler: IUseOpenClose;
    title?: string;
    children?: ReactNode;
    className?: string;
    style?: React.CSSProperties;
    withoutBox?: boolean;
    modalContenClass?: string;
    backdropStatic?: boolean;
}

const Modal: FC<IModal> = ({
    children,
    title,
    handler,
    className = "grid place-content-center",
    style = { backgroundColor: "rgb(10, 10, 10, .9)", marginLeft: "0px" },
    withoutBox,
    modalContenClass,
    backdropStatic,
}: IModal) => {
    const [isMounted, setIsMounted] = useState<boolean>(true);
    const reference = useRef(null);

    const handleClickOutside = (event: { target: any }) => {
        if (reference.current && !reference.current.contains(event.target) && !backdropStatic) {
            handler?.handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });
    useEffect(() => {
        return () => {
            setIsMounted(false);
            document.body.classList.remove("overflow-hidden");
        };
    }, []);

    useEffect(() => {
        if (isMounted) {
            if (handler?.isOpen) {
                document.body.classList.add("overflow-hidden");
            } else {
                document.body.classList.remove("overflow-hidden");
            }
        }
    }, [handler.isOpen]);

    if (!handler.isOpen) {
        return <></>;
    }

    return (
        <>
            {withoutBox ? (
                <div className={`fixed inset-0 z-50 h-screen w-screen${className ? ` ${className}` : ""}`} style={style}>
                    <div
                        className={
                            handler?.isOpen ? " flex grow animate-scale-enlarge justify-center" : " flex grow animate-scale-shrink justify-center"
                        }
                    >
                        <div className="flex w-3/4 flex-col gap-4">{children}</div>
                    </div>
                </div>
            ) : (
                <div className={`fixed inset-0 z-50 h-screen w-screen${className ? ` ${className}` : ""}`} style={style}>
                    <div
                        className={
                            handler?.isOpen ? " flex grow animate-scale-enlarge justify-center" : " flex grow animate-scale-shrink justify-center"
                        }
                    >
                        <div className="relative" ref={reference}>
                            <div className="absolute -inset-1 animate-tilt rounded-lg bg-gradient-to-r from-rc-pink to-rc-blue opacity-75 blur" />
                            <div className="relative flex min-h-screen w-screen flex-col rounded-lg bg-white text-black dark:bg-rc-dark dark:text-white  lg:max-h-screen lg:min-h-0 lg:w-950">
                                {title && (
                                    <div className="flex flex-row justify-between pb-2 pt-6 lg:px-14">
                                        <H2 className="w-full text-3xl">{title}</H2>
                                    </div>
                                )}
                                <XIcon
                                    onClick={handler?.handleClose}
                                    className="absolute right-6 top-6 z-10 size-8 cursor-pointer text-black dark:text-white lg:size-6"
                                />
                                <div className={`relative my-auto ${modalContenClass ?? ""}`}>
                                    <div className="modal-content flex flex-col gap-12 px-12 lg:px-10 lg:py-8">{children}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
