import AppleStore from "@helper/apple/apple-store";
import { findProvider } from "@helper/auth/provider-check";
import Networks from "@helper/networks";
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import PlayerStore from "@helper/player/player-store";
import { EProviderIds } from "@models/provider-ids";
import React, { FC, useEffect, useState } from "react";

export interface Properties {}

const PlayerTimeRage: FC<Properties> = () => {
    const playerTime = PlayerPersistSettingsStore((state) => state.playerTime);
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const setSeek = PlayerPersistSettingsStore((state) => state.setSeek);

    const handleOnClick = (event: any) => {
        const RECT = event.target.getBoundingClientRect();
        const CURSOR_POS = event.clientX - RECT.left;
        const ELEMENT_WIDTH = event.target.parentElement?.clientWidth;
        setSeek(CURSOR_POS / ELEMENT_WIDTH);
    };

    const token = findProvider(EProviderIds.spotify);

    const [appleLogIn, setAppleLogIn] = useState<boolean>(false);
    const mediaContext = AppleStore((state) => state.mediaContext);

    useEffect(() => {
        if (mediaContext) {
            const LoginStatus = mediaContext.isAuthorized;

            setAppleLogIn(LoginStatus);
        }
    }, [mediaContext]);

    if (!currentPlayerItem?.duration_ms) return <></>;
    return (
        <div className="cursor-pointer pt-2" onClick={handleOnClick}>
            <div className="relative h-1 w-full rounded bg-rc-pink">
                <div
                    className="absolute left-0 h-1 rounded bg-rc-blue"
                    style={{
                        width: `${(playerTime / currentPlayerItem.duration_ms) * 100}%`,
                    }}
                />

                <>
                    {currentPlayerItem.platform === Networks.spotify.name && !token && (
                        <div
                            className="absolute left-0 h-1 rounded bg-rc-blue"
                            style={{
                                width: `${(playerTime / 30000) * 100}%`,
                            }}
                        />
                    )}
                    {currentPlayerItem.platform === "Apple_Music" && !appleLogIn && (
                        <div
                            className="absolute left-0 h-1 rounded bg-rc-blue"
                            style={{
                                width: `${(playerTime / 30000) * 100}%`,
                            }}
                        />
                    )}

                    <>
                        {currentPlayerItem.duration_ms && (appleLogIn || token) && (
                            <div
                                className="absolute left-0 h-1 rounded bg-rc-blue"
                                style={{
                                    width: `${(playerTime / currentPlayerItem.duration_ms) * 100}%`,
                                }}
                            />
                        )}
                    </>
                </>
            </div>
        </div>
    );
};

export default PlayerTimeRage;
