import { IAppleArtist } from "@models/apple/artist-info";
import { IAppleTrackInfos } from "@models/apple/track-infos";
import axios from "axios";

import AppleStore from "./apple-store";

export const getAppleCharts = async () => {
    const { mediaContext } = AppleStore.getState();
    const data = await mediaContext?.api.music(`/v1/catalog/${mediaContext.storefrontId}/charts?types=songs,albums,artists`);
    return data.results;
};
export const getAppleTrackById = async (id: string): Promise<IAppleTrackInfos | undefined> => {
    const { mediaContext } = AppleStore.getState();
    const data = await mediaContext?.api.music(`/v1/catalog/de/songs/${id}`);
    if (data?.data?.data?.length > 0) return data.data.data[0];
};
export const getAppleArtistById = async (id: string): Promise<IAppleArtist | undefined> => {
    const { mediaContext } = AppleStore.getState();
    const data = await mediaContext?.api.music(`/v1/catalog/${mediaContext.storefrontId}/artists/${id}?extend=artistBio,bornOrFormed,isGroup,origin`);
    if (data?.data?.data?.length > 0) return data.data.data[0];
};

export const getAppleAlbumOrPlaylistOrLibrary = async (id: string) => {
    const { mediaContext } = AppleStore.getState();
    if (id && !id.includes("pl.") && !id.includes("l.")) {
        const data = await mediaContext?.api.music(`/v1/catalog/${mediaContext.storefrontId}/albums/${id}`);

        return data.data.data[0];
    }
    if (id && !id.includes("pl.") && id.includes("l.")) {
        const data = await mediaContext?.api.music(`/v1/me/library/albums/${id}`);
        return data.data.data[0];
    }
    if (id && id.includes("pl.")) {
        const data = await mediaContext?.api.music(`/v1/catalog/${mediaContext.storefrontId}/playlists/${id}`);

        return data.data.data[0];
    }
};

export const getAppleLibraryArtists = async ({ limit = 16 }) => {
    const { mediaContext } = AppleStore.getState();
    const data = await mediaContext?.api.music(`/v1/me/library/artists?include=catalog&limit=${limit}`);
    return data?.data ?? { data: [] };
};

export const appleSearchCatalog = async (term: string) => {
    const { mediaContext } = AppleStore.getState();
    const data = await mediaContext?.api.music(`/v1/catalog/${mediaContext.storefrontId}/search`, {
        term,
        limit: 25,
        types: ["activities", "albums", "apple-curators", "curators", "music-videos", "playlists", "record-labels", "songs", "stations"],
    });
    return data.data;
};
export const appleCall = async (link: string) => {
    const { mediaContext } = AppleStore.getState();
    const data = await mediaContext?.api.music(link, { limit: 25 });
    return data.data;
};

export const getAppleVideoFeed = async () => {
    const { mediaContext } = AppleStore.getState();
    const data = await mediaContext?.api.music(`/v1/catalog/${mediaContext.storefrontId}/charts?types=songs,albums,artists`);

    return data.results;
};
export const appleIsLiked = async (id: string, type: "songs" | "playlists" | "albums") => {
    const { mediaContext } = AppleStore.getState();
    try {
        const data = await mediaContext?.api.music(`/v1/me/ratings/${type}/${id}`);
        if (data?.data?.data && data.data.data[0]?.attributes.value === 1) {
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

export async function handleAppleRating(id: string, like: boolean, type: "songs" | "playlists" | "albums") {
    const { mediaContext } = AppleStore.getState();

    const data = await axios.put(
        `https://api.music.apple.com/v1/me/ratings/${type}/${id}`,
        {
            type: "rating",
            attributes: {
                value: like ? -1 : 1,
            },
        },
        {
            headers: {
                Authorization: `Bearer ${mediaContext.developerToken}`,
                Accept: "application/json",
                "Music-User-Token": `${mediaContext.musicUserToken}`,
                "Content-Type": "application/json",
            },
        },
    );
    return data.data;
}
