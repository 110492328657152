import useOpenClose, { IUseOpenClose } from "@helper/custom-hooks/use-open-close";
import { Dispatch, SetStateAction, useState } from "react";

export interface IUseSignUpContext {
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
    nextStep?: () => void;
    selectedInterests: string[];
    addSelectedInterest: (item: string) => void;
    removeSelectedInterest: (item: string) => void;
    toggleSelectedInterest: (item: string) => void;
    hasSelectedItem: (item: string) => boolean;
    setSelectedInterests: Dispatch<SetStateAction<string[]>>;
    user: any;
    setUser: Dispatch<SetStateAction<any>>;
    clearUserState?: () => void;
    openClose?: IUseOpenClose;
}

const defaultSignUpData = {
    gender: "male",
    language: "de",
};

const useSignUpContext = (): IUseSignUpContext => {
    const [step, setStep] = useState<number>(0);
    const openClose = useOpenClose();

    const [selectedInterests, setSelectedInterests] = useState<string[]>([]);

    // The usage might be simplified with useReducer. Could be tricky to type, though.
    const [user, setUser] = useState(defaultSignUpData);

    const hasSelectedItem = (item: string): boolean => selectedInterests.includes(item);

    const addSelectedInterest = (item: string): void => {
        if (!selectedInterests.includes(item)) {
            setSelectedInterests([...selectedInterests, item]);
        }
    };

    const removeSelectedInterest = (item: string): void => {
        setSelectedInterests(selectedInterests.filter((v) => v !== item));
    };

    const toggleSelectedInterest = (item: string): void => {
        if (selectedInterests.includes(item)) {
            removeSelectedInterest(item);
        } else {
            addSelectedInterest(item);
        }
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const clearUserState = () => {
        setUser(defaultSignUpData);
    };

    return {
        step,
        setStep,
        nextStep,
        hasSelectedItem,
        selectedInterests,
        addSelectedInterest,
        removeSelectedInterest,
        toggleSelectedInterest,
        setSelectedInterests,
        user,
        setUser,
        clearUserState,
        openClose,
    };
};

export default useSignUpContext;
