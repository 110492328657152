import useFadeInOut from "@helper/custom-hooks/use-fade-in-out";
import useOnClickOutside from "@helper/custom-hooks/use-on-click-outside";
import useOpenClose from "@helper/custom-hooks/use-open-close";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import React, { FC, ReactNode, useRef } from "react";

export interface Properties {
    children: ReactNode;
    icon?: JSX.Element;
    className?: string;
}

const ThreeDotMenu: FC<Properties> = ({ children, icon, className }: Properties) => {
    const openClose = useOpenClose();

    const threeDotMenuFade = useFadeInOut(openClose.isOpen);

    const reference = useRef(null);

    useOnClickOutside(reference, openClose.handleClose);

    return (
        <div className="relative cursor-pointer text-white" ref={reference}>
            <div className="flex h-full" onClick={openClose.toggleOpenClose}>
                {icon || <DotsHorizontalIcon className="h-5 w-5 self-center text-black dark:text-white" />}
            </div>
            <div className={threeDotMenuFade.className}>
                {threeDotMenuFade.isOpen && (
                    <div className={`absolute top-0 right-0 ${className}`}>
                        <div className="relative p-4 " style={{ zIndex: 9999 }}>
                            <div
                                onClick={openClose.toggleOpenClose}
                                className="static top-10 flex w-full  flex-col rounded-lg bg-white p-2 shadow-xl dark:bg-rc-black-light"
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default ThreeDotMenu;
