import React, { FC } from "react";

export interface IDropdownOption {
    value?: string;
    text: string;
}

interface IDropdown {
    name: string;
    label?: string;
    forceSelection?: boolean;
    options: IDropdownOption[];
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
    value?: string;
    required?: boolean;
    hasBorder?: boolean;
}

const Dropdown: FC<IDropdown> = ({ name, label, options, forceSelection, onChange, value, required = false, hasBorder = true }: IDropdown) => (
    <div
        className={`relative flex w-full border-rc-gray-dark text-black focus-within:border-blue-500 dark:text-white ${
            hasBorder ? "border-b-4 " : "border-0"
        }`}
    >
        <select className="block w-full bg-transparent pt-2 text-black focus:outline-none dark:text-white" onChange={onChange} value={value || "-"}>
            {forceSelection && (
                <option value="-" disabled className="dark:bg-rc-dark dark:text-white">
                    -
                </option>
            )}
            {options.map((option, i) => (
                <option value={option.value} key={option.value + i} className="dark:bg-rc-dark dark:text-white">
                    {option.text}
                </option>
            ))}
        </select>
        {label && (
            <label
                htmlFor={name}
                className="pointer-events-none absolute top-0 origin-0 -translate-y-6 scale-75"
                dangerouslySetInnerHTML={{
                    __html: `${label} ${required ? '<span class="text-rc-pink">*</span>' : ""}`,
                }}
            />
        )}
    </div>
);

export default Dropdown;
