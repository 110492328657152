import create from "zustand";
import { devtools } from "zustand/middleware";

interface AppleMusicState {
    token: string;
    mediaContext: any;
    testToken: string;
    setMediaContext: (mediaContext: any) => void;
    setToken: (token: string) => void;

    setIsLoaded: (status: boolean) => void;
    isLoaded: boolean;
    logInStatus: number;
    setLogInStatus: (status: number) => void;
}

const AppleStore = create<AppleMusicState>(
    devtools(
        (set) => ({
            isLoaded: false,
            token: undefined,
            mediaContext: undefined,
            testToken: undefined,
            logInStatus: 0,
            setMediaContext: (music: any) => set(() => ({ mediaContext: music })),
            setToken: (token: string) => set(() => ({ token })),

            setIsLoaded: (status: boolean) => set(() => ({ isLoaded: status })),
            setLogInStatus: (status: number) => set(() => ({ logInStatus: status })),
        }),
        { name: "AppleStore" },
    ),
);

export default AppleStore;
