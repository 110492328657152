import ThreeDotMenu from "@components/three-dot-menu/three-dot-menu";
import ThreeDotMenuItem from "@components/three-dot-menu/three-dot-menu-item";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { msToTime } from "@helper/time-helper";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { IPlayerItem } from "@models/player/player-item";
import React, { FC } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import PlayerTicker from "./player-ticker";

export interface Properties {
    item: IPlayerItem;
    handleRemove: (index: number) => void;
    handleItemForPlaylist: (item: IPlayerItem) => void;
    index: number;
}

const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    position: "static",
});

const PlayerTrackRow: FC<Properties> = ({ item, handleRemove, handleItemForPlaylist, index }: Properties) => {
    const { t } = useTranslation();
    const duration = typeof item?.duration_ms === "number" ? msToTime(item?.duration_ms) : " ";
    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);

    const setQueue = PlayerStore((state) => state.setQueue);
    const queue = PlayerStore((state) => state.queue);
    const setCurrentPlayerItem = PlayerStore((state) => state.setCurrentPlayerItem);

    const handlePlayTrack = (): void => {
        setQueue(queue.slice(index + 1));
        setCurrentPlayerItem({ ...item });
        setIsPlaying(true);
    };
    return (
        <Draggable key={item.id} draggableId={item?.id?.toString() || item.uri} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`${snapshot.isDragging ? "bg-rc-pink" : ""} select-none`}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                    <div className="flex flex-row p-2" id={item?.position?.toString()}>
                        <img
                            onClick={handlePlayTrack}
                            className="my-auto h-10 w-10 cursor-pointer object-cover"
                            alt={item?.name}
                            src={item?.image_url}
                        />
                        <div onClick={handlePlayTrack} className="my-auto flex w-full cursor-pointer flex-col pl-2">
                            <div>
                                <PlayerTicker onlyOnHover maxWidth="11.5rem" text={item?.name} />
                                {Array.isArray(item?.artists) && (
                                    <div className="w-44 overflow-hidden truncate text-xs text-rc-gray-light">
                                        {item?.artists?.map((v) => v?.name).join(", ")}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row space-x-2 self-center">
                            <p className=" dark:text-white ">{duration}</p>
                            <ThreeDotMenu icon={<DotsVerticalIcon className="h-5 w-5 cursor-pointer  self-center text-black dark:text-white" />}>
                                <ThreeDotMenuItem onClick={() => handleItemForPlaylist({ ...item })} title={t("playlist.add")} />
                                <ThreeDotMenuItem title={t("generic.remove")} onClick={() => handleRemove(index)} />
                            </ThreeDotMenu>
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default PlayerTrackRow;
