import { apiClient } from "@helper/auth/auth-api-helper";
import { ICountry, IGetLocation, ILocationAddress, IMapBoxPlacesResponse } from "@models/location";
import axios, { AxiosResponse } from "axios";

export interface IUseLocationContext {
    reverseGeocoding: (lat: number, lng: number) => Promise<string[]>;
    getAddressFromLatLong: (lat: number, long: number) => Promise<ILocationAddress>;
    getLocationsOfAddress: (city: string, country: string, postalcode: string, street: string) => Promise<IGetLocation[]>;
    getCountries: () => Promise<ICountry[]>;
}

const ACCESS_TOKEN = "pk.eyJ1IjoidGltZWNvb2wiLCJhIjoiY2t5azQ5MXo2MGRpajJvdWZpMXBxMDk1eiJ9.M2mGJbLhpWDVaK1UnCLZMA";
const useLocationContext = (): IUseLocationContext => {
    const getCountries = () => apiClient.get(`${process.env.NEXT_PUBLIC_BACKEND}countries`).then((e) => e.data.data);

    const reverseGeocoding = (lat: number, lng: number) => {
        return axios
            .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${ACCESS_TOKEN}`)
            .then((response: AxiosResponse<IMapBoxPlacesResponse>) => {
                return response.data.features.map((e) => e.place_name);
            });
    };

    const getLocationsOfAddress = (city: string, country: string, postalcode: string, street: string): Promise<IGetLocation[]> => {
        return axios
            .get(
                `https://nominatim.openstreetmap.org/search?format=json${country ? `&country=${country}` : ""}${
                    postalcode ? `&postalcode=${postalcode}` : ""
                }${street ? `&street=$ ${street}` : ""}${city ? `&city=${city}` : ""}`,
            )
            .then((response) => response.data)
            .then((value) => value as IGetLocation[])
            .catch((error) => {
                throw error;
            });
    };

    const getAddressFromLatLong = (lat: number, long: number): Promise<ILocationAddress> => {
        return axios
            .get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`)
            .then((response) => response.data)
            .then((value) => value.address as ILocationAddress)
            .catch((error) => {
                throw error;
            });
    };

    return {
        getCountries,
        reverseGeocoding,
        getLocationsOfAddress,
        getAddressFromLatLong,
    };
};

export default useLocationContext;
