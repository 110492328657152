import "styles/globals.scss";
import "styles/wikipedia.css";
import "styles/gallery.css";
import "styles/star-rating.scss";
import "react-toastify/dist/ReactToastify.css";
import "pintura/pintura.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "styles/fonts.css";

import Footer from "@components/footer";
import Header from "@components/header/header";
import NavbarMobile from "@components/navbar/navbar-mobile";
import AddToPlaylistModal from "@components/playlist/add-to-playlist-modal";
import SignUpModal from "@components/sign-up-modal/sign-up-modal";
import VideoPlayerModal from "@components/video-player/video-player-modal/video-player-modal";
import VirutalTour from "@components/virtual-tour/virtual-tour";
import AppleStore from "@helper/apple/apple-store";
import { getUserData } from "@helper/auth/auth-api-helper";
import AuthStore from "@helper/auth/auth-store";
import useBreakpoints from "@helper/custom-hooks/use-breakpoints";
import useScrollRestoration from "@helper/custom-hooks/use-scroll-restoration";
import useRestrictRoute from "@helper/custom-hooks/useRestrictRoute";
import GlobalHistoryStore from "@helper/global-history-store";
import PlayerStore from "@helper/player/player-store";
import PlaylistStore from "@helper/playlist/playlist-store";
import ContextProvider from "@helper/react-context/context-provider";
import { getReminder } from "@helper/reminder/reminder-api-helper";
import ReminderStore from "@helper/reminder/reminder-store";
import { APIProvider } from "@vis.gl/react-google-maps";
import axios from "axios";
import { whitelistedPaths } from "constants/index";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "next-i18next.config";
import React, { FC, ReactNode, useEffect } from "react";
import { ToastContainer } from "react-toastify";

interface IPageContent {
    children: ReactNode;
}
// Added this fc-container to make use of the react context for the dark/light mode this far up in the DOM.
const PageLayout: FC<IPageContent> = ({ children }: IPageContent) => {
    const breakpoints = useBreakpoints();
    const APIKey = process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY;

    const user = AuthStore((state) => state.user);
    const setUser = AuthStore((state) => state.setUser);
    const setLastPage = GlobalHistoryStore((state) => state.setLastPage);
    const itemToBeAddedToPlaylist = PlaylistStore((state) => state.itemToBeAddedToPlaylist);
    const setItemToBeAddedToPlaylist = PlaylistStore((state) => state.setItemToBeAddedToPlaylist);

    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);

    const router = useRouter();
    const [loading, setLoading] = React.useState(false);
    const [loadedForTheFirstTime, setLoadedForTheFirstTime] = React.useState(false);
    // Fetching the User on each Reload to check if the user is logged in or not.
    useEffect(() => {
        setLoadedForTheFirstTime(false);
        getUserData()
            .then((user2) => {
                if (user2) {
                    setUser(user2);
                }
            })
            .catch(() => {
                if (!whitelistedPaths.includes(router.pathname)) {
                    // router.push('/login')
                    window.location.href = "/login";
                }
            })
            .finally(() => {
                setLoadedForTheFirstTime(true);
            });
    }, []);

    // Return User to the last page they were on before they logged out.
    useEffect(() => {
        if (!router.asPath.includes("/login") && router.asPath !== "/") {
            setLastPage(router.asPath);
        }
    }, [router.asPath]);

    // Apple MusicKit
    const setIsLoaded = AppleStore((state) => state.setIsLoaded);
    const isLoaded = AppleStore((state) => state.isLoaded);
    const setMediaContext = AppleStore((state) => state.setMediaContext);
    useRestrictRoute();

    async function registerMusicKit() {
        try {
            if (!isLoaded && !loading) {
                console.log("init musickit");
                const token = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND}/apple/music/token`);
                // @ts-ignore
                const data = await MusicKit.configure({
                    developerToken: token.data.accessToken,
                    app: {
                        name: "RealClixx",
                        build: "1978.4.1",
                    },
                });
                setMediaContext(data);
                setIsLoaded(true);
                setLoading(false);
                console.log("done musickit");
            }
        } catch (err) {
            setIsLoaded(false);
            setLoading(false);
            console.log(err);
        }
    }
    useEffect(() => {
        setLoading(true);
        window.addEventListener("musickitloaded", registerMusicKit);
    });

    // Reminder
    const setReminder = ReminderStore((state) => state.setReminder);
    useEffect(() => {
        const reminderCall = async () => {
            try {
                const data = await getReminder();
                if (data) setReminder(data);
            } catch (e) {
                console.error("Fail to call reminder", e);
            }
        };
        if (user) reminderCall();
    }, [user]);

    useScrollRestoration(router);

    if (!loadedForTheFirstTime) {
        return null;
    }
    return (
        <APIProvider apiKey={APIKey} onLoad={() => console.log("Maps API has loaded.")}>
            <div className="dark relative font-karla tracking-wide">
                {currentPlayerItem && (
                    <>
                        <VideoPlayerModal />
                    </>
                )}
                {typeof itemToBeAddedToPlaylist !== "undefined" && (
                    <AddToPlaylistModal item={itemToBeAddedToPlaylist} isOpen handleClose={() => setItemToBeAddedToPlaylist(undefined)} />
                )}
                <div className="dark:bg-rc-background">{children}</div>
                {!breakpoints.isLgAndAbove && user && <NavbarMobile />}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    limit={10}
                />
            </div>
        </APIProvider>
    );
};

const RealClixxApp = ({ Component, pageProps }: AppProps): JSX.Element => {
    return (
        <ContextProvider>
            <Head>
                <title key="title">Realclixx</title>
                <meta name="title" content="Realclixx" />
                <meta name="description" content="Realclixx" />
                <meta name="facebook-domain-verification" content="wkmbog3u0xgslpcpycv8z3ywrwd1ko" />
                <script
                    id="Cookiebot"
                    src="https://consent.cookiebot.com/uc.js"
                    data-cbid="be2184a1-4b04-42a9-a535-c7ad4b5c6e21"
                    data-blockingmode="auto"
                    type="text/javascript"
                    async
                ></script>
            </Head>

            <Script src="https://js-cdn.music.apple.com/musickit/v3/musickit.js" data-web-components async></Script>
            <Script async data-web-components src="https://player.twitch.tv/js/embed/v1.js"></Script>
            <PageLayout>
                <Header />
                <Component {...pageProps} />
                <VirutalTour />
                <SignUpModal />
            </PageLayout>

            <Footer />
        </ContextProvider>
    );
};

// @ts-ignore
export default appWithTranslation(RealClixxApp, nextI18NextConfig);
