/* eslint-disable import/no-extraneous-dependencies */
import Button from "@components/buttons/button";
import InputField from "@components/input-field";
import { handleValidateUsername } from "@helper/auth/auth-api-helper";
import useSignUp from "@helper/custom-hooks/use-sign-up";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import { useTranslation } from "next-i18next";
import React, { FC, useState } from "react";
import { toast } from "react-toastify";

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

const SignUpNameBirthday: FC = () => {
    const [loading, setLoading] = useState(false);
    const { user, setUser, nextStep } = useSignUp();
    const { t } = useTranslation();
    const [innerError, setInnerError] = useState<string>("");

    const isValidDate = (dateString: string) => {
        const dateFormat = "YYYY-MM-DD";
        const date = dayjs(dateString, dateFormat, true);
        if (!date.isValid()) {
            setInnerError(t("error.invalid_date_format"));
            return false;
        }

        const age = dayjs().diff(date, "year");
        if (age < 16) {
            setInnerError(t("error.must_be_older_than_16"));
            return false;
        }
        return true;
    };

    const handleOnClick = () => {
        if (user && user.birthday && user.username) {
            const isValid = isValidDate(user.birthday);
            if (!isValid) return;
            setLoading(true);
            handleValidateUsername(user.username)
                .then((data) => {
                    if (data.message === "Username is available") {
                        nextStep();
                    } else {
                        toast.error("This username is not available");
                    }
                })
                .catch(() => {
                    toast.error("This username is not available");
                })
                .finally(() => {
                    setLoading(false);
                    setInnerError("");
                });
        }
    };

    return (
        <form className="relative mt-20 space-y-10 px-6 md:px-0">
            <InputField
                name="username"
                label={`${t("generic.username")} (${t("generic.e-g")} realclixx.com/<span class="text-rc-pink">${
                    user.username || "username"
                }</span>) `}
                required
                type="text"
                symbolInFrontOfInput="@"
                value={user.username}
                onChange={(e) => setUser({ ...user, username: e.target.value })}
            />
            <InputField
                name="dateOfBirth"
                label={t("generic.birthday")}
                type="date"
                value={user.birthday}
                required
                onChange={(data) => setUser({ ...user, birthday: data.target.value })}
            />
            {innerError && <p className="text-red-500">{innerError}</p>}
            <div>
                <p className=" text-sm text-rc-pink">* {t("generic.mandatory_field")}</p>
            </div>
            <Button title={t("generic.continue")} onClick={handleOnClick} loading={loading} disabled={loading} />
        </form>
    );
};

export default SignUpNameBirthday;
