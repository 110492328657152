import { logInvalidModel } from "@helper/toasts/log-invalid-model";
import { isString } from "@helper/types";

export interface ILanguage {
    id: string;
    title: string;
    short: string;
    country: string;
    langSelect: string;
}

export const isILanguage = (val: ILanguage | undefined): boolean => {
    if (
        typeof val !== "undefined" &&
        isString(val?.id) &&
        isString(val?.title) &&
        isString(val?.short) &&
        isString(val?.country) &&
        isString(val?.langSelect)
    ) {
        return true;
    }

    logInvalidModel(val, "ILanguage");

    return false;
};
