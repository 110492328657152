/* eslint-disable no-underscore-dangle */
import MediumVideoPlayer from "@components/video-player/medium-video-player";
import { nextTrack } from "@helper/player/player-helper";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { FC } from "react";

const YoutubePlayer: FC = () => {
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const isLargeVideoPlayer = PlayerSettingsStore((state) => state.isLargeVideoPlayer);

    if (isLargeVideoPlayer || !currentPlayerItem) return <></>;

    return (
        <div className="right-0 z-10 mb-0.5">
            <MediumVideoPlayer
                controls={false}
                link={`/video/youtube/${currentPlayerItem.resource_id}`}
                videoUrl={`https://www.youtube.com/watch?v=${currentPlayerItem.resource_id}`}
                onEnded={() => {
                    nextTrack();
                }}
            />
        </div>
    );
};

export default YoutubePlayer;
