import { ReactNode } from "react";
import { Step } from "react-joyride";

export interface IVirtualTourStep extends Omit<Step, "target" | "content"> {
    // [target] should be a id of element (without "#")
    target: string;
    content: ReactNode;
    contentList?: {
        type?: "text" | "image";
        value: string;
    }[];
}

export const VIRTUAL_TOUR_STEPS: Record<string, IVirtualTourStep> = {
    NAVBAR_SOCIALS_ICON: {
        title: "Connect your socials!",
        target: "navbar-socials-icon",
        content: "",
        contentList: [
            {
                type: "text",
                value: "Connect all your social channels with a few clicks",
            },
        ],
    },
    NAVBAR_MENU_ICON: {
        title: "Menu!",
        target: "navbar-menu-icon",
        content: "",
        contentList: [
            { type: "image", value: "/virtual_tour_navbar_social.jpg" },
            {
                type: "text",
                value: "To open different parts of application, you can use menu button. To explore other parts of the app, let's go to playlists section. To do this, click the menu button and choose Library from the drop-down list",
            },
        ],
    },
    MULTIMEDIA_VIDEOS_APPLE_VIDEO_LABEL: {
        title: "Connect your Apple!",
        target: "multimedia-videos-apple-label",
        content: "",
        contentList: [
            {
                type: "text",
                value: "Welcome to Realclixx! To connect your social networks, open the app connector by clicking on the icon",
            },
        ],
    },
    MULTIMEDIA_INTERESTS_AND_HASHTAGS: {
        title: "Manage interests & hashtags",
        target: "navbar-menu-icon",
        content: "",
        contentList: [
            {
                type: "text",
                value: `In the multimedia section you'll see some predefined content form certain social channels. Please go to settings and manage your own interests and hashtags so that we can show personalized content.`,
            },
        ],
    },
    MY_PROFILE_ICON: {
        title: "My profile",
        target: "my-profile-icon",
        content: "",
        contentList: [
            {
                type: "text",
                value: "In your profile you will find all your activities and interests in Realclixx",
            },
        ],
    },
    DASHBOARD_WHATS_NEW_BOX: {
        title: "What’s new",
        target: "dashboard-whats-new-box",
        content: "",
        contentList: [
            {
                type: "text",
                value: "What's new in your subscribed channels? Here you can find an overview of the most recently published content",
            },
        ],
    },
    PROFILE_DASHBOARD: {
        title: "Your dashboard",
        target: "profile-dashboard-label",
        content: "",
        contentList: [
            {
                type: "text",
                value: "You can check your general info here!",
            },
        ],
    },
    PROFILE_INFO: {
        title: "Your info",
        target: "profile-info-label",
        content: "",
        contentList: [
            {
                type: "text",
                value: "You can check your general info here!",
            },
        ],
    },
    PROFILE_POSTS: {
        title: "Your post",
        target: "profile-post-label",
        content: "",
        contentList: [
            {
                type: "text",
                value: "You can check your posts here!",
            },
        ],
    },
    PROFILE_SUBSCRIBED: {
        title: "Your subscribed",
        target: "profile-subscribed-label",
        content: "",
        contentList: [
            {
                type: "text",
                value: "You can check your subscribed here!",
            },
        ],
    },
    PROFILE_LIKE_SHARE: {
        title: "Your likes and shares",
        target: "profile-like-share-label",
        content: "",
        contentList: [
            {
                type: "text",
                value: "You can check your likes and shares here here!",
            },
        ],
    },
    PROFILE_PLAYLISTS: {
        title: "Your playlists",
        target: "profile-playlist-label",
        content: "",
        contentList: [
            {
                type: "text",
                value: "You can check your playlists here here!",
            },
        ],
    },
    PROFILE_FOLLOWER: {
        title: "Follower",
        target: "profile-follower-label",
        content: "",
        contentList: [
            {
                type: "text",
                value: "You can check your followers here!",
            },
        ],
    },
    SETTING_INTERESTS: {
        title: "Manage interests",
        target: "setting-interests-menu",
        content: "",
        contentList: [
            {
                type: "text",
                value: "Here you can manage your interests.",
            },
        ],
    },
    SETTING_HASHTAGS: {
        title: "Manage hashtags",
        target: "setting-hashtags-menu",
        content: "",
        contentList: [
            {
                type: "text",
                value: "Here you can manage your hashtags that interest you.",
            },
        ],
    },
};

export const DASHBOARD_VIRTUAL_TOUR_KEY = "dashboard_virtual_tour";
export const DASHBOARD_VIRTUAL_TOUR_STEPS = [
    VIRTUAL_TOUR_STEPS.NAVBAR_SOCIALS_ICON,
    VIRTUAL_TOUR_STEPS.MY_PROFILE_ICON,
    VIRTUAL_TOUR_STEPS.MULTIMEDIA_INTERESTS_AND_HASHTAGS,
    VIRTUAL_TOUR_STEPS.DASHBOARD_WHATS_NEW_BOX,
];

export const PROFILE_VIRTUAL_TOUR_KEY = "profile_virtual_tour";
export const PROFILE_VIRTUAL_TOUR_STEPS = [
    VIRTUAL_TOUR_STEPS.PROFILE_DASHBOARD,
    VIRTUAL_TOUR_STEPS.PROFILE_INFO,
    VIRTUAL_TOUR_STEPS.PROFILE_POSTS,
    VIRTUAL_TOUR_STEPS.PROFILE_SUBSCRIBED,
    VIRTUAL_TOUR_STEPS.PROFILE_LIKE_SHARE,
    VIRTUAL_TOUR_STEPS.PROFILE_PLAYLISTS,
];

export const SETTING_VIRTUAL_TOUR_KEY = "setting_virtual_tour";
export const SETTING_VIRTUAL_TOUR_STEPS = [VIRTUAL_TOUR_STEPS.SETTING_INTERESTS, VIRTUAL_TOUR_STEPS.SETTING_HASHTAGS];

export const MULTIMEDIA_VIRTUAL_TOUR_KEY = "multimedia_virtual_tour";
export const MULTIMEDIA_VIRTUAL_TOUR_STEPS = [
    VIRTUAL_TOUR_STEPS.NAVBAR_SOCIALS_ICON,
    VIRTUAL_TOUR_STEPS.NAVBAR_MENU_ICON,
    VIRTUAL_TOUR_STEPS.MULTIMEDIA_VIDEOS_APPLE_VIDEO_LABEL,
];
