import Button from "@components/buttons/button";
import ButtonSkip from "@components/buttons/button-skip";
import Dropdown from "@components/dropdowns/dropdown";
import { handleRegister } from "@helper/auth/auth-api-helper";
import useSignUp from "@helper/custom-hooks/use-sign-up";
import { removeLocalStorageVirtualTour } from "@helper/virtual-tour/virtual-tour-helper";
import { useTranslation } from "next-i18next";
import React, { FC, useState } from "react";
import { toast } from "react-toastify";

import { LANGUAGES } from "../../constants/language";

const SignUpLanguage: FC = () => {
    const [loading, setLoading] = useState(false);
    const [skipLoading, setSkipLoading] = useState(false);
    const { user, setUser, setStep, nextStep } = useSignUp();
    const { t } = useTranslation();

    const onRegister = async (user) => {
        try {
            const success = await handleRegister(user);
            removeLocalStorageVirtualTour();
            if (success) {
                nextStep();
            } else {
                toast("Something went wrong");
            }
        } catch (error) {
            toast("Something went wrong");
        }
    };

    const handleOnClick = async () => {
        if (user && user.birthday && user.username) {
            try {
                setLoading(true);
                await onRegister(user);
            } catch (error) {
                setStep(0);
                throw error;
            } finally {
                setLoading(true);
            }
        }
    };

    const onSkip = async () => {
        if (user && user.birthday && user.username) {
            try {
                setSkipLoading(true);
                await onRegister(user);
            } catch (error) {
                setStep(0);
                throw error;
            } finally {
                setSkipLoading(false);
            }
        }
    };

    return (
        <div className="mt-5">
            <div className="mb-5 flex flex-col justify-center text-white">
                <span className="text-center text-3xl">{"What's your language?"}</span>
                <span className="mt-2 text-center text-base">
                    {"This helps us find more relevant content for you. We won't show it on your profile."}
                </span>
            </div>
            <form className="relative mt-20 space-y-10 px-6 md:px-0">
                <Dropdown
                    name="language"
                    label={t("generic.language")}
                    onChange={(data) => setUser({ ...user, language: data.target.value })}
                    value={user.language}
                    options={LANGUAGES}
                />
                <div>
                    <p className=" text-sm text-rc-pink">* {t("generic.mandatory_field")}</p>
                </div>
                <div className="flex justify-between gap-5">
                    <ButtonSkip className="text-white" title={"Skip"} onClick={onSkip} loading={skipLoading} />
                    <Button disabled={loading} title={t("generic.continue")} onClick={handleOnClick} loading={loading} />
                </div>
            </form>
        </div>
    );
};

export default SignUpLanguage;
