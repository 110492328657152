import React, { Dispatch, SetStateAction, useState } from "react";

export interface ITextState {
    text: string;
    setText: Dispatch<SetStateAction<string>>;
    resetText: () => void;
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
}

/**
 * Custom hook for states that hold a string. Will likely be expanded.
 * Also used for easier expansion of the React Context.
 * @param initialState Optional initial string value.
 * @returns ITextState
 */
const useTextState = (initialState = ""): ITextState => {
    const [text, setText] = useState<string>(initialState || "");

    const resetText = () => {
        setText(initialState);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event?.target?.value);
    };

    return { text, setText, resetText, onChange };
};

export default useTextState;
