import React from "react";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface ISort {
    name: string;
    valueTmbdMovies: string;
    valueTmbdSeries: string;
}

export const SortFilterEnum: ISort[] = [
    {
        name: "Popularity",
        valueTmbdSeries: "popularity.desc",
        valueTmbdMovies: "popularity.desc",
    },
    {
        name: "Release Date",
        valueTmbdSeries: "first_air_date.desc",
        valueTmbdMovies: "release_date.desc",
    },
    {
        name: "User Voting",
        valueTmbdSeries: "vote_average.desc",
        valueTmbdMovies: "vote_average.desc",
    },
];

export interface SortFilterState {
    sortFilterValues: ISort[];
    selectedSortFilter: ISort;
    setSelectedSortFilter: (sortFilter: ISort) => void;
}

export const SortFilterStore = create<SortFilterState>(
    devtools(
        (set) => ({
            sortFilterValues: SortFilterEnum,
            selectedSortFilter: SortFilterEnum[0],
            setSelectedSortFilter: (sortFilter: ISort) => set(() => ({ selectedSortFilter: sortFilter })),
        }),
        { name: "SortFilterStore" },
    ),
);

export const SortFilter = () => {
    const { sortFilterValues, selectedSortFilter, setSelectedSortFilter } = SortFilterStore();
    return (
        <div className="flex w-1/6 flex-col gap-4 p-2">
            <p className="border-b border-gray-600 pb-2 font-oswald text-lg font-bold uppercase">Sort</p>
            <div className="flex flex-col gap-2">
                {sortFilterValues &&
                    sortFilterValues.length > 0 &&
                    sortFilterValues.map((sortValue: ISort) => (
                        <div
                            className={`w-1/4 cursor-pointer hover:underline  ${
                                sortValue === selectedSortFilter ? "text-rc-pink" : "hover:text-rc-pink"
                            }`}
                            key={sortValue.name}
                            onClick={() => setSelectedSortFilter(sortValue)}
                        >
                            <span className={`flex items-center whitespace-nowrap`}>{sortValue.name}</span>
                        </div>
                    ))}
            </div>
        </div>
    );
};
