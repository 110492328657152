import Link from "next/link";
import React, { FC, ReactNode } from "react";

export interface Props {
    name: string;
    href: string;
    icon: ReactNode;
}

const NavbarMenuLink: FC<Props> = ({ name, href, icon }: Props) => (
    <Link href={href}>
        <a className="flex flex-row gap-1.5 border-b border-rc-gray-light py-4 tracking-wide text-black dark:text-white lg:mb-2 lg:py-0 lg:pb-2">
            <div className="flex w-10 items-center justify-center ">{icon}</div>
            <div className="flex w-full grow flex-row justify-between">
                <span className="ml-2 self-center text-xl lg:text-base">{name}</span>
            </div>
        </a>
    </Link>
);
export default NavbarMenuLink;
