/* eslint-disable no-underscore-dangle */
import Networks from "@helper/networks";
import { PostTopicResponse } from "@models/post";
import axios from "axios";
import { toast } from "react-toastify";

export const twitterClient = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_BACKEND}/twitter`,
    withCredentials: true,
});

let isFirstRefreshRequest = true;
twitterClient.interceptors.response.use(null, (error) => {
    if (error.config && error.response && error.response.status === 401 && isFirstRefreshRequest) {
        isFirstRefreshRequest = false;
        return axios
            .create({
                baseURL: `${process.env.NEXT_PUBLIC_BACKEND}/twitter`,
                withCredentials: true,
            })
            .get(`${process.env.NEXT_PUBLIC_BACKEND}/twitter/refresh`)
            .then(() => {
                isFirstRefreshRequest = true;
                return axios.request(error.config);
            })
            .catch((error) => {
                if (error.message === "canceled") {
                    return;
                }
                if (error.response.status === 409) {
                    toast.error("Please reconnect your Twitter account");
                } else {
                    toast.error("Failed to refresh Twitter token, please try again");
                }
                return Promise.reject(error);
            });
    }

    return Promise.reject(error);
});

// checked
export const getHomeTimelineMedia = async () => {
    const homeTimeLine = await twitterClient.get("/home_timeline");
    const result = homeTimeLine?.data?.posts ?? [];

    return result?.map((v) => ({
        ...v,
        networkName: Networks.twitter.name,
        networkImage: Networks.twitter.iconUrl,
    }));
};

// checked
export async function getUserInformation(id: string) {
    const result = await twitterClient.get(`/getUserInformation?id=${id}`);
    const rest = await result.data;
    return rest;
}

// checked
export async function getTweetById(tweetId: string) {
    const result = await twitterClient.get(`/getTweetByTweetId?id=${tweetId}`);
    const ret = await result.data;
    return ret;
}

// checked
export async function searchTweets(query: string) {
    const result = await twitterClient.get(`/search/tweets/${encodeURIComponent(query)}`);
    return result.data;
}

export async function likeTweet(tweetId: string) {
    await twitterClient.post("/likeTweet", {
        id: tweetId,
    });

    return true;
}

export async function removeLike(tweetId: string) {
    await twitterClient.post("/removeLike", {
        id: tweetId,
    });

    return true;
}

export async function getLikeStatus(tweetId: string) {
    const result = await twitterClient.get(`/tweet/${tweetId}/rating`);

    return result.data;
}

export async function makeReplay(tweetId: string, text: string) {
    const data = await twitterClient.post("/makeReply", {
        id: tweetId,
        text,
    });

    return data.data;
}

export async function retweet(tweetId: string) {
    await twitterClient.post("/retweet", {
        id: tweetId,
    });

    return true;
}

export async function unRetweet(tweetId: string) {
    await twitterClient.post("/unRetweet", {
        id: tweetId,
    });

    return true;
}

// checked
export async function getReplies(id) {
    const [repliesRes, internalRepliesRes] = await Promise.all([
        twitterClient.get(`/getRepliesById?id=${id}`),
        twitterClient.get(`/getInternalRepliesById?id=${id}`),
    ]);

    return [...(repliesRes?.data?.posts ?? []), ...(internalRepliesRes?.data?.posts ?? [])].sort(
        (a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
    );
}

// checked
export async function getTwitterSearchImages(searchQuery: string) {
    const result = await twitterClient.get(`/get-photos?query=${encodeURIComponent(searchQuery)}`);
    return result.data;
}

export async function getTrendingTopics() {
    const result = await twitterClient.get("/get-trends");
    return result.data as PostTopicResponse;
}

// checked
export async function getTwitterSearchVideos(searchQuery: string) {
    const result = await twitterClient.get(`/get-video?query=${encodeURIComponent(searchQuery)}`);
    return result.data;
}

// checked
export async function getTweetsForUserId(userId?: string) {
    const response = await twitterClient.get(`/getTweetsForUserId?id=${userId}`);

    return response.data;
}

// checked
export async function getTweetsFromUserName(userName: string, nextToken: string = null) {
    let url = `/getTweetsFromUserName?username=${userName}`;
    if (nextToken) {
        url += `&nextToken=${nextToken}`;
    }
    const response = await twitterClient.get(url);

    return response.data;
}

export async function getOwnTwitterFollowings() {
    const data = await twitterClient.get("/getSubscribers");

    return data.data;
}
