import PlayerPersistSettingsStore from "./player-persist-settings-store";
import PlayerSettingsStore from "./player-settings-store";
import PlayerStore from "./player-store";
import { updatePlayingPosition } from "./queue-handler";

export function nextTrack() {
    const { queue, currentPlayerList, currentPlayerItem, shuffleList, history, setQueue, setCurrentPlayerItem, setShuffleList, setHistory } =
        PlayerStore.getState();
    const { setIsPlaying } = PlayerSettingsStore.getState();
    const { setPlayerTime, setSeek } = PlayerPersistSettingsStore.getState();

    setPlayerTime(0);
    setSeek(0);
    setIsPlaying(false);
    setHistory([currentPlayerItem, ...(history || [])]);

    if (queue && queue.length > 0) {
        const temporary = queue;
        setCurrentPlayerItem(temporary[0]);
        temporary.shift();
        setQueue(temporary);
        setIsPlaying(true);
        return;
    }
    if (shuffleList?.tracks.length > (shuffleList?.playing_position ?? 0) + 1) {
        setCurrentPlayerItem(shuffleList.tracks[shuffleList.playing_position + 1]);
        setShuffleList({
            ...currentPlayerList,
            playing_position: shuffleList.playing_position + 1,
        });

        setIsPlaying(true);
        return;
    }
    if (currentPlayerList?.tracks?.length > (currentPlayerList?.playing_position ?? 0) + 1) {
        setCurrentPlayerItem(currentPlayerList.tracks[currentPlayerList.playing_position + 1]);
        updatePlayingPosition(currentPlayerList.playing_position + 1);
        setIsPlaying(true);
        return;
    }

    setCurrentPlayerItem(undefined);
}

export function prevTrack() {
    const { setCurrentPlayerItem, setHistory, history } = PlayerStore.getState();
    const { setIsPlaying } = PlayerSettingsStore.getState();

    setIsPlaying(false);

    if (history && history.length > 0) {
        const temporary = history;

        setCurrentPlayerItem(temporary[0]);
        temporary.shift();
        setHistory(temporary);
        setIsPlaying(true);
        return;
    }
    setIsPlaying(true);
}
