import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import PlaylistStore from "@helper/playlist/playlist-store";
import { XIcon } from "@heroicons/react/solid";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import PlayerListRow from "./player-list-row";
import PlayerTrackRow from "./player-track-row";

const PlayerQueueList: FC = () => {
    const { t } = useTranslation();
    const setItemToBeAddedToPlaylist = PlaylistStore((state) => state.setItemToBeAddedToPlaylist);

    const queue = PlayerStore((state) => state.queue);
    const setQueue = PlayerStore((state) => state.setQueue);
    const setShuffleList = PlayerStore((state) => state.setShuffleList);
    const shuffleList = PlayerStore((state) => state.shuffleList);
    const setCurrentPlayerList = PlayerStore((state) => state.setCurrentPlayerList);
    const currentPlayerList = PlayerStore((state) => state.currentPlayerList);
    const queueListOpen = PlayerSettingsStore((state) => state.queueListOpen);
    const setQueueListOpen = PlayerSettingsStore((state) => state.setQueueListOpen);
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const temporary = [...queue];
        const [removed] = temporary.splice(result.source.index, 1);
        temporary.splice(result.destination.index, 0, removed);
        setQueue(temporary);
    };
    if (!queueListOpen) return <></>;
    return (
        <div className="relative z-40 shadow-xl">
            <div className="absolute -inset-0.5 rounded-t-lg bg-gradient-to-r from-rc-pink to-rc-blue lg:rounded-r-none " />
            <div className="relative h-screen-3/4 rounded-t-lg bg-white py-2 pl-4 pr-2 dark:bg-rc-dark dark:text-white lg:h-80 lg:rounded-r-none">
                <div className="flex flex-row justify-between">
                    <span className="text-xl"></span>
                    <div className="my-auto flex h-full space-x-2">
                        <button
                            type="button"
                            className="my-auto rounded-lg border border-rc-gray-light py-0.5 px-4 text-xs  text-rc-gray-light transition  hover:border-rc-pink hover:text-rc-pink"
                            onClick={() => {
                                setQueue([]);
                                setCurrentPlayerList(undefined);
                                setShuffleList(undefined);
                            }}
                        >
                            {t("player.clear_queue")}
                        </button>
                        <XIcon className="h-7 w-7 cursor-pointer self-center transition hover:text-rc-pink" onClick={() => setQueueListOpen(false)} />
                    </div>
                </div>
                <div className="flex h-64 flex-col  overflow-x-hidden overflow-y-scroll ">
                    {queue && queue.length > 0 && (
                        <>
                            <div className="mt-4 border-b border-rc-gray-light text-sm  text-rc-gray-light ">Warteschlange</div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {Array.isArray(queue) &&
                                                queue
                                                    .filter((f) => !!f?.uri)
                                                    .map((v, index) => (
                                                        <PlayerTrackRow
                                                            key={`AudioPlayerTrackRow_${v?.resource_id}_${v?.position}`}
                                                            item={v}
                                                            handleItemForPlaylist={setItemToBeAddedToPlaylist}
                                                            handleRemove={() => {
                                                                setQueue(queue.filter((f) => f.uri !== v.uri));
                                                            }}
                                                            index={index}
                                                        />
                                                    ))}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </>
                    )}
                    {shuffleList && shuffleList.tracks?.length > shuffleList.playing_position + 1 ? (
                        <div>
                            <div className="border-b border-rc-gray-light text-sm  text-rc-gray-light ">{shuffleList.name}</div>
                            {shuffleList.tracks?.slice(currentPlayerList.playing_position + 1).map((v, index) => (
                                <>
                                    <PlayerListRow key={`AudioPlayerListRow_${v?.resource_id}_${index}`} item={v} index={index} />
                                </>
                            ))}
                        </div>
                    ) : (
                        currentPlayerList &&
                        currentPlayerList.tracks?.length > currentPlayerList.playing_position + 1 && (
                            <div>
                                <div className="border-b border-rc-gray-light text-sm  text-rc-gray-light ">{currentPlayerList.name}</div>
                                {currentPlayerList.tracks?.slice(currentPlayerList.playing_position + 1).map((v, index) => (
                                    <>
                                        <PlayerListRow key={`AudioPlayerListRow_${v?.resource_id}_${index}`} item={v} index={index} />
                                    </>
                                ))}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default PlayerQueueList;
