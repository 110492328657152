import ButtonOutline from "@components/buttons/button-outline";
import { Checkbox, DropzoneField, InputField, SelectField, TextAreaField } from "@components/react-form/fields";
import { refreshUserData } from "@helper/auth/auth-api-helper";
import { getActiveCompany } from "@helper/auth/companies-helper";
import useCompanyProfile from "@helper/custom-hooks/use-company-profile";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanySize, ICreateProfileRequestBody } from "components/companies/type";
import { COMPANY_SIZE_OPTIONS } from "constants/company";
import { createCompanyProfile, editCompanyProfile } from "helper/companies/companies-api-helper";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

type ProfileFormValues = {
    name: string;
    slug: string;
    about?: string;
    videoUrl?: string;
    industry: string;
    size: CompanySize;
    headquarters: string;
    founded: string;
    spezialist: string;
    websiteUrl: string;
    contact: {
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
    };
    termsAndConditions: boolean;
    verificationFiles: FileList;
};

export enum BaseProfileFormMode {
    ADD = "ADD",
    EDIT = "EDIT",
}

type BaseProfileFormProp = {
    mode: BaseProfileFormMode;
};

const schema = yup
    .object({
        name: yup.string().required("Company name is required"),
        slug: yup.string().required("Slug is required"),
        size: yup.string().required("Employees is required"),
        industry: yup.string().required("Industry is required"),
        videoUrl: yup
            .string()
            .nullable()
            .optional()
            .matches(/^(?:$|https?:\/\/(?:www\.)?(youtube\.com|youtu\.be|vimeo\.com)\/.+)$/, "Video url is not valid"),
        spezialist: yup.string(),
        founded: yup
            .string()
            .matches(/^\d{4}$/, "Year must be in YYYY format")
            .required("Founded is required"),
        websiteUrl: yup.string().url("Website url is not valid"),
        headquarters: yup.string(),
        contact: yup.object({
            email: yup.string().email("Email is not valid"),
            firstName: yup.string(),
            lastName: yup.string(),
            phone: yup.string(),
        }),
        termsAndConditions: yup
            .boolean()
            .oneOf([true], "Accept terms and conditions is required")
            .required("Accept terms and conditions is required"),
        verificationFiles: yup.mixed().required("Verification files are required"),
    })
    .required();

const BaseProfileForm = (props: BaseProfileFormProp) => {
    const { mode } = props;
    const { openCloseAddCompany, openCloseEditCompany } = useCompanyProfile();
    let defaultCompany;
    if (mode === BaseProfileFormMode.EDIT) {
        defaultCompany = getActiveCompany();
        defaultCompany.contact = {
            email: defaultCompany.contactEmail,
            firstName: defaultCompany.contactFirstName,
            lastName: defaultCompany.contactLastName,
            phone: defaultCompany.contactPhone,
        };
    }
    const [isLoading, setIsLoading] = useState(false);
    const { handleSubmit, control, trigger, getValues } = useForm<ProfileFormValues>({
        mode: "onBlur",
        resolver: yupResolver<any>(schema),
        ...(mode === BaseProfileFormMode.EDIT && { defaultValues: { ...defaultCompany, termsAndConditions: true } }),
    });
    const handleAddCompany = async (createdData: ICreateProfileRequestBody) => {
        const {
            verificationFiles,
            name = "",
            slug = "",
            size = "",
            industry = "",
            about = "",
            spezialist = "",
            founded = "",
            websiteUrl = "",
            headquarters = "",
            videoUrl = "",
            contact,
        } = createdData;
        const formData = new FormData();

        formData.append("name", name);
        formData.append("slug", slug);
        formData.append("size", size);
        formData.append("industry", industry);
        formData.append("about", about);
        formData.append("spezialist", spezialist);
        formData.append("founded", founded);
        formData.append("websiteUrl", websiteUrl);
        formData.append("headquarters", headquarters);
        formData.append("videoUrl", videoUrl);
        formData.append("contact", JSON.stringify(contact));

        if (verificationFiles) {
            verificationFiles.forEach((file: File) => {
                formData.append("verificationFiles", file);
            });
        }
        try {
            setIsLoading(true);
            await createCompanyProfile(formData);
            await refreshUserData();
            openCloseAddCompany.handleClose();
            toast.success("Company profile created successfully");
        } catch (error) {
            toast.error(`Error creating company profile ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditCompany = async (updatedData: ICreateProfileRequestBody) => {
        try {
            const {
                verificationFiles,
                name = "",
                slug = "",
                size = "",
                industry = "",
                about = "",
                spezialist = "",
                founded = "",
                websiteUrl = "",
                headquarters = "",
                videoUrl = "",
                contact,
            } = updatedData;
            const formData = new FormData();

            formData.append("name", name);
            formData.append("slug", slug);
            formData.append("size", size);
            formData.append("industry", industry);
            formData.append("about", about);
            formData.append("spezialist", spezialist);
            formData.append("founded", founded);
            formData.append("websiteUrl", websiteUrl);
            formData.append("headquarters", headquarters);
            formData.append("videoUrl", videoUrl);
            formData.append("contact", JSON.stringify(contact));

            if (verificationFiles) {
                verificationFiles.forEach((file: File | string) => {
                    if (file instanceof File) {
                        formData.append("verificationFiles", file);
                    } else if (typeof file === "string") {
                        formData.append("verificationFileUrl", file);
                    }
                });
            }

            setIsLoading(true);
            await editCompanyProfile(formData);
            await refreshUserData();
            openCloseEditCompany.handleClose();
            toast.success("Company profile edited successfully");
        } catch (error) {
            toast.error("Error updating your company profile");
        } finally {
            setIsLoading(false);
        }
    };

    const onSubmit = async (data: ProfileFormValues) => {
        const { name, slug, size, industry, about, spezialist, founded, websiteUrl, headquarters, contact, videoUrl, verificationFiles } = data;

        const createdData: ICreateProfileRequestBody = {
            name,
            slug: slug.toLowerCase(),
            about,
            videoUrl,
            industry,
            size,
            headquarters,
            founded,
            spezialist,
            websiteUrl,
            contact,
            verificationFiles,
        };

        if (mode === BaseProfileFormMode.ADD) {
            handleAddCompany(createdData);
        } else {
            handleEditCompany(createdData);
        }
    };

    const handleCustomSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
            const formData = getValues();
            console.log(formData);
            handleSubmit(onSubmit)();
        }
    };

    const companySizeOptions = [...[{ text: "Select size", value: "" }], ...COMPANY_SIZE_OPTIONS];
    return (
        <form className="mx-auto max-w-md">
            <h2 className="mb-8 border-y-2 border-gray-600 py-4 text-lg font-bold">Company Info</h2>
            <div className="mb-4">
                <InputField name="name" control={control} label="Company Name*" />
            </div>
            <div className="mb-4">
                <InputField name="slug" control={control} label="Slug*" />
            </div>
            <div className="mb-4">
                <TextAreaField name="about" control={control} label="About" />
            </div>
            <div className="mb-4">
                <InputField name="videoUrl" control={control} label="Youtube/Vimeo video link" />
            </div>
            <div className="mb-4">
                <InputField name="industry" control={control} label="Industry*" />
            </div>

            <div className="mb-4">
                <SelectField options={companySizeOptions} name="size" control={control} label="Employees*" />
            </div>

            <div className="mb-4">
                <InputField name="headquarters" control={control} label="Headquarters" />
            </div>
            <div className="mb-4">
                <InputField name="founded" control={control} label="Founded*" placeholder="YYYY" />
            </div>
            <div className="mb-4">
                <InputField name="spezialist" control={control} label="Spezialist in" />
            </div>
            <div className="mb-4">
                <InputField name="websiteUrl" control={control} label="Website url" />
            </div>
            {mode !== BaseProfileFormMode.EDIT && (
                <div className="mb-4">
                    <Checkbox
                        name="termsAndConditions"
                        control={control}
                        label=""
                        anchorLink={`${window.location.protocol}//${window.location.host}/termsandconditions`}
                        anchorText={"Accept terms and conditions*"}
                    />
                </div>
            )}

            <h2 className="my-8 border-y-2 border-gray-600 py-4 text-lg font-bold">Contact Information</h2>
            <div className="mb-4">
                <InputField name="contact.firstName" control={control} label="First Name" />
            </div>
            <div className="mb-4">
                <InputField name="contact.lastName" control={control} label="Last Name" />
            </div>
            <div className="mb-4">
                <InputField name="contact.email" control={control} label="E-Mail Address" />
            </div>
            <div className="mb-4">
                <InputField name="contact.phone" control={control} label="Phone" />
            </div>
            <div className="mb-4">
                <DropzoneField
                    supportedFileTypes={[".png", ".jpeg", ".jpg", ".pdf"]}
                    multiple
                    name="verificationFiles"
                    control={control}
                    label="Verification files*"
                />
            </div>
            <div className="flex items-center justify-between">
                <ButtonOutline onClick={handleCustomSubmit} loading={isLoading}>
                    {mode === BaseProfileFormMode.ADD ? "Create Company" : "Update Company"}
                </ButtonOutline>
            </div>
        </form>
    );
};

export default BaseProfileForm;
