/* eslint-disable no-underscore-dangle */
import Networks from "@helper/networks";
import { nextTrack } from "@helper/player/player-helper";
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { FC, useEffect, useState } from "react";

const TwitchPlayer: FC = () => {
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const playerSlideIn = PlayerSettingsStore((state) => state.playerSlideIn);
    const [twitchPlayer, setTwitchPlayer] = useState(undefined);
    const [twitchOptions, setTwitchOptions] = useState(undefined);
    const isPlaying = PlayerSettingsStore((state) => state.isPlaying);
    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);
    const volume = PlayerPersistSettingsStore((state) => state.volume);

    useEffect(() => {
        if (twitchPlayer) {
            // @ts-ignore
            twitchPlayer.addEventListener(Twitch.Player.PLAY, () => {
                if (!isPlaying) setIsPlaying(true);
            });
            // @ts-ignore
            twitchPlayer.addEventListener(Twitch.Player.PAUSE, () => {
                if (isPlaying) setIsPlaying(false);
            });
            // @ts-ignore
            twitchPlayer.addEventListener(Twitch.Player.OFFLINE, () => {
                nextTrack();
            });
        }
    });
    console.log(currentPlayerItem);
    useEffect(() => {
        if (currentPlayerItem?.platform === Networks.twitch.internalName)
            if (currentPlayerItem?.resource_id && twitchOptions === undefined) {
                setTwitchOptions({
                    width: 500,
                    height: 350,
                    channel: currentPlayerItem.resource_id,
                    // parent: [window.location.host],
                });
            } else {
                twitchPlayer?.setChannel(currentPlayerItem?.resource_id);
            }
    }, [currentPlayerItem]);

    useEffect(() => {
        // @ts-ignore
        if (Twitch && twitchOptions && !twitchPlayer) {
            // @ts-ignore
            const player = new Twitch.Player("123", twitchOptions);
            setTwitchPlayer(player);
        }
    }, [twitchOptions]);

    useEffect(() => {
        if (twitchPlayer) {
            if (isPlaying) {
                twitchPlayer?.play();
            } else {
                twitchPlayer?.pause();
            }
            twitchPlayer.setVolume(volume);
        }
    }, [twitchPlayer]);

    useEffect(() => {
        if (isPlaying) {
            twitchPlayer?.play();
        } else {
            twitchPlayer?.pause();
        }
    }, [isPlaying]);
    useEffect(() => {
        if (twitchPlayer) {
            twitchPlayer.setVolume(volume);
        }
    }, [volume]);

    if (playerSlideIn || !currentPlayerItem) return <></>;
    return (
        <>
            <div id="123"></div>
        </>
    );
};

export default TwitchPlayer;
