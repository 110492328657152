import React, { useEffect } from "react";

const useOnClickOutside = (ref: React.RefObject<HTMLElement>, handler: EventListener): void => {
    useEffect(() => {
        const listener = (event: any) => {
            if (typeof document === "undefined" || !ref.current || ref.current.contains(event.target)) {
                return;
            }

            handler(event);
        };

        if (document) {
            document.addEventListener("mousedown", listener);

            document.addEventListener("touchstart", listener);
        }

        return () => {
            document.removeEventListener("mousedown", listener);

            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
};

export default useOnClickOutside;
