import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, ReactNode } from "react";

export interface Properties {
    title: string | ReactNode;
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
}

const Button: FC<Properties> = ({ title, onClick, className, disabled, loading }: Properties) => (
    <button
        disabled={disabled}
        type="button"
        className={`gradient-button relative w-full font-bold uppercase disabled:cursor-not-allowed disabled:opacity-50 ${
            className ? ` ${className}` : ""
        }`}
        onClick={onClick}
    >
        {loading ? <FontAwesomeIcon icon={faSpinner} className="ml-1 animate-spin" /> : title}
    </button>
);
export default Button;
