var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"GLeq7ddJE_m6LoKMIn9hl"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// sentry.server.config.js
const Sentry = require("@sentry/nextjs");

const SENTRY_ENABLED = [true, "true"].includes(process.env.NEXT_PUBLIC_SENTRY_ENABLED);
const config = {
    dsn: process.env.SENTRY_DSN, // Replace with your Sentry DSN
    tracesSampleRate: 1.0, // Adjust this value as needed
    enabled: SENTRY_ENABLED,
};
Sentry.init(config);
