import React, { FC } from "react";

export interface Props {
    title: string;
    onClick?: () => void;
    className?: string;
}

const ThreeDotMenuItem: FC<Props> = ({ title, onClick, className }: Props) => {
    return (
        <button className={`px-4 py-2 ${className}`} type="button" onClick={onClick}>
            {title}
        </button>
    );
};
export default ThreeDotMenuItem;
