import SubmitButton from "@components/buttons/submit-button";
import InputField from "@components/input-field";
import ServiceSignInOptions from "@components/service-sign-in-options";
import { handleValidateEmail } from "@helper/auth/auth-api-helper";
import useSignUp from "@helper/custom-hooks/use-sign-up";
import { useTranslation } from "next-i18next";
import React, { FC, useState } from "react";
import { toast } from "react-toastify";

export interface Props {
    withSSO?: boolean;
}

const SignUpEmailPassword: FC<Props> = ({ withSSO }: Props) => {
    const [loading, setLoading] = useState(false);
    const { user, setUser, nextStep } = useSignUp();

    const { t } = useTranslation();

    const handleOnClick = (e) => {
        e.preventDefault();
        if (user && user.email && user.password && user.password_confirmation && user.password === user.password_confirmation) {
            setLoading(true);
            handleValidateEmail(user.email)
                .then((data) => {
                    if (data.message === "Email is available") {
                        nextStep();
                    } else {
                        toast.error("This email is not available");
                    }
                })
                .catch(() => {
                    toast.error("This email is not available");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div className="mt-20">
            <form className="relative space-y-10 px-6 md:px-0">
                <InputField
                    name="email"
                    label={`${t("generic.email")}`}
                    type="email"
                    required
                    value={user?.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
                <InputField
                    name="password"
                    label={t("generic.password")}
                    required={!withSSO}
                    type="password"
                    value={user?.password}
                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                />
                <InputField
                    name="password_confirmation"
                    label={t("generic.password_confirm")}
                    required={!withSSO}
                    type="password"
                    value={user?.password_confirmation}
                    onChange={(e) => setUser({ ...user, password_confirmation: e.target.value })}
                />
                {user?.password !== user?.password_confirmation && <p className="text-red-500">{t("error.password_not_identical")}</p>}
                <div>
                    <p className=" text-sm text-rc-pink">* {t("generic.mandatory_field")}</p>
                </div>
                <SubmitButton title={t("generic.continue")} onClick={handleOnClick} loading={loading} disabled={loading} />
            </form>

            <div className="mt-10 border-t border-rc-gray-dark pt-6 text-center text-white">
                <span>OR</span>
            </div>
            <ServiceSignInOptions />
        </div>
    );
};

export default SignUpEmailPassword;
