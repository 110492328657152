import Button from "@components/buttons/button-outline";
import Modal from "@components/modal/modal";
import { refreshUserData } from "@helper/auth/auth-api-helper";
import { isBusinessProfile } from "@helper/auth/companies-helper";
import { getUser } from "@helper/auth/user-helpers";
import { IUseOpenClose } from "@helper/custom-hooks/use-open-close";
import { activeCompany } from "helper/companies/companies-api-helper";
import { IProfileType } from "models/session/current-user";
import { useRouter } from "next/router";
import React, { FC, useState } from "react";
import { toast } from "react-toastify";

type UserCompaniesModalProps = {
    companies: any;
    openClose: IUseOpenClose;
    activeCompany: any;
    handleCloseNavbar: () => void;
};

const UserCompaniesModal: FC<UserCompaniesModalProps> = ({ openClose, companies, activeCompany: selectedCompany, handleCloseNavbar }) => {
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [selectingSlug, setSelectingSlug] = useState("");
    const currentUser = getUser();
    const isBusiness = isBusinessProfile();

    const onSwitchCompany = async (companySlug: string, profileType: IProfileType) => {
        try {
            setSelectingSlug(companySlug);
            setLoading(true);
            const newProfile = await activeCompany(companySlug, profileType);
            await refreshUserData();
            openClose.handleClose();
            handleCloseNavbar();

            const isBusinessProfile = newProfile?.profileType === "business" || newProfile.slug;
            if (isBusinessProfile) {
                await router.push(`/companies/${newProfile.slug}/profile/info`);
            } else {
                await router.push(`/profile/info`);
            }
        } catch (error) {
            toast.error("Failed to switch company");
        } finally {
            setLoading(false);
        }
    };

    const availableCompanies = companies.filter((company) => company.slug !== selectedCompany.slug);
    return (
        <Modal title="Switch profile" backdropStatic handler={openClose}>
            {isBusiness && (
                <div className="lg:ml-4">
                    <p>Personal Profile</p>
                    <div className="shadow-md rounded-lg bg-white py-2 dark:bg-rc-black-light">
                        <Button loading={loading && !selectingSlug} key={currentUser.username} onClick={() => onSwitchCompany(null, "personal")}>
                            {currentUser.name}
                        </Button>
                    </div>
                </div>
            )}

            {availableCompanies && availableCompanies.length ? (
                <div className="lg:ml-4">
                    <p>Business Profile</p>
                    <div className="shadow-md rounded-lg bg-white py-2 dark:bg-rc-black-light">
                        {availableCompanies.map((company) => {
                            return (
                                <Button
                                    loading={loading && selectingSlug === company.slug}
                                    key={company.slug}
                                    className={`mb-2`}
                                    onClick={() => onSwitchCompany(company.slug, "business")}
                                >
                                    {company.name} - {company.slug}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            ) : null}
        </Modal>
    );
};

export default UserCompaniesModal;
