import { INetwork } from "@helper/networks";
import { IPlayerItem } from "@models/player/player-item";
import moment from "moment";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface IHistoryPlayerItem {
    item: IPlayerItem;
    create_date: moment.Moment;
}
export interface IHistoryPage {
    link: string;
    title: string;
    description?: string;
    network?: INetwork;
    create_date: moment.Moment;
}

interface PlaylistState {
    itemToBeAddedToPlaylist: IPlayerItem;
    setItemToBeAddedToPlaylist: (itemToBeAddedToPlaylist: IPlayerItem) => void;
}
const PlaylistStore = create<PlaylistState>(
    devtools(
        (set) => ({
            itemToBeAddedToPlaylist: undefined,
            setItemToBeAddedToPlaylist: (itemToBeAddedToPlaylist: IPlayerItem) => set(() => ({ itemToBeAddedToPlaylist })),
        }),
        { name: "PlaylistStore" },
    ),
);

export default PlaylistStore;
