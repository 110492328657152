import { useRouter } from "next/router";
import { i18n } from "next-i18next";
import { useState } from "react";

interface ISlug {
    locale: string;
    slug: string;
}

export interface IUseLanguageContext {
    currentSlug: string | undefined;
    localizedSlugs: ISlug[] | undefined;
    registerLocalizedSlugs: (value: ISlug[]) => void;
    onLanguageChange: (value: string) => void;
}

const useLanguageContext = (): IUseLanguageContext => {
    const { push, route, pathname, query } = useRouter();
    const [localizedSlugs, setLocalizedSlugs] = useState<{
        pathname: string;
        slugs: ISlug[];
    }>();
    const [currentSlug, setCurrentSlug] = useState<string>();

    const onLanguageChange = (_locale: string) => {
        if (i18n?.changeLanguage) i18n.changeLanguage(_locale);
        const localizedSlug = localizedSlugs?.pathname === pathname ? localizedSlugs?.slugs.find((s) => s.locale === _locale)?.slug : undefined;

        // const path = localizedSlug ? `${_locale}/${localizedSlug}` : `${_locale}${route}`
        const path = localizedSlug ? `${_locale}/${localizedSlug}` : `${route.replace("[slug]", query.slug as string)}`;
        // const path = localizedSlug ? `/${localizedSlug}` : route.slice(0, route.lastIndexOf('/') + 1) + query.slug;

        if (localizedSlug) {
            setCurrentSlug(localizedSlug);
        }

        push(path, undefined, { locale: _locale });
        // push(pathname, `${localizedSlug || path}`, { locale: _locale })
    };

    const registerLocalizedSlugs = (slugs: ISlug[]) => {
        setLocalizedSlugs({ pathname, slugs });
    };

    return {
        currentSlug,
        localizedSlugs: localizedSlugs?.slugs,
        registerLocalizedSlugs,
        onLanguageChange,
    };
};

export default useLanguageContext;
