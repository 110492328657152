import React from "react";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface IAge {
    name: string;
    value: string;
}

export const AgeRecommendations: IAge[] = [
    { name: "0+", value: "0" },
    { name: "6+", value: "6" },
    { name: "12+", value: "12" },
    { name: "16+", value: "16" },
    { name: "18+", value: "18" },
    { name: "No restrictions", value: "-1" },
];

export interface AgeFilterState {
    ageValues: IAge[];
    selectedAge: IAge;
    setSelectedAge: (age: IAge) => void;
}

export const AgeStore = create<AgeFilterState>(
    devtools(
        (set) => ({
            ageValues: AgeRecommendations,
            selectedAge: AgeRecommendations[5],
            setSelectedAge: (age: IAge) => set(() => ({ selectedAge: age })),
        }),
        { name: "AgeStore" },
    ),
);

export const AgeFilter = () => {
    const ageValues = AgeStore((state) => state.ageValues);
    const selectedAge = AgeStore((state) => state.selectedAge);
    const setSelectedAge = AgeStore((state) => state.setSelectedAge);
    return (
        <div className="flex w-1/6 flex-col gap-4 p-2">
            <p className="border-b border-gray-600 pb-2 font-oswald text-lg font-bold uppercase">Age Recommendation</p>
            <div className="flex flex-col gap-2">
                {ageValues &&
                    ageValues.length > 0 &&
                    ageValues.map((ageValue: IAge) => (
                        <div
                            className={`w-1/4 cursor-pointer hover:underline  ${ageValue === selectedAge ? "text-rc-pink" : "hover:text-rc-pink"}`}
                            key={ageValue.name}
                            onClick={() => setSelectedAge(ageValue)}
                        >
                            <span className={`flex items-center whitespace-nowrap`}>{ageValue.name}</span>
                        </div>
                    ))}
            </div>
        </div>
    );
};
