import React from "react";

export const triangleUpIcon = (color = "#fff"): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19">
        <path id="Polygon_1" data-name="Polygon 1" d="M11,0,22,19H0Z" fill={color} />
    </svg>
);

export const triangleDownIcon = (color = "#fff"): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19">
        <path id="Polygon_2" data-name="Polygon 2" d="M11,0,22,19H0Z" transform="translate(22 19) rotate(180)" fill={color} />
    </svg>
);

export const triangleLeftIcon = (color = "#EC6EAD"): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.1" height="17.9" viewBox="0 0 20.1 17.9">
        <path id="Path_140_1_" data-name="Path 140 (1)" d="M20.1,0H0L20.1,17.9Z" fill={color} />
    </svg>
);
export const triangleRightIcon = (color = "#3494E6"): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.1" height="17.9" viewBox="0 0 20.1 17.9">
        <path id="Path_141_1_" data-name="Path 141 (1)" d="M0,17.9,20.1,0H0Z" fill={color} />
    </svg>
);

export const fullscreenIcon = (color = "#fff", width = "33.859", height = "33.859"): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 33.859 33.859">
        <g id="Gruppe_212" data-name="Gruppe 212" transform="translate(-48.436 -54.919)">
            <path id="Pfad_195" data-name="Pfad 195" d="M49.778,56.262H63.938v-.842h-15v15h.842Z" fill={color} stroke="#fff" strokeWidth="1" />
            <path
                id="Pfad_196"
                data-name="Pfad 196"
                d="M317.483,56.262V70.422h.842v-15h-15v.842Z"
                transform="translate(-236.531)"
                fill={color}
                stroke="#fff"
                strokeWidth="1"
            />
            <path
                id="Pfad_197"
                data-name="Pfad 197"
                d="M49.778,323.967V309.806h-.842v15h15v-.842Z"
                transform="translate(0 -236.531)"
                fill={color}
                stroke="#fff"
                strokeWidth="1"
            />
            <path
                id="Pfad_198"
                data-name="Pfad 198"
                d="M317.483,323.967H303.323v.842h15v-15h-.842Z"
                transform="translate(-236.531 -236.531)"
                fill={color}
                stroke="#fff"
                strokeWidth="1"
            />
        </g>
    </svg>
);

export const connectorIcon = (color = "#fff", width = "33.859", height = "33.859"): JSX.Element => (
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={width} height={height} viewBox="0 0 850.39 850.39">
        <g>
            <path
                fill={color}
                d="M605.57,715.43c-12.45,0-22.59-10.13-22.59-22.59v-96.28H486.7c-12.45,0-22.59-10.13-22.59-22.59s10.13-22.59,22.59-22.59
			h96.28V455.1c0-12.45,10.13-22.59,22.59-22.59s22.59,10.13,22.59,22.59v96.28h96.29c12.45,0,22.59,10.13,22.59,22.59
			s-10.13,22.59-22.59,22.59h-96.29v96.28C628.16,705.29,618.02,715.43,605.57,715.43z"
            />
            <path
                fill={color}
                d="M605.57,438.51c9.16,0,16.59,7.43,16.59,16.59v102.28h102.28c9.16,0,16.59,7.43,16.59,16.59
			s-7.43,16.59-16.59,16.59H622.16v102.28c0,9.16-7.43,16.59-16.59,16.59c-9.16,0-16.59-7.43-16.59-16.59V590.56H486.7
			c-9.16,0-16.59-7.43-16.59-16.59s7.43-16.59,16.59-16.59h102.28V455.1C588.98,445.94,596.41,438.51,605.57,438.51 M605.57,426.51
			c-15.76,0-28.59,12.82-28.59,28.59v90.28H486.7c-15.76,0-28.59,12.82-28.59,28.59s12.82,28.59,28.59,28.59h90.28v90.28
			c0,15.76,12.82,28.59,28.59,28.59c15.76,0,28.59-12.82,28.59-28.59v-90.28h90.28c15.76,0,28.59-12.82,28.59-28.59
			s-12.82-28.59-28.59-28.59h-90.28V455.1C634.16,439.34,621.33,426.51,605.57,426.51L605.57,426.51z"
            />
        </g>
        <path
            fill={color}
            d="M562.81,616.73h-6.75c-22.94,16.88-48.55,29.4-76.41,37.31c10.33-11.78,19.88-24.29,28.53-37.31H486.7
		c-3.95,0-7.77-0.55-11.4-1.55c-8.03,10.74-16.74,21.01-26.1,30.68c-5.85-4.87-12.68-8.38-20.03-10.3V533.68
		c11.93,0.5,23.94,1.68,35.8,3.49c6.37-3.78,13.8-5.96,21.73-5.96h62.7c4.75-14.87,8.52-30.1,11.2-45.29
		c0.84-4.78,1.57-9.62,2.22-14.49V455.1c0-5.26,0.96-10.3,2.71-14.96c0.02-0.38,0.04-0.76,0.05-1.14h0.4
		c6.37-15.62,21.72-26.66,39.6-26.66c23.58,0,42.76,19.18,42.76,42.76v14.33c0.18,0.12,0.35,0.24,0.53,0.36
		c-0.17,0.89-0.35,1.79-0.53,2.68v58.75h21.89c7.31-17.5,12.79-35.68,16.33-54.3c24.08-4.65,42.14-25.96,42.14-51.05
		c0-24.95-17.94-46.23-41.87-50.99c-4.92-26.64-13.72-52.34-26.21-76.46c-13.09-25.28-29.96-48.35-50.14-68.56
		c-20.19-20.21-43.22-37.1-68.47-50.2c-24.14-12.52-49.85-21.33-76.5-26.23c-2.28-10.43-7.73-19.94-15.73-27.26
		c-9.61-8.79-22.06-13.64-35.07-13.64c-13.05,0-25.54,4.87-35.16,13.72c-8.01,7.37-13.45,16.94-15.69,27.42
		c-54.53,10.18-104.07,36.25-143.59,75.62c-19.46,19.38-35.83,41.35-48.65,65.3c-1.06,1.97-2.1,3.98-3.11,5.98
		c-11.35,22.51-19.53,46.52-24.35,71.44c-24.44,4.37-42.77,25.74-42.77,51.18c0,24.99,17.97,46.27,41.94,51.01
		c9.84,55.36,36.21,105.74,76.51,146.03c40.08,40.06,90.23,66.43,145.37,76.48c2.23,10.48,7.67,20.05,15.68,27.42
		c9.62,8.86,22.12,13.74,35.18,13.74c13.11,0,25.65-4.91,35.29-13.84c8.03-7.44,13.45-17.08,15.63-27.63
		c33.89-6.45,66.59-19.45,95.86-37.98V616.73z M153.36,441.2c-9.93,0-18-8.07-18-18c0-9.93,8.07-18,18-18c9.93,0,18,8.07,18,18
		C171.36,433.12,163.29,441.2,153.36,441.2z M533.44,479.38c-3.25,18.33-7.43,35.71-10.28,42.97c-23.05-7.29-46.95-12.26-71.08-14.78
		c-7.58-0.79-15.29-1.35-22.93-1.65v-66.9h108.75C538.26,443.9,537.39,457.14,533.44,479.38z M429.2,411.05v-66.9
		c31.62-1.27,62.94-6.71,93.15-16.18c8.78,26.85,13.94,54.77,15.35,83.07H429.2z M676.7,407.86c9.93,0,18,8.07,18,18
		c0,9.93-8.07,18-18,18c-9.93,0-18-8.07-18-18C658.7,415.94,666.77,407.86,676.7,407.86z M649.18,381.76
		c-10.8,6.74-18.73,17.16-22.33,29.31h-61.38c-1.43-31.44-7.15-62.52-17-92.44c21.4-8.64,41.29-18.97,60.69-31.53
		C629.46,315.93,642.9,347.75,649.18,381.76z M591.68,265.35c-16.94,10.78-34.3,19.73-52.97,27.3c-2.79-6.56-5.8-13.08-8.96-19.43
		c-2.3-4.62-4.74-9.25-7.25-13.75c-12.74-22.93-28.14-44.49-45.83-64.2C521.22,207.24,559.87,230.79,591.68,265.35z M512.58,302.09
		c-4.01,1.67-14.83,4.89-36.26,8.72c-18.91,3.38-37.56,5.65-46.4,5.65c-0.25,0-0.49,0-0.72-0.01V214.46
		c6.61-1.93,12.8-5.17,18.17-9.51C474.99,233.06,496.91,265.69,512.58,302.09z M414.7,146.53c9.93,0,18,8.07,18,18
		c0,9.93-8.07,18-18,18c-9.93,0-18-8.07-18-18C396.7,154.6,404.77,146.53,414.7,146.53z M382.61,205.41
		c5.53,4.34,11.88,7.52,18.66,9.34v101.63c-28.16-1.21-56.19-6.05-83.41-14.42c0.43-0.95,0.86-1.89,1.28-2.83
		c1.53-3.38,3.11-6.87,4.75-10.24C338.87,258.01,358.6,229.95,382.61,205.41z M292.75,411.05c1.39-28.23,6.55-56.17,15.35-83.08
		c15.08,4.75,30.57,8.51,46.06,11.2c15.5,2.69,31.33,4.36,47.09,4.98v66.9H292.75z M401.24,439.02v66.89
		c-31.95,1.3-63.53,6.83-93.91,16.43c-8.6-27.06-13.53-55.08-14.69-83.33H401.24z M353.8,195.26c-26.05,28.98-46.9,61.7-62.06,97.38
		c-11.94-4.84-23.17-10.16-34.28-16.25c-4.62-2.53-9.28-5.23-13.83-8.02c-1.61-0.99-3.24-2-4.85-3.03
		C270.54,230.81,309.2,207.26,353.8,195.26z M191.4,344.12c1.86-5.15,3.94-10.31,6.16-15.34c5.02-11.32,11-22.37,17.78-32.86
		c1.92-2.96,3.92-5.92,5.98-8.82c19.13,12.38,39.52,22.97,60.66,31.51c-1.09,3.33-2.14,6.69-3.13,10.03
		c-3.5,11.85-6.38,23.86-8.55,35.7c-2.17,11.8-3.74,24.01-4.67,36.31c-0.26,3.45-0.48,6.95-0.64,10.43h-61.06
		c-3.12-12.87-11.14-24.24-22.29-31.51C183.96,367.53,187.24,355.61,191.4,344.12z M181.08,467.18
		c10.34-6.54,18.09-16.54,21.81-28.15h61.99c0.38,9.66,1.15,19.36,2.3,28.84c0.74,6.07,1.64,12.15,2.68,18.06
		c2.72,15.4,6.54,30.81,11.37,45.83c-2.17,0.88-4.35,1.79-6.5,2.72c-18.52,7.96-36.49,17.54-53.42,28.5
		C200.77,533.82,187.26,501.62,181.08,467.18z M259.85,605.15c-2.25-1.94-4.5-3.95-6.68-5.98c-4.92-4.56-9.75-9.41-14.38-14.45
		c1.57-1,3.14-1.98,4.7-2.94c15.13-9.29,30.63-17.14,47.31-23.97c14.64,35.14,34.79,67.46,59.95,96.18
		C316.87,644.39,286.32,627.98,259.85,605.15z M316.92,548.41c27.29-8.52,55.64-13.47,84.32-14.72v102.32
		c-7.12,2.11-13.69,5.73-19.3,10.62C354.27,618.1,332.42,585.09,316.92,548.41z M416.03,703.86c-9.93,0-18-8.07-18-18
		c0-9.93,8.07-18,18-18c9.93,0,18,8.07,18,18C434.03,695.79,425.96,703.86,416.03,703.86z"
        />
    </svg>
);

export const userIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg id="Solid" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path
            fill={color}
            d="m431.964 435.333c-.921-58.994-19.3-112.636-51.977-151.474-32.487-38.601-76.515-59.859-123.987-59.859s-91.5 21.258-123.987 59.859c-32.646 38.797-51.013 92.364-51.973 151.285 18.46 9.247 94.85 44.856 175.96 44.856 87.708 0 158.845-35.4 175.964-44.667z"
        />
        <circle fill={color} cx="256" cy="120" r="88" />
    </svg>
);

export const friendrequestIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg id="Solid" viewBox="0 0 512 512" className={className}>
        <path
            fill={color}
            d="m272 384a96 96 0 1 0 96-96 96.108 96.108 0 0 0 -96 96zm40-8h48v-48a8 8 0 0 1 16 0v48h48a8 8 0 0 1 0 16h-48v48a8 8 0 0 1 -16 0v-48h-48a8 8 0 0 1 0-16z"
        />
        <path
            fill={color}
            d="m272.6 442.613a111.947 111.947 0 0 1 71.217-167.976c-31.117-42.497-77.107-66.637-127.817-66.637-45.522 0-87.578 19.485-118.42 54.865-31.063 35.633-48.567 85.3-49.538 140.291 18.365 9.261 93.77 44.844 167.958 44.844a312.1 312.1 0 0 0 56.6-5.387z"
        />
        <circle fill={color} cx="216" cy="112" r="80" />
    </svg>
);

export const groupIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Solid" viewBox="0 0 512 512" className={className}>
        <path
            fill={color}
            d="M344,208c-36.3,0-69.4,14.759-94.139,41.742A149.313,149.313,0,0,1,275.813,273c28.218,31.874,43.871,76.417,44.173,125.553A210.236,210.236,0,0,0,344,400c64.807,0,121.011-28.379,135.952-36.614-.927-43.955-15.09-83.5-40.119-111.777C414.938,223.487,380.9,208,344,208Z"
        />
        <circle fill={color} cx="344" cy="128" r="64" />
        <path
            fill={color}
            d="M168,240c-36.9,0-70.938,15.487-95.833,43.609-24.992,28.23-39.15,67.706-40.114,111.581C48.2,403.413,108.617,432,168,432c64.664,0,120.985-28.389,135.951-36.62-.927-43.952-15.09-83.5-40.118-111.771C238.938,255.487,204.9,240,168,240Z"
        />
        <circle fill={color} cx="168" cy="160" r="64" />
    </svg>
);

export const heartIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg enableBackground="new 0 0 32 32" viewBox="0 0 32 32" className={className} xmlns="http://www.w3.org/2000/svg">
        <g id="_x33_6_love_favourite">
            <path
                fill={color}
                d="m28.545 5.207c-1.593-1.585-3.719-2.457-5.985-2.457-2.257 0-4.368.867-5.945 2.442l-.615.616-.615-.615c-1.582-1.58-3.698-2.45-5.961-2.45-2.261 0-4.381.869-5.971 2.45-3.286 3.285-3.288 8.646 0 11.95l11.84 11.824c.195.194.451.292.707.292s.511-.098.707-.292l11.842-11.827c3.281-3.297 3.28-8.65-.004-11.933zm-2.123 9.816c-.196.196-.452.295-.709.295-.255 0-.51-.097-.706-.291-.391-.39-.393-1.022-.003-1.414 1.318-1.325 1.322-3.555.008-4.869-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0c2.079 2.079 2.077 5.603-.004 7.693z"
            />
        </g>
    </svg>
);

export const plusIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" className={className}>
        <g>
            <path
                fill={color}
                d="M257,0C116.39,0,0,114.39,0,255s116.39,257,257,257s255-116.39,255-257S397.61,0,257,0z M392,285H287v107
			c0,16.54-13.47,30-30,30c-16.54,0-30-13.46-30-30V285H120c-16.54,0-30-13.46-30-30c0-16.54,13.46-30,30-30h107V120
			c0-16.54,13.46-30,30-30c16.53,0,30,13.46,30,30v105h105c16.53,0,30,13.46,30,30S408.53,285,392,285z"
            />
        </g>
    </svg>
);

export const bellIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={className} viewBox="0 0 397.52 438.57">
        <g>
            <path
                fill={color}
                d="M193.89,12.74c3.73,0,7.45,0,11.18,0c0.73,0.26,1.44,0.59,2.18,0.77c17.73,4.38,28.96,15.36,32.26,33.35
			c0.91,4.94,3.22,6.48,7.15,8.27c45.58,20.82,71.65,56.15,74.75,106.22c1.8,29.12,8.16,56.4,21.34,82.22
			c10.43,20.44,23.33,39.26,37.09,57.59c15.83,21.07,5.78,48.25-19.66,54.9c-23.86,6.24-48.12,9.88-72.63,12.32
			c-30.62,3.04-61.33,4.52-92.05,3.85c-28.97-0.63-57.94-2.26-86.85-4.28c-24.42-1.71-48.53-5.93-72.15-12.59
			c-9.15-2.58-16.29-7.93-20.64-16.39c-7.12-13.85-5.33-27,4.14-39.16c13.18-16.93,25.1-34.69,34.92-53.78
			c12.46-24.23,21.15-49.48,22.01-77.13c0.33-10.55,1.4-21.37,4.17-31.51c10.94-40.04,36.01-67.98,74.53-83.64
			c2.36-0.96,2.99-2.15,3.3-4.57c1.38-10.67,6.11-19.6,14.32-26.74C179.26,17.22,186.54,14.96,193.89,12.74z"
            />
            <path
                fill={color}
                d="M148.5,388.2c6.47,18.68,29.84,33.73,51.64,33.32c24.67-0.46,44.18-16.87,50.26-33.32
			C216.53,388.2,182.61,388.2,148.5,388.2z"
            />
            <path
                fill={color}
                d="M148.5,388.2c34.1,0,68.03,0,101.89,0c-6.07,16.45-25.59,32.85-50.26,33.32C178.34,421.93,154.97,406.88,148.5,388.2z"
            />
        </g>
    </svg>
);

export const searchIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg version="1.1" id="Layer_1" className={className} x="0px" y="0px" viewBox="0 0 276.21 267.31">
        <g>
            <path
                fill={color}
                d="M127.47,9.62c4.04,0.63,8.09,1.16,12.11,1.91c17.39,3.21,32.95,10.49,46.58,21.71c17.92,14.75,29.97,33.38,35.6,55.93
				c6.14,24.57,3.09,48.27-7.4,71.15c-1.79,3.9-1.82,3.89,1.25,6.96c12.33,12.33,24.69,24.61,36.97,36.99
				c12.46,12.57,12.02,33.89-0.86,45.57c-11.62,10.55-29.08,10.53-40.59-0.54c-12.5-12.03-24.61-24.46-36.9-36.71
				c-1.65-1.64-3.17-4.14-5.11-4.56c-1.79-0.38-4.05,1.53-6.11,2.41c-15.7,6.73-32.04,10.32-49.17,9.3
				c-22.99-1.36-43.52-9.3-61.22-24.07c-17.41-14.53-29.45-32.72-34.68-54.84c-8.59-36.33-0.77-68.89,24.21-96.88
				c16.5-18.49,37.36-29.24,61.76-33.23c2.68-0.44,5.39-0.75,8.08-1.11C117.14,9.62,122.31,9.62,127.47,9.62z M199.95,117.75
				c1.93-40.94-29.7-81.67-78.13-83.28c-40.23-1.34-80.31,29.63-82.33,77.41c-1.73,40.78,29.48,80.8,76.82,83.1
				C157.48,196.97,198,165.5,199.95,117.75z"
            />
        </g>
    </svg>
);

export const medialibraryIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg id="Layer_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" className={className}>
        <path
            fill={color}
            d="m399.452 257.106c0 20.36-8.928 39.578-23.767 52.927v-62.021c0-6.627-5.373-12-12-12h-73.49c-13.54 0-25.74 5.857-34.198 15.172-8.458-9.315-20.658-15.172-34.198-15.172h-73.49c-6.627 0-12 5.373-12 12v62.022c-14.839-13.349-23.767-32.567-23.767-52.928 0-28.807 17.288-54.617 44.043-65.755 4.037-1.681 6.84-5.425 7.317-9.771 4.312-39.355 37.466-69.032 77.121-69.032 37.503 0 69.602 26.729 76.325 63.556 1.033 5.656 5.932 9.785 11.681 9.845 38.831.403 70.423 32.324 70.423 71.157zm-109.256 2.906h61.49v139.445h-83.688v-117.259c.005-12.233 9.964-22.186 22.198-22.186zm45.778 95.018c0-6.628-5.373-12-12-12h-28.265c-6.627 0-12 5.372-12 12s5.373 12 12 12h28.265c6.628 0 12-5.372 12-12zm-40.265-62.592c-6.627 0-12 5.372-12 12s5.373 12 12 12h28.265c6.627 0 12-5.372 12-12s-5.373-12-12-12zm-51.711-10.24v117.259h-83.688v-139.445h61.49c12.233 0 22.192 9.953 22.198 22.186zm-15.712 72.832c0-6.628-5.373-12-12-12h-28.264c-6.627 0-12 5.372-12 12s5.373 12 12 12h28.264c6.627 0 12-5.372 12-12zm0-50.592c0-6.628-5.373-12-12-12h-28.264c-6.627 0-12 5.372-12 12s5.373 12 12 12h28.264c6.627 0 12-5.372 12-12zm283.714-226.045v355.215c0 43.225-35.167 78.392-78.393 78.392h-355.214c-43.226 0-78.393-35.167-78.393-78.393v-355.214c0-43.226 35.167-78.393 78.393-78.393h355.215c43.225 0 78.392 35.167 78.392 78.393zm-88.548 178.713c0-48.709-37.17-89.288-84.634-94.578-12.179-43.389-51.897-73.98-97.794-73.98-49.587 0-91.413 35.458-99.991 83.551-32.104 16.15-52.49 48.803-52.49 85.007 0 33.948 18.521 65.504 47.767 82.43v71.92c0 6.628 5.373 12 12 12h215.376c6.627 0 12-5.372 12-12v-71.921c29.245-16.925 47.766-48.482 47.766-82.429z"
        />
    </svg>
);

export const settingsIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg enableBackground="new 0 0 24 24" viewBox="0 0 24 24" className={className} xmlns="http://www.w3.org/2000/svg">
        <path
            fill={color}
            d="m22.683 9.394-1.88-.239c-.155-.477-.346-.937-.569-1.374l1.161-1.495c.47-.605.415-1.459-.122-1.979l-1.575-1.575c-.525-.542-1.379-.596-1.985-.127l-1.493 1.161c-.437-.223-.897-.414-1.375-.569l-.239-1.877c-.09-.753-.729-1.32-1.486-1.32h-2.24c-.757 0-1.396.567-1.486 1.317l-.239 1.88c-.478.155-.938.345-1.375.569l-1.494-1.161c-.604-.469-1.458-.415-1.979.122l-1.575 1.574c-.542.526-.597 1.38-.127 1.986l1.161 1.494c-.224.437-.414.897-.569 1.374l-1.877.239c-.753.09-1.32.729-1.32 1.486v2.24c0 .757.567 1.396 1.317 1.486l1.88.239c.155.477.346.937.569 1.374l-1.161 1.495c-.47.605-.415 1.459.122 1.979l1.575 1.575c.526.541 1.379.595 1.985.126l1.494-1.161c.437.224.897.415 1.374.569l.239 1.876c.09.755.729 1.322 1.486 1.322h2.24c.757 0 1.396-.567 1.486-1.317l.239-1.88c.477-.155.937-.346 1.374-.569l1.495 1.161c.605.47 1.459.415 1.979-.122l1.575-1.575c.542-.526.597-1.379.127-1.985l-1.161-1.494c.224-.437.415-.897.569-1.374l1.876-.239c.753-.09 1.32-.729 1.32-1.486v-2.24c.001-.757-.566-1.396-1.316-1.486zm-10.683 7.606c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"
        />
    </svg>
);

export const pieChartIcon = (color = "#fbd44c", className = "") => (
    <svg viewBox="0 0 258.88 236.7" className={className}>
        <path
            fill={color}
            d="M258.88,138.02c-0.83,4.95-1.5,9.94-2.52,14.85c-3.41,16.48-10.41,31.35-20.54,44.76c-1.47,1.95-3,3.85-4.58,5.71
		c-3.16,3.74-7.4,3.95-11.01,0.6c-0.31-0.29-0.6-0.59-0.9-0.89c-20.35-20.35-40.72-40.68-61.03-61.08c-1.31-1.31-2.53-3.04-3.01-4.79
		c-1.09-4,1.8-7.83,5.92-8.33c1-0.12,2.02-0.12,3.02-0.12c28.44-0.01,56.89,0.05,85.33-0.06c4.34-0.02,7.5,1.26,9.3,5.31
		C258.88,135.33,258.88,136.67,258.88,138.02z"
        />
        <path
            fill={color}
            d="M114.34,68.29c0,16.67,0.05,33.34-0.04,50.01c-0.02,3.09,1,5.44,3.18,7.61c23.73,23.66,47.4,47.37,71.11,71.05
		c1.84,1.84,3.66,3.81,3.09,6.54c-0.39,1.85-1.24,3.99-2.61,5.18c-15.61,13.64-33.66,22.57-54.04,26.17
		c-58.8,10.38-114.26-24.14-130.5-81.34c-7.56-26.63-5.73-52.89,6.09-78.02c16.28-34.6,43.36-56.06,80.66-64.47
		c4.98-1.12,10.13-1.56,15.23-2.11c4.45-0.47,7.73,2.78,7.8,7.59c0.08,5.47,0.02,10.94,0.02,16.42
		C114.33,44.72,114.33,56.51,114.34,68.29C114.34,68.29,114.34,68.29,114.34,68.29z"
        />
        <path
            fill={color}
            d="M127.99,56.86c0-16.16-0.01-32.32,0-48.49c0-6.13,2.88-8.88,8.92-8.3c39.64,3.85,69.67,23.07,90.05,57.12
		c8.81,14.71,13.37,30.95,14.7,48.05c0.41,5.31-2.5,8.47-7.88,8.48c-32.58,0.02-65.15,0.02-97.73,0c-5.32,0-8.06-2.78-8.06-8.13
		C127.98,89.35,127.99,73.11,127.99,56.86z"
        />
    </svg>
);

export const logoutIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={className} viewBox="0 0 512 512">
        <g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M510.371,226.513c-1.088-2.603-2.645-4.971-4.629-6.955l-63.979-63.979c-8.341-8.32-21.824-8.32-30.165,0
					c-8.341,8.341-8.341,21.845,0,30.165l27.584,27.584H320.013c-11.797,0-21.333,9.557-21.333,21.333s9.536,21.333,21.333,21.333
					h119.168l-27.584,27.584c-8.341,8.341-8.341,21.845,0,30.165c4.16,4.181,9.621,6.251,15.083,6.251s10.923-2.069,15.083-6.251
					l63.979-63.979c1.984-1.963,3.541-4.331,4.629-6.955C512.525,237.606,512.525,231.718,510.371,226.513z"
                    />
                    <path
                        fill={color}
                        d="M362.68,298.667c-11.797,0-21.333,9.557-21.333,21.333v106.667h-85.333V85.333c0-9.408-6.187-17.728-15.211-20.437
					l-74.091-22.229h174.635v106.667c0,11.776,9.536,21.333,21.333,21.333s21.333-9.557,21.333-21.333v-128
					C384.013,9.557,374.477,0,362.68,0H21.347c-0.768,0-1.451,0.32-2.197,0.405c-1.003,0.107-1.92,0.277-2.88,0.512
					c-2.24,0.576-4.267,1.451-6.165,2.645c-0.469,0.299-1.045,0.32-1.493,0.661C8.44,4.352,8.376,4.587,8.205,4.715
					C5.88,6.549,3.939,8.789,2.531,11.456c-0.299,0.576-0.363,1.195-0.597,1.792c-0.683,1.621-1.429,3.2-1.685,4.992
					c-0.107,0.64,0.085,1.237,0.064,1.856c-0.021,0.427-0.299,0.811-0.299,1.237V448c0,10.176,7.189,18.923,17.152,20.907
					l213.333,42.667c1.387,0.299,2.795,0.427,4.181,0.427c4.885,0,9.685-1.685,13.525-4.843c4.928-4.053,7.808-10.091,7.808-16.491
					v-21.333H362.68c11.797,0,21.333-9.557,21.333-21.333V320C384.013,308.224,374.477,298.667,362.68,298.667z"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export const companyIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        className={className}
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512;"
    >
        <g>
            <g>
                <path
                    fill={color}
                    d="M448.005,44.508V12.455H213.344v32.216h-65.065v121.634c50.429,19.031,86.396,67.789,86.396,124.797
					c0,30.116-19.882,79.492-60.781,150.948c-5.711,9.976-11.435,19.669-16.957,28.818h158.812v-98.131h31.997v98.131H512V44.725
					L448.005,44.508z M312.546,339.437h-60.797V307.44h60.797V339.437z M312.546,275.439h-60.797v-31.998h60.797V275.439z
					M312.546,212.912h-60.797v-31.997h60.797V212.912z M312.546,148.912h-60.797v-31.997h60.797V148.912z M411.746,339.437h-60.797
					V307.44h60.797V339.437z M411.746,275.439h-60.797v-31.998h60.797V275.439z M411.746,212.912h-60.797v-31.997h60.797V212.912z
					M411.746,148.912h-60.797v-31.997h60.797V148.912z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    fill={color}
                    d="M101.339,259.101c-17.646,0-32.001,14.355-32.001,32.001s14.355,32.001,32.001,32.001s32.001-14.355,32.001-32.001
					S118.985,259.101,101.339,259.101z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    fill={color}
                    d="M101.338,189.764C45.46,189.764,0,235.225,0,291.102c0,43.309,64.283,151.352,101.338,208.444
					c37.054-57.102,101.338-165.161,101.338-208.444C202.677,235.223,157.217,189.764,101.338,189.764z M101.339,355.1
					c-35.289,0-63.998-28.709-63.998-63.998s28.709-63.998,63.998-63.998s63.998,28.709,63.998,63.998S136.628,355.1,101.339,355.1z"
                />
            </g>
        </g>
    </svg>
);

export const filterIcon = (color = "#fff", className = ""): JSX.Element => (
    <svg id="Capa_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" className={className}>
        <g>
            <g>
                <path
                    fill={color}
                    d="m20 116h136c0 33.084 26.916 60 60 60s60-26.916 60-60h216c11.046 0 20-8.954 20-20s-8.954-20-20-20h-216c0-33.084-26.916-60-60-60s-60 26.916-60 60h-136c-11.046 0-20 8.954-20 20s8.954 20 20 20zm176-40c0-11.028 8.972-20 20-20s20 8.972 20 20v40c0 11.028-8.972 20-20 20s-20-8.972-20-20zm296 320h-296c0-33.084-26.916-60-60-60s-60 26.916-60 60h-56c-11.046 0-20 8.954-20 20s8.954 20 20 20h56c0 33.084 26.916 60 60 60s60-26.916 60-60h296c11.046 0 20-8.954 20-20s-8.954-20-20-20zm-336 19.99v.01s0 .006 0 .01v19.99c0 11.028-8.972 20-20 20s-20-8.972-20-20v-40c0-11.028 8.972-20 20-20s20 8.972 20 20zm336-179.99h-56c0-33.084-26.916-60-60-60s-60 26.916-60 60h-296c-11.046 0-20 8.954-20 20s8.954 20 20 20h296c0 33.084 26.916 60 60 60s60-26.916 60-60h56c11.046 0 20-8.954 20-20s-8.954-20-20-20zm-96 40c0 11.028-8.972 20-20 20s-20-8.972-20-20v-40c0-11.028 8.972-20 20-20s20 8.972 20 20z"
                />
            </g>
        </g>
    </svg>
);

// GRADIENT ICONS

export const gradientHeart = (className = "", fill = ""): JSX.Element => (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={className} viewBox="0 0 512 512">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="258" x2="512" y2="258" gradientTransform="matrix(1 0 0 -1 0 514)">
            <stop offset="0" stopColor="#EC6EAD" />
            <stop offset="1" stopColor="#3494E6" />
        </linearGradient>
        <path
            fill={`${fill || "url(#SVGID_1_)"}`}
            d="M256,472.779c-3.589,0-7.178-0.965-10.363-2.894C75.757,366.972,0,279.858,0,187.425
			C0,106.588,57.544,39.221,138,39.221c42.59,0,79.926,18.991,107.971,54.921c3.74,4.792,7.074,9.585,10.029,14.229
			c2.955-4.643,6.289-9.437,10.029-14.229C294.074,58.212,331.41,39.221,374,39.221c80.503,0,138,67.41,138,148.205
			c0,92.433-75.757,179.547-245.637,282.46C263.178,471.815,259.589,472.779,256,472.779z M138,79.221c-58.666,0-98,50.001-98,108.205
			c0,37.771,17.285,75.419,52.843,115.095C128.21,341.982,181.655,383.534,256,429.343c74.345-45.809,127.79-87.361,163.157-126.823
			C454.715,262.844,472,225.196,472,187.425c0-58.156-39.272-108.205-98-108.205c-30.246,0-55.964,13.301-76.439,39.533
			c-15.749,20.176-22.404,40.673-22.467,40.875c-2.605,8.356-10.341,14.046-19.093,14.046s-16.488-5.691-19.093-14.046
			c-0.048-0.151-6.909-21.308-23.193-41.797C193.35,92.211,167.876,79.221,138,79.221z"
        />
    </svg>
);

export const gradientFilter = (className = ""): JSX.Element => (
    <svg id="Capa_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" className={className}>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" x2="512" y1="256" y2="256">
            <stop offset="0" stopColor="#EC6EAD" />
            <stop offset="1" stopColor="#3494E6" />
        </linearGradient>
        <g>
            <g>
                <path
                    d="m20 116h136c0 33.084 26.916 60 60 60s60-26.916 60-60h216c11.046 0 20-8.954 20-20s-8.954-20-20-20h-216c0-33.084-26.916-60-60-60s-60 26.916-60 60h-136c-11.046 0-20 8.954-20 20s8.954 20 20 20zm176-40c0-11.028 8.972-20 20-20s20 8.972 20 20v40c0 11.028-8.972 20-20 20s-20-8.972-20-20zm296 320h-296c0-33.084-26.916-60-60-60s-60 26.916-60 60h-56c-11.046 0-20 8.954-20 20s8.954 20 20 20h56c0 33.084 26.916 60 60 60s60-26.916 60-60h296c11.046 0 20-8.954 20-20s-8.954-20-20-20zm-336 19.99v.01s0 .006 0 .01v19.99c0 11.028-8.972 20-20 20s-20-8.972-20-20v-40c0-11.028 8.972-20 20-20s20 8.972 20 20zm336-179.99h-56c0-33.084-26.916-60-60-60s-60 26.916-60 60h-296c-11.046 0-20 8.954-20 20s8.954 20 20 20h296c0 33.084 26.916 60 60 60s60-26.916 60-60h56c11.046 0 20-8.954 20-20s-8.954-20-20-20zm-96 40c0 11.028-8.972 20-20 20s-20-8.972-20-20v-40c0-11.028 8.972-20 20-20s20 8.972 20 20z"
                    fill="url(#SVGID_1_)"
                />
            </g>
        </g>
    </svg>
);

export const gradientFire = (className = "", fill = ""): JSX.Element => (
    <svg id="Capa_1" enableBackground="new 0 0 512.049 512.049" viewBox="0 0 512.049 512.049" className={className}>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="40.488" x2="471.561" y1="256.024" y2="256.024">
            <stop offset="0" stopColor="#EC6EAD" />
            <stop offset="1" stopColor="#3494E6" />
        </linearGradient>
        <g>
            <path
                d="m418.109 154.451c-9.076-10.338-25.617-8.612-32.401 3.286-10.817 18.964-29.055 40.752-58.163 49.571 17.261-76.517-8.321-152.406-57.358-201.45-7.809-7.811-20.474-7.811-28.284 0l-138.247 138.246c-84.22 84.222-84.229 220.544 0 304.775 84.222 84.22 220.544 84.232 304.777 0 80.571-80.572 84.257-209.484 9.676-294.428zm-198.823 302.323c-20.314-20.314-20.319-53.198.001-73.516l36.758-36.759 36.758 36.759h.001c20.314 20.314 20.319 53.197-.001 73.517-20.279 20.279-53.22 20.295-73.517-.001zm124.182-8.089c10.431-31.918 3.033-68.298-22.38-93.712l-50.9-50.9c-7.811-7.809-20.475-7.809-28.285 0l-50.899 50.9c-25.382 25.382-32.825 61.752-22.381 93.711-100.4-57.637-117.958-195.02-36.681-276.295l123.176-123.177c36.128 46.739 50.047 110.434 25.661 175.049-4.932 13.07 4.743 27.062 18.712 27.062 41.455 0 77.655-17.396 104.32-49.582 53.744 83.572 27.223 196.673-60.343 246.944z"
                fill={`${fill || "url(#SVGID_1_)"}`}
            />
        </g>
    </svg>
);

export const gradientClock = (className = "", fill = ""): JSX.Element => (
    <svg id="Capa_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" className={className}>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" x2="512" y1="256" y2="256">
            <stop offset="0" stopColor="#EC6EAD" />
            <stop offset="1" stopColor="#3494E6" />
        </linearGradient>
        <path
            d="m256 0c-141.484 0-256 114.497-256 256 0 141.484 114.497 256 256 256 141.483 0 256-114.497 256-256 0-141.484-114.497-256-256-256zm0 472c-119.377 0-216-96.607-216-216 0-119.377 96.607-216 216-216 119.377 0 216 96.607 216 216 0 119.377-96.607 216-216 216zm0-393.333c-97.782 0-177.333 79.551-177.333 177.333s79.551 177.333 177.333 177.333c97.781 0 177.333-79.551 177.333-177.333s-79.552-177.333-177.333-177.333zm0 314.666c-75.726 0-137.333-61.607-137.333-137.333s61.607-137.333 137.333-137.333 137.333 61.607 137.333 137.333-61.607 137.333-137.333 137.333zm68.667-137.333c0 11.046-8.954 20-20 20h-48.667c-11.046 0-20-8.954-20-20v-78.667c0-11.046 8.954-20 20-20s20 8.954 20 20v58.667h28.667c11.046 0 20 8.954 20 20z"
            fill={`${fill || "url(#SVGID_1_)"}`}
        />
    </svg>
);

export const gradientBulletCheck = (className = ""): JSX.Element => (
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" className={className} x="0px" y="0px" viewBox="0 0 512 512">
        <path fill="none" d="M-0.2,0.3" />
        <g>
            <g>
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" x2="512" y1="256" y2="256">
                    <stop offset="0" stopColor="#EC6EAD" />
                    <stop offset="1" stopColor="#3494E6" />
                </linearGradient>
                <path
                    fill="url(#SVGID_1_)"
                    d="M256,505.6C118.4,505.6,6.4,393.6,6.4,256C6.4,118.4,118.4,6.4,256,6.4c137.6,0,249.6,112,249.6,249.6
				C505.6,393.6,393.6,505.6,256,505.6z M256,58.3C147,58.3,58.3,147,58.3,256c0,109,88.7,197.7,197.7,197.7
				c109,0,197.7-88.7,197.7-197.7C453.7,147,365,58.3,256,58.3z"
                />
            </g>

            <path
                fill="url(#SVGID_1_)"
                d="M360.7,163.1
			c-44.6,47.8-89.3,95.6-133.9,143.4c-0.7,0.7-1.3,1.4-2,2.1c-23.3-22.8-46.6-45.5-69.9-68.3c-23.9-23.3-60.6,13.3-36.7,36.7
			c29.7,29,59.4,58,89.1,87c9.8,9.5,27,10.4,36.7,0c44.6-47.8,89.3-95.6,133.9-143.4c6.5-7,13-13.9,19.5-20.9
			C420.1,175.4,383.5,138.6,360.7,163.1z"
            />
        </g>
    </svg>
);

// Emojis

export const loveEmoji = (className = "", color = "#ec6ead", grey = true): JSX.Element => (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" className={className}>
        <path
            fill={grey ? "#515151" : color}
            d="M367.775,19.058c-3.286,0-6.552,0.113-9.787,0.33L256.103,492.88
		c5.862-3.41,255.866-150.2,255.897-329.576C512,83.651,447.428,19.058,367.775,19.058z"
        />
        <path
            fill={grey ? "#515151" : color}
            d="M357.988,19.388c63.006,5.027,112.805,67.549,112.805,143.916
		c-0.031,179.19-209.364,325.867-214.69,329.576c-0.072,0.041-0.103,0.062-0.103,0.062S0,344.802,0,163.284
		C0,83.63,64.572,19.058,144.225,19.058c45.081,0,85.33,20.686,111.775,53.085C280.518,42.103,316.894,22.139,357.988,19.388z"
        />
    </svg>
);

export const happyEmoji = (className = "", grey = true): JSX.Element => (
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" className={className}>
        <circle fill={`${grey ? "#D8D8D8" : "#FFE17D"}`} cx="256" cy="256" r="256" />
        {!grey && (
            <path
                fill="#FFD164"
                d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.376-161.463
		C36.863,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.626-94.538
		C410.573,453.317,354.389,474.839,293.161,474.839z"
            />
        )}
        <path
            fill={`${grey ? "#515151" : "#9C6846"}`}
            d="M256,305.305c-49.323,0-96.405-5.727-139.598-16.079c-5.595-1.341-10.417,4.504-8.691,10.596
		c19.92,70.324,78.718,121.34,148.29,121.34s128.369-51.016,148.29-121.34c1.726-6.092-3.096-11.937-8.691-10.596
		C352.405,299.578,305.323,305.305,256,305.305z"
        />
        <g>
            <path
                fill={`${grey ? "#515151" : "#7D5046"}`}
                d="M395.599,289.226c-43.194,10.352-90.275,16.079-139.599,16.079s-96.405-5.726-139.598-16.079
			c-5.595-1.341-10.417,4.504-8.691,10.596c5.214,18.408,13.148,35.443,23.186,50.637c41.04,7.796,82.792,12.085,125.121,12.085
			c42.315,0,84.051-4.286,125.079-12.076c10.041-15.196,17.978-32.233,23.193-50.645
			C406.015,293.729,401.194,287.885,395.599,289.226z"
            />
            <path
                fill={`${grey ? "#515151" : "#7D5046"}`}
                d="M169.29,231.642L169.29,231.642c-11.402,0-20.645-9.243-20.645-20.645v-33.449
			c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645v33.449
			C189.935,222.399,180.692,231.642,169.29,231.642z"
            />
        </g>
        <path
            fill={`${grey ? "#515151" : "#9C6846"}`}
            d="M169.29,156.903c-1.414,0-2.794,0.145-4.129,0.416v45.419c0,6.841,5.546,12.387,12.387,12.387
		s12.387-5.546,12.387-12.387v-25.191C189.935,166.146,180.693,156.903,169.29,156.903z"
        />
        <path
            fill={`${grey ? "#515151" : "#7D5046"}`}
            d="M342.71,231.642L342.71,231.642c-11.402,0-20.645-9.243-20.645-20.645v-33.449
		c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645v33.449
		C363.355,222.399,354.112,231.642,342.71,231.642z"
        />
        <path
            fill={`${grey ? "#515151" : "#9C6846"}`}
            d="M342.71,156.903c-1.414,0-2.794,0.145-4.129,0.416v45.419c0,6.841,5.546,12.387,12.387,12.387
		s12.387-5.546,12.387-12.387v-25.191C363.355,166.146,354.112,156.903,342.71,156.903z"
        />
        <path
            fill="#FFFFFF"
            d="M367.048,317.428c4.464-7.649,8.199-15.845,11.331-24.377
		c-38.384,7.921-79.532,12.253-122.379,12.253c-42.873,0-84.044-4.336-122.448-12.267c3.148,8.573,6.944,16.784,11.438,24.465
		c5.195,8.88,13.99,14.56,23.558,15.724c28.649,3.485,57.847,5.354,87.452,5.354c29.599,0,58.79-1.868,87.433-5.352
		C353.033,332.061,361.845,326.344,367.048,317.428z"
        />
    </svg>
);

export const sadEmoji = (className = "", grey = true): JSX.Element => (
    <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" className={className}>
        <circle fill={grey ? "#D8D8D8" : "#FFE17D"} cx="256" cy="256" r="256" />
        {!grey && (
            <path
                fill="#FFD164"
                d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.376-161.463
		C36.863,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.626-94.538
		C410.573,453.317,354.389,474.839,293.161,474.839z"
            />
        )}
        <g>
            <path
                fill={grey ? "#515151" : "#AA7346"}
                d="M115.621,216.778c-3.79,0-7.435-2.093-9.242-5.71c-2.548-5.101-0.484-11.298,4.621-13.851
			l66.065-33.032c5.097-2.536,11.306-0.472,13.847,4.617c2.548,5.101,0.484,11.298-4.621,13.851l-66.065,33.032
			C118.75,216.423,117.169,216.778,115.621,216.778z"
            />
            <path
                fill={grey ? "#515151" : "#AA7346"}
                d="M396.379,216.778c-1.548,0-3.129-0.351-4.605-1.093l-66.065-33.032
			c-5.105-2.552-7.169-8.75-4.621-13.851c2.54-5.093,8.75-7.181,13.847-4.617L401,197.218c5.105,2.552,7.169,8.75,4.621,13.851
			C403.815,214.685,400.169,216.778,396.379,216.778z"
            />
        </g>
        <path
            fill={grey ? "#515151" : "#9C6846"}
            d="M255.941,404.675L255.941,404.675c-22.804,0-41.29-18.486-41.29-41.29V338.61
		c0-22.804,18.486-41.29,41.29-41.29l0,0c22.804,0,41.29,18.486,41.29,41.29v24.774C297.231,386.189,278.745,404.675,255.941,404.675
		z"
        />
        <path
            fill="#FFFFFF"
            d="M215.486,330.323h80.91c-3.838-18.831-20.49-33.003-40.455-33.003h-0.001
		C235.975,297.32,219.324,311.491,215.486,330.323z"
        />
        <path
            fill={grey ? "#515151" : "#7D5046"}
            d="M214.651,338.61v16.486h82.581V338.61c0-2.839-0.289-5.61-0.835-8.288h-80.91
		C214.94,333.001,214.651,335.771,214.651,338.61z"
        />
        <path
            fill={grey ? "#515151" : "#A5CDFF"}
            d="M139.401,247.059c-5.239,31.155-19.87,31.794-23.734,67.42c-4.13,38.085,12.285,39.953,8.155,78.038
		c-3.827,35.287-18.172,36.366-23.548,66.615c21.903,16.817,46.599,30.145,73.278,39.22c1.356-37.065,16.383-38.983,16.383-79.533
		c0-42.461-16.516-42.461-16.516-84.922c0-42.432,16.516-42.432,16.516-84.864L139.401,247.059z"
        />
        <path
            fill={grey ? "#515151" : "#AA7346"}
            d="M165.129,266.323c-15.685,0-30.661-7.423-41.073-20.367c-3.573-4.444-2.871-10.94,1.573-14.516
		c4.435-3.565,10.935-2.855,14.508,1.573c6.573,8.169,15.452,12.665,24.992,12.665c9.548,0,18.427-4.496,24.984-12.661
		c3.565-4.435,10.073-5.149,14.516-1.577c4.444,3.569,5.153,10.069,1.581,14.512C195.798,258.899,180.831,266.323,165.129,266.323z"
        />
        <path
            fill={grey ? "#515151" : "#8CB4EB"}
            d="M185.407,451.055c-23.67-11.003-45.404-25.465-64.479-42.821
		c-5.705,20.804-16.221,25.958-20.653,50.898l0,0c21.903,16.817,46.599,30.145,73.278,39.219
		C174.42,474.657,180.863,465.299,185.407,451.055z"
        />
        <path
            fill={grey ? "#515151" : "#A5CDFF"}
            d="M396.333,314.48c-3.863-35.626-18.494-36.266-23.734-67.42l-50.534,1.973
		c0,42.432,16.516,42.432,16.516,84.864c0,42.462-16.516,42.462-16.516,84.923c0,40.55,15.027,42.468,16.383,79.533
		c26.679-9.075,51.375-22.403,73.278-39.22c-5.376-30.249-19.721-31.328-23.548-66.615
		C384.048,354.433,400.463,352.565,396.333,314.48z"
        />
        <path
            fill={grey ? "#515151" : "#8CB4EB"}
            d="M338.447,498.352c26.679-9.075,51.375-22.403,73.278-39.22c-0.811-4.564-1.843-8.408-2.995-11.861
		c-23.108,11.722-48.237,20.012-74.722,24.266C336.243,478.532,338.021,486.705,338.447,498.352z"
        />
        <path
            fill={grey ? "#515151" : "#AA7346"}
            d="M346.871,266.323c-15.702,0-30.669-7.423-41.081-20.371c-3.573-4.444-2.863-10.944,1.581-14.512
		c4.444-3.573,10.952-2.859,14.516,1.577c6.556,8.165,15.435,12.661,24.984,12.661c9.548,0,18.419-4.496,24.992-12.661
		c3.548-4.435,10.073-5.149,14.508-1.577c4.444,3.573,5.145,10.073,1.573,14.512C377.532,258.899,362.565,266.323,346.871,266.323z"
        />
    </svg>
);

export const angryEmoji = (className = "", grey = true): JSX.Element => (
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        enableBackground="new 0 0 512 512"
        className={className}
    >
        <circle fill={grey ? "#D8D8D8" : "#FFE17D"} cx="256" cy="256" r="256" />
        <path
            fill={grey ? "#515151" : "#7D5046"}
            d="M169.29,280.774L169.29,280.774c-11.402,0-20.645-9.243-20.645-20.645v-16.516
		c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645v16.516
		C189.935,271.531,180.692,280.774,169.29,280.774z"
        />
        <path
            fill={grey ? "#515151" : "#9C6846"}
            d="M169.29,222.968c-1.414,0-2.794,0.145-4.129,0.416v28.487c0,6.841,5.546,12.387,12.387,12.387
		s12.387-5.546,12.387-12.387v-8.258C189.935,232.211,180.693,222.968,169.29,222.968z"
        />
        <path
            fill={grey ? "#515151" : "#7D5046"}
            d="M342.71,280.774L342.71,280.774c-11.402,0-20.645-9.243-20.645-20.645v-16.516
		c0-11.402,9.243-20.645,20.645-20.645l0,0c11.402,0,20.645,9.243,20.645,20.645v16.516
		C363.355,271.531,354.112,280.774,342.71,280.774z"
        />
        <path
            fill={grey ? "#515151" : "#9C6846"}
            d="M342.71,222.968c-1.414,0-2.794,0.145-4.129,0.416v28.487c0,6.841,5.546,12.387,12.387,12.387
		s12.387-5.546,12.387-12.387v-8.258C363.355,232.211,354.112,222.968,342.71,222.968z"
        />
        {!grey && (
            <path
                fill="#FFD164"
                d="M293.161,474.839c-141.385,0-256-114.615-256-256c0-61.227,21.521-117.411,57.376-161.463
		C36.863,104.316,0,175.842,0,256c0,141.385,114.615,256,256,256c80.159,0,151.685-36.864,198.626-94.538
		C410.573,453.317,354.389,474.839,293.161,474.839z"
            />
        )}
        <g>
            <path
                fill={grey ? "#515151" : "#AA7346"}
                d="M206.452,208.52c-1.081,0-2.177-0.173-3.266-0.532l-74.323-24.774
			c-5.403-1.802-8.331-7.649-6.524-13.06c1.79-5.403,7.597-8.335,13.056-6.528l74.323,24.774c5.403,1.802,8.331,7.649,6.524,13.06
			C214.806,205.782,210.774,208.52,206.452,208.52z"
            />
            <path
                fill={grey ? "#515151" : "#AA7346"}
                d="M305.548,208.52c-4.323,0-8.355-2.738-9.79-7.06c-1.806-5.411,1.121-11.258,6.524-13.06
			l74.323-24.774c5.387-1.806,11.258,1.125,13.056,6.528c1.806,5.411-1.121,11.258-6.524,13.06l-74.323,24.774
			C307.734,208.347,306.629,208.52,305.548,208.52z"
            />
        </g>
        <path
            fill={grey ? "#515151" : "#9C6846"}
            d="M255.941,372.752c20.823,0,40.8,2.002,59.375,5.661c5.87,1.157,10.402-4.846,8.103-10.37
		c-11.256-27.037-37.208-45.979-67.478-45.979s-56.222,18.941-67.478,45.979c-2.3,5.524,2.233,11.526,8.103,10.37
		C215.141,374.754,235.118,372.752,255.941,372.752z"
        />
        <path
            fill={grey ? "#515151" : "#7D5046"}
            d="M255.941,346.838c24.972,0,47.815,6.504,65.705,17.268c-12.055-24.92-36.927-42.041-65.704-42.041
		s-53.65,17.121-65.705,42.04C208.126,353.342,230.969,346.838,255.941,346.838z"
        />
    </svg>
);

// SOLID EMOJIS

export const sadEmojiSolid = (className = "", color = "#fff") => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={className}>
        <path
            fill={color}
            d="M256,0C114.62,0,0,114.62,0,256S114.62,512,256,512,512,397.38,512,256,397.38,0,256,0ZM148.65,235.35V218.84a20.64,20.64,0,1,1,41.28,0v16.51a20.64,20.64,0,1,1-41.28,0ZM345.54,379.21A10.31,10.31,0,0,1,331,378.6c-17.11-18.6-45.14-29.7-75-29.7s-57.88,11.1-75,29.7a10.32,10.32,0,1,1-15.19-14c21-22.77,54.67-36.36,90.18-36.36s69.23,13.59,90.17,36.36A10.32,10.32,0,0,1,345.54,379.21Zm17.81-143.86a20.64,20.64,0,1,1-41.28,0V218.84a20.64,20.64,0,1,1,41.28,0Z"
        />
    </svg>
);

export const retweetIcon = (className = "", color = "#fff") => (
    <svg viewBox="0 -91 512 512" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path
            fill={color}
            d="m512 250.644531-79.796875 79.671875-80.363281-80.03125 28.210937-28.328125 31.871094 31.738281v-193.613281c.035156-5.277343-1.996094-10.246093-5.71875-13.992187-3.757813-3.785156-8.777344-5.867188-14.128906-5.867188h-215.230469l-40.144531-39.980468h255.375c16.082031 0 31.171875 6.277343 42.492187 17.667968 11.265625 11.34375 17.417969 26.382813 17.332032 42.363282v193.882812l31.851562-31.804688zm-184.039062 39.449219h-208.035157c-5.355469 0-10.371093-2.082031-14.128906-5.863281-3.722656-3.746094-5.753906-8.71875-5.71875-13.996094v-193.613281l31.871094 31.738281 28.210937-28.328125-80.363281-80.03125-79.796875 79.675781 28.25 28.289063 31.851562-31.800782v193.878907c-.085937 15.980469 6.066407 31.023437 17.332032 42.363281 11.320312 11.390625 26.410156 17.667969 42.492187 17.667969h255.375l-40.144531-39.980469zm0 0"
        />
    </svg>
);

export const starIcon = (className = "", color = "#fbd44c") => (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g id="star">
            <path
                fill={color}
                d="m29.911 13.75-6.229 6.072 1.471 8.576c.064.375-.09.754-.398.978-.174.127-.381.191-.588.191-.159 0-.319-.038-.465-.115l-7.702-4.049-7.701 4.048c-.336.178-.745.149-1.053-.076-.308-.224-.462-.603-.398-.978l1.471-8.576-6.23-6.071c-.272-.266-.371-.664-.253-1.025s.431-.626.808-.681l8.609-1.25 3.85-7.802c.337-.683 1.457-.683 1.794 0l3.85 7.802 8.609 1.25c.377.055.69.319.808.681s.019.758-.253 1.025z"
            />
        </g>
    </svg>
);

export const menuOpenIcon = (className = "", color = "#fbd44c") => (
    <svg viewBox="0 0 512 446.2" className={className}>
        <g>
            <rect fill={color} x="47.1" y="69.3" width="417.3" height="39" />
            <rect fill={color} x="47.1" y="183.7" width="417.3" height="39" />
            <rect fill={color} x="46.4" y="298" width="258.7" height="39" />
            <polygon fill={color} points="442.2,288.1 400.2,330.2 356.4,286.4 333.1,309.7 400.2,376.8 465.6,311.5 	" />
        </g>
    </svg>
);

export const menuCloseIcon = (className = "", color = "#fbd44c") => (
    <svg className={className} viewBox="0 0 512 446.2">
        <g>
            <rect fill={color} x="47.1" y="69.3" width="417.3" height="39" />
            <rect fill={color} x="47.1" y="183.7" width="417.3" height="39" />
            <rect fill={color} x="46.4" y="298" width="258.7" height="39" />
            <polygon fill={color} points="442.2,375 400.2,333 356.4,376.8 333.1,353.5 400.2,286.4 465.6,351.7 	" />
        </g>
    </svg>
);

export const circleCheckIcon = (className = "", color = "#e86fae", circleColor = "") => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
        <g id="Gruppe_827" data-name="Gruppe 827" transform="translate(-1686 -343)">
            <path
                id="check"
                d="M24.932,5.469a1.6,1.6,0,0,1,0,2.262l-12.8,12.8a1.6,1.6,0,0,1-2.262,0l-6.4-6.4a1.6,1.6,0,1,1,2.263-2.263L11,17.138,22.67,5.469A1.6,1.6,0,0,1,24.932,5.469Z"
                transform="translate(1692.8 351)"
                fill={color}
            />
            <g id="Rechteck_287" data-name="Rechteck 287" transform="translate(1686 343)" fill="none" stroke={circleColor || color} strokeWidth="2">
                <rect width="42" height="42" rx="21" stroke="none" />
                <rect x="1" y="1" width="40" height="40" rx="20" fill="none" />
            </g>
        </g>
    </svg>
);

export const circleXIcon = (className = "", color = "#fff") => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
        <g id="Gruppe_826" data-name="Gruppe 826" transform="translate(-1629 -343)">
            <g id="Rechteck_288" data-name="Rechteck 288" transform="translate(1629 343)" fill="none" stroke={color} strokeWidth="2">
                <rect width="42" height="42" rx="21" stroke="none" />
                <rect x="1" y="1" width="40" height="40" rx="20" fill="none" />
            </g>
            <path
                id="x"
                d="M4.391,4.391a1.333,1.333,0,0,1,1.885,0L12,10.115l5.724-5.724A1.333,1.333,0,1,1,19.61,6.276L13.885,12l5.724,5.724a1.333,1.333,0,1,1-1.885,1.885L12,13.885,6.276,19.609a1.333,1.333,0,1,1-1.885-1.885L10.115,12,4.391,6.276a1.333,1.333,0,0,1,0-1.885Z"
                transform="translate(1638 352)"
                fill={color}
                fillRule="evenodd"
            />
        </g>
    </svg>
);

export const multimediaIcon = (color = "#fff", className = "") => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 50 50">
        <g id="film">
            <path
                fill={color}
                stroke={color}
                d="M40.7,42H9c-1.9,0-3.5-1.6-3.5-3.5V10.9c0-1.9,1.6-3.5,3.5-3.5h31.6c1.9,0,3.5,1.6,3.5,3.5v27.7
			C44.2,40.5,42.6,42,40.7,42z M36.3,39h4.4c0.3,0,0.5-0.2,0.5-0.5v-4.4h-4.9V39z M16.5,39h16.8V26.2H16.5V39z M8.6,34.1v4.4
			C8.6,38.8,8.8,39,9,39h4.4v-4.9H8.6z M36.3,31.1h4.9v-4.9h-4.9V31.1z M8.6,31.1h4.9v-4.9H8.6V31.1z M36.3,23.2h4.9v-4.9h-4.9V23.2z
			M16.5,23.2h16.8V10.4H16.5V23.2z M8.6,23.2h4.9v-4.9H8.6V23.2z M36.3,15.3h4.9v-4.4c0-0.3-0.2-0.5-0.5-0.5h-4.4V15.3z M8.6,15.3
			h4.9v-4.9H9c-0.3,0-0.5,0.2-0.5,0.5V15.3z"
            />
        </g>
    </svg>
);

export const timelineIcon = (color = "#fff", className = "") => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={className} viewBox="0 0 50 50">
        <g id="menu-alt-1">
            <path
                fill={color}
                stroke={color}
                d="M43.7,41.5H6.3c-0.8,0-1.4-0.7-1.4-1.5s0.6-1.5,1.4-1.5h37.3c0.8,0,1.4,0.7,1.4,1.5S44.4,41.5,43.7,41.5z
		 M25,26.5H6.3c-0.8,0-1.4-0.7-1.4-1.5s0.6-1.5,1.4-1.5H25c0.8,0,1.4,0.7,1.4,1.5S25.8,26.5,25,26.5z M43.7,11.5H6.3
		c-0.8,0-1.4-0.7-1.4-1.5s0.6-1.5,1.4-1.5h37.3c0.8,0,1.4,0.7,1.4,1.5S44.4,11.5,43.7,11.5z"
            />
        </g>
    </svg>
);
