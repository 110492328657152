/* eslint-disable no-promise-executor-return */
import useLanguageContext, { IUseLanguageContext } from "@helper/react-context/context-hooks/use-language-context";
import { EMultimediaFilter } from "models/multimedia-filter";
import React, { createContext, FC, ReactNode } from "react";

import useCompanyProfile, { IUseCompanyProfileContext } from "./context-hooks/use-company-profile-context";
import useFilesContext, { IUseFilesContext } from "./context-hooks/use-files-context";
import useFilterContext, { IUseFilterContext } from "./context-hooks/use-filter-context";
import useLocationContext, { IUseLocationContext } from "./context-hooks/use-location-context";
import useSettingsContext, { IUseSettingsContext } from "./context-hooks/use-settings-context";
import useSignUpContext, { IUseSignUpContext } from "./context-hooks/use-sign-up-context";
import useVirtualTourContext, { IUseVirtualTourContext } from "./context-hooks/use-virtual-tour-context";
import useVisualContext, { IUseVisualContext } from "./context-hooks/use-visual-context";

interface IProperties {
    children: ReactNode;
}

export interface IProviderContext {
    language: IUseLanguageContext;
    location: IUseLocationContext;
    visual: IUseVisualContext;
    signUp: IUseSignUpContext;
    filter: IUseFilterContext;
    files: IUseFilesContext;
    settings: IUseSettingsContext;
    virtualTour: IUseVirtualTourContext;
    companyProfile: IUseCompanyProfileContext;
}

const functionDefaults = {
    void: () => {},
    voidPromise: () => new Promise(() => {}),
    indexState: { index: -1, setIndex: () => {}, resetIndex: () => {} },
    useOpenClose: {
        isOpen: false,
        handleOpen: () => {},
        handleClose: () => {},
        toggleOpenClose: () => {},
    },
    useNumberArray: {
        array: [],
        addToArray: () => {},
        removeItemFromArray: (f: any) => f,
        toggleItemInArray: () => {},
        containsItem: () => false,
        resetArray: () => {},
        emptyArray: () => {},
    },
    useIdState: { id: -1, setId: () => {}, resetId: () => {} },
};

// Set the initial states here.
// Functions can be set as empty functions that return themselves, like (f) => f or as undefined
export const Context = createContext<IProviderContext>({
    language: {
        currentSlug: "",
        localizedSlugs: undefined,
        registerLocalizedSlugs: functionDefaults.void,
        onLanguageChange: functionDefaults.void,
    },
    location: {
        reverseGeocoding: (f) => new Promise(() => f),
        getCountries: () => new Promise(() => {}),
        getLocationsOfAddress: (f) => new Promise(() => f),
        getAddressFromLatLong: (f) => new Promise(() => f),
    },
    visual: {
        toggleMenuHome: functionDefaults.indexState,
        toggleMenuProfile: functionDefaults.indexState,
        toggleMenuCompanyPage: functionDefaults.indexState,
        darkLightMode: "dark",
        toggleDarkLightMode: functionDefaults.void,
        notificationOpenClose: functionDefaults.useOpenClose,
        searchOpenClose: functionDefaults.useOpenClose,
        navbarOpenClose: functionDefaults.useOpenClose,
        profileFilterOpenClose: functionDefaults.useOpenClose,
        quickSettingsOpenClose: functionDefaults.useOpenClose,
        loading: { isLoading: false, setIsLoading: (f) => f },
        companyInfo: { selectedOption: "Nothing", setSelectedOption: (f) => f },
        networkSetting: { selectedOption: "Nothing", setSelectedOption: (f) => f },
        watchedStories: functionDefaults.useNumberArray,
        profilePictures: [],
        setProfilePictures: functionDefaults.void,
        getProfilePicturesByUserId: () => undefined,
    },
    signUp: {
        step: 0,
        setStep: (f) => f,
        nextStep: undefined,
        selectedInterests: [],
        hasSelectedItem: () => false,
        addSelectedInterest: (f) => f,
        removeSelectedInterest: (f) => f,
        toggleSelectedInterest: (f) => f,
        setSelectedInterests: (f) => f,
        user: () => {},
        setUser: (f) => f,
        clearUserState: functionDefaults.void,
    },
    companyProfile: {
        openCloseAddCompany: functionDefaults.useOpenClose,
        openCloseEditCompany: functionDefaults.useOpenClose,
        activeCompany: undefined,
    },
    filter: {
        selectedMultimediaFilter: EMultimediaFilter.all,
        setSelectedMultimediaFilter: functionDefaults.void,
        search: { searchString: "", setSearchString: functionDefaults.void },
        selectedPopularFilter: "nothing",
        setSelectedPopularFilter: functionDefaults.void,
        postSettings: [],
        changePostSettings: functionDefaults.void,
        changePostNetworkSettings: functionDefaults.void,
        postNetworkSettings: [],
    },
    files: {
        makePostImages: undefined,
        makePostAudio: undefined,
        profileHeaderImage: undefined,
        profilePictureImage: undefined,
    },
    settings: {
        globalVolume: {
            volume: 0.8,
            setVolume: functionDefaults.void,
            setMuted: functionDefaults.void,
            muted: false,
        },
    },
    virtualTour: {
        stepIndex: 0,
        steps: [],
        setSteps: () => {},
        onStart: () => {},
        onFinish: () => {},
        onNextStep: () => {},
        tourKey: "",
        setTourKey: () => {},
    },
});

const ContextProvider: FC<IProperties> = ({ children }: IProperties) => {
    return (
        <Context.Provider
            value={{
                language: useLanguageContext(),
                location: useLocationContext(),
                visual: useVisualContext(),
                signUp: useSignUpContext(),
                filter: useFilterContext(),
                files: useFilesContext(),
                settings: useSettingsContext(),
                virtualTour: useVirtualTourContext(),
                companyProfile: useCompanyProfile(),
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default ContextProvider;
