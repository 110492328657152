import H2 from "@components/helper/h2";
import useIndexState from "@helper/custom-hooks/use-index-state";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import React, { FC, ReactNode } from "react";
import Slider from "react-slick";

interface IButtonProps {
    onClick?: () => void;
    hidden: boolean;
}

export const NextArr = ({ onClick, hidden }: IButtonProps): JSX.Element => {
    return (
        <ul className={`absolute -right-10 top-1/2 -translate-y-1/2 transition hover:scale-110${hidden ? "hidden" : "visible"}`}>
            <li className="flex flex-col items-center space-y-1">
                <div className=" rounded-full bg-gradient-to-r from-rc-pink to-rc-blue p-0.5 ">
                    <div className=" flex h-8 w-8 cursor-pointer justify-center  rounded-full bg-white text-white dark:bg-rc-dark" onClick={onClick}>
                        <ChevronRightIcon className="h-4 w-4 self-center text-black dark:text-white" />
                    </div>
                </div>
            </li>
        </ul>
    );
};

export const PrevArr = ({ onClick, hidden }: IButtonProps): JSX.Element => {
    return (
        <ul className={`absolute -left-10 top-1/2 -translate-y-1/2 transition hover:scale-110${hidden ? "hidden" : "visible"}`}>
            <li className="flex flex-col items-center space-y-1">
                <div className=" rounded-full bg-gradient-to-r from-rc-pink to-rc-blue p-0.5 ">
                    <div className=" flex h-8 w-8 cursor-pointer justify-center  rounded-full bg-white text-white dark:bg-rc-dark" onClick={onClick}>
                        <ChevronLeftIcon className="h-4 w-4 self-center text-black dark:text-white" />
                    </div>
                </div>
            </li>
        </ul>
    );
};
export interface Props {
    title?: string;
    children: ReactNode;
    infinite: boolean;
    slidesToShow: number;
    slidesToShowXl: number;
    slidesToShowLg: number;
    slidesToShowMd: number;
    slidesToShowSm: number;
    slidesToScroll: number;
}

const Carousel: FC<Props> = ({
    children,
    title,
    infinite,
    slidesToScroll,
    slidesToShow,
    slidesToShowXl,
    slidesToShowLg,
    slidesToShowMd,
    slidesToShowSm,
}: Props) => {
    const { index, setIndex } = useIndexState(0);

    const settings = {
        infinite,
        slidesToShow,
        slidesToScroll,
        nextArrow: <NextArr hidden={Array.isArray(children) && children.length - 1 === index} />,
        prevArrow: <PrevArr hidden={index === 0} />,
        responsive: [
            {
                breakpoint: 3839,
                settings: {
                    slidesToShow: slidesToShowXl,
                },
            },
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: slidesToShowLg,
                },
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: slidesToShowMd,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: slidesToShowSm,
                },
            },
        ],
    };

    return (
        <div>
            {title && (
                <div className="mb-5">
                    <H2>{title}</H2>
                </div>
            )}
            <div>
                <Slider {...settings} afterChange={(e: number) => setIndex(e)}>
                    {children}
                </Slider>
            </div>
        </div>
    );
};
export default Carousel;
