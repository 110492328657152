import { CompanySize } from "components/companies/type";

export const COMPANY_SIZE_OPTIONS = [
    {
        text: "1 - 5",
        value: CompanySize.MICRO,
    },
    {
        text: "6 - 20",
        value: CompanySize.SMALL,
    },
    {
        text: "21 - 50",
        value: CompanySize.MEDIUM_SMALL,
    },
    {
        text: "51 - 100",
        value: CompanySize.MEDIUM,
    },
    {
        text: "101 - 500",
        value: CompanySize.MEDIUM_LARGE,
    },
    {
        text: "500+",
        value: CompanySize.LARGE,
    },
];

export const COMPANY_SIZE_LABELS = {
    [CompanySize.MICRO]: "1-5",
    [CompanySize.SMALL]: "6-20",
    [CompanySize.MEDIUM_SMALL]: "21-50",
    [CompanySize.MEDIUM]: "51-100",
    [CompanySize.MEDIUM_LARGE]: "101-500",
    [CompanySize.LARGE]: "500+",
};
