import create from "zustand";
import { devtools, persist } from "zustand/middleware";

interface PlayerPersistSettingsStoreState {
    playerTime: number;
    volume: number;
    seek: number;
    setSeek: (seek: number) => void;
    setVolume: (volume: number) => void;
    setPlayerTime: (playerTime: number) => void;
}
const PlayerPersistSettingsStore = create<PlayerPersistSettingsStoreState>(
    devtools(
        persist(
            (set) => ({
                playerTime: 0,
                volume: 1,
                seek: 0,
                setSeek: (seek: number) => set(() => ({ seek })),
                setVolume: (volume: number) => set(() => ({ volume })),
                setPlayerTime: (playerTime: number) => set(() => ({ playerTime })),
            }),
            {
                name: "PlayerPersistSettingsStore",
            },
        ),
        { name: "PlayerPersistSettingsStore" },
    ),
);

export default PlayerPersistSettingsStore;
