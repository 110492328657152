import { IPlayerItem } from "@models/player/player-item";

import PlayerStore from "./player-store";

export function addItemToQueue(item: IPlayerItem) {
    const { queue, setQueue } = PlayerStore.getState();
    setQueue([...queue, item]);
}
export function addItemsToQueue(items: IPlayerItem[]) {
    const { queue, setQueue } = PlayerStore.getState();
    setQueue([...queue, ...items]);
}

export function updateCurrentPlayerList(
    type: string,
    name: string,
    tracks: IPlayerItem[],
    playing_position: number,
    playlist_id?: number,
    resource_id?: string,
) {
    const { setCurrentPlayerList } = PlayerStore.getState();

    setCurrentPlayerList({
        id: resource_id || playlist_id,
        name,
        type,
        tracks,
        playing_position,
    });
}

export function updatePlayingPosition(playing_position: number) {
    const { setCurrentPlayerList, currentPlayerList } = PlayerStore.getState();

    setCurrentPlayerList({ ...currentPlayerList, playing_position });
}
