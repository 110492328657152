import { useState } from "react";

export interface IUseNumberArray {
    array: number[];
    addToArray: (item: number) => void;
    removeItemFromArray: (item: number) => void;
    toggleItemInArray: (item: number) => void;
    containsItem: (item: number) => boolean;
    resetArray: () => void;
    emptyArray: () => void;
}

const useNumberArray = (initialArray = []): IUseNumberArray => {
    const [array, setArray] = useState<number[]>(initialArray);

    const addToArray = (item: number): void => {
        if (!array.includes(item)) {
            setArray([...array, item]);
        }
    };

    const removeItemFromArray = (item: number): void => {
        setArray(array.filter((v) => item !== v));
    };

    const containsItem = (item: number): boolean => {
        return array.includes(item);
    };

    const resetArray = (): void => {
        setArray(initialArray);
    };

    const emptyArray = (): void => {
        setArray([]);
    };

    const toggleItemInArray = (item: number): void => {
        if (containsItem(item)) {
            removeItemFromArray(item);
        } else {
            addToArray(item);
        }
    };

    return {
        array,
        addToArray,
        removeItemFromArray,
        toggleItemInArray,
        containsItem,
        resetArray,
        emptyArray,
    };
};

export default useNumberArray;
