import { PostAPIResponse, PostAuthor, PostTopicResponse, Topic, UsersAPIResponse } from "@models/post";
import axios from "axios";
import { toast } from "react-toastify";

const twitchClient = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_BACKEND}/twitch`,
    withCredentials: true,
});

let isFirstRefreshRequest = true;
twitchClient.interceptors.response.use(null, (error) => {
    if (error.config && error.response && error.response.status === 401 && isFirstRefreshRequest) {
        isFirstRefreshRequest = false;
        return axios
            .get(`${process.env.NEXT_PUBLIC_BACKEND}/twitch/refresh`, { withCredentials: true })
            .then(() => {
                isFirstRefreshRequest = true;
                return axios.request(error.config);
            })
            .catch((err) => {
                if (err.message === "canceled") {
                    return;
                }
                if (err.response.status === 409) {
                    toast.error("Please reconnect your Twitch account");
                } else {
                    toast.error("Failed to refresh Twitch token, please try again");
                }
                return Promise.reject(err);
            });
    }

    return Promise.reject(error);
});

export async function topTwitchGames(): Promise<undefined | PostTopicResponse> {
    const data = await twitchClient.get(`/topics`);
    return data.data;
}
export async function getFollowedStreams(): Promise<undefined | UsersAPIResponse> {
    const data = await twitchClient.get(`/streams/followed`);
    return data.data;
}

export async function getStreamsByGame(game_id: string, after?: string, first = 100): Promise<PostAPIResponse | undefined> {
    const data = await twitchClient.get(`/topic/stream/${game_id}`, {
        params: {
            first,
            language: "de",
            after,
        },
    });
    return data.data;
}
export async function getPopularStreams(after?: string, first = 100): Promise<PostAPIResponse | undefined> {
    const data = await twitchClient.get(`/streams/popular`, {
        params: {
            first,
            language: "de",
            after,
        },
    });
    return data.data;
}

export async function getStreamByUser(user_id?: string, user_login?: string): Promise<PostAPIResponse | undefined> {
    const data = await twitchClient.get(`/user/stream`, {
        params: {
            user_id,
            user_login,
            language: "de",
        },
    });
    return data.data;
}

export async function getTwitchUser(id?: string, login?: string): Promise<PostAuthor | undefined> {
    const data = await twitchClient.get(`/user`, {
        params: {
            id,
            login,
            language: "de",
        },
    });

    return data?.data?.users?.[0];
}

export async function searchTwitchCategories(query: string): Promise<Topic[] | undefined> {
    const data = await twitchClient.get(`/topic/search`, {
        params: {
            query,
            first: 100,
        },
    });
    return data.data.topics;
}
export async function searchTwitchChannels(query: string): Promise<PostAuthor[] | undefined> {
    const data = await twitchClient.get(`/user/search`, {
        params: {
            query,
            live_only: true,
            first: 50,
        },
    });
    return data?.data?.users;
}

export async function getGame(id: number | string): Promise<PostTopicResponse | undefined> {
    const data = await twitchClient.get(`/topic/${id}`);
    return data.data.data[0];
}

export async function getFollowers(pageToken?: string, userId?: string): Promise<UsersAPIResponse | undefined> {
    const data = await twitchClient.get(`/follows`, { params: { pageToken, userId: userId || "" } });

    return data?.data;
}
