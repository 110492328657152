import H2 from "@components/helper/h2";
import Modal from "@components/modal/modal";
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nextTrack } from "@helper/player/player-helper";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import { XIcon } from "@heroicons/react/solid";
import { IPlayerItem } from "@models/player/player-item";
import React, { FC } from "react";

import MediumVideoPlayer from "../medium-video-player";

interface ITwitterPlayer {
    playerItem: IPlayerItem;
}

const TwitterPlayer: FC<ITwitterPlayer> = ({ playerItem }: ITwitterPlayer) => {
    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);
    const setVideoModal = PlayerSettingsStore((state) => state.setVideoModal);
    const setIsLargeVideoPlayer = PlayerSettingsStore((state) => state.setIsLargeVideoPlayer);
    const videoModal = PlayerSettingsStore((state) => state.videoModal);

    return (
        <Modal
            handler={{
                isOpen: videoModal,
                handleClose: () => {},
                handleOpen: () => {},
                toggleOpenClose: () => {},
            }}
        >
            <div className="flex flex-col px-4 lg:px-0">
                <div className="flex flex-row flex-nowrap items-center justify-between py-5">
                    <H2>{playerItem.name}</H2>
                    <div className="my-5 flex flex-row flex-nowrap items-center space-x-2">
                        <FontAwesomeIcon
                            icon={faWindowMinimize}
                            className="mr-1 cursor-pointer text-white"
                            onClick={() => {
                                setVideoModal(false);
                                setIsLargeVideoPlayer(false);
                            }}
                        />
                        <XIcon
                            className="h-6 w-6 cursor-pointer "
                            onClick={() => {
                                setVideoModal(false);
                                setIsPlaying(false);
                                setIsLargeVideoPlayer(false);
                            }}
                        />
                    </div>
                </div>
                <MediumVideoPlayer
                    isLarge
                    duration_ms={playerItem.duration_ms}
                    showIcons={false}
                    link={playerItem.resource_id}
                    videoUrl={playerItem.preview_url}
                    onEnded={nextTrack}
                />
            </div>
        </Modal>
    );
};

export default TwitterPlayer;
