import { IProvider } from "@components/types";
import { EProviderIds } from "@models/provider-ids";

import AuthStore from "./auth-store";

export const lookForProvider = (id: EProviderIds): boolean => {
    const { user } = AuthStore.getState();

    return user?.provider?.some((f) => f.provider_id === id);
};

export const findProvider = (id: EProviderIds): IProvider => {
    const { user } = AuthStore.getState();

    return user?.provider?.find((f) => f.provider_id === id);
};

export const getProviders = (): IProvider[] => {
    const user = AuthStore((state) => state.user);
    const companies = user?.companies || [];
    const activeCompany = companies.find((company) => company.active);
    const profileType = user.profile_type;
    const providers = profileType === "business" ? activeCompany?.providers : user?.provider;

    return providers || [];
};
