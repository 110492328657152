import { IProvider } from "@components/types";

import { IPlace } from "./company-profile-info/company-places.interface";

export type ICreateProfileRequestBody = {
    name: string;
    slug: string;
    about?: string;
    videoUrl?: string;
    industry: string;
    size: CompanySize;
    headquarters?: string;
    isVerified?: boolean;
    avatar?: string;
    founded: string;
    spezialist: string;
    websiteUrl?: string;
    contact?: ContactInfo;
    verificationFiles?: any;
};

export enum CompanySize {
    MICRO = "micro",
    SMALL = "small",
    MEDIUM_SMALL = "medium_small",
    MEDIUM = "medium",
    MEDIUM_LARGE = "medium_large",
    LARGE = "large",
}

type ContactInfo = {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
};

export type IHashtag = {
    name: string;
    description: string;
};

export type CompanyProfile = {
    _id: string;
    name: string;
    slug: string;
    industry: string;
    size: string;
    founded: string;
    headquarters?: string;
    isVerified?: boolean;
    avatar?: string;
    coverImage?: string;
    spezialist?: string;
    websiteUrl?: string;
    about?: string;
    places?: IPlace[];
    videoUrl?: string;
    verificationFiles?: any;
    contactFirstName?: string;
    contactLastName?: string;
    contactEmail?: string;
    userId?: string;
    active: boolean;
    providers?: IProvider[];
    createdAt?: string;
    updatedAt?: string;
    hashtags?: IHashtag[];
    interests?: string[];
    categoriesInterests?: any;
};

export enum SocialFeedType {
    TWITTER = "twitter",
    YOUTUBE = "youtube",
    PINTEREST = "pinterest",
    TIKTOK = "tiktok",
    INSTAGRAM = "instagram",
}

export interface SocialFeedParams {
    twitterPageToken?: string;
    youtubePageToken?: string;
    pinterestPageToken?: string;
    tiktokPageToken?: string;
    instagramPageToken?: string;
    filter?: SocialFeedType[];
    verificationStatus?: VerificationStatus;
}

export enum VerificationStatus {
    PENDING = "pending",
    REJECTED = "rejected",
    VERIFIED = "verified",
}
