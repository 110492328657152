const countFormatList = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
];

export const formatNumber = (value: string | number) => {
    const tmp: string = typeof value === "number" ? value.toString() : value;
    return tmp?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const formatCountNumber = (num: string | number, precision = 1) => {
    if (!num) return "";

    const value = typeof num === "string" ? Number(num) : num;
    const { suffix, threshold } = countFormatList.find(({ threshold }) => Math.abs(value) >= threshold) || {};

    if (!suffix || !threshold) return String(value);

    let fixedNumber = (value / threshold).toFixed(precision);
    if (/[.][0]+$/.test(fixedNumber)) {
        fixedNumber = (value / threshold).toFixed(0);
    }
    return `${fixedNumber}${suffix}`;
};
