/* eslint-disable no-unsafe-optional-chaining */
import useHover from "@helper/custom-hooks/use-hover";
import { isNumber } from "@helper/types";
import React, { FC, useEffect, useRef, useState } from "react";

interface IAudioPlayerTicker {
    text: string;
    maxWidth?: string;
    pauseInMs?: number;
    onlyOnHover?: boolean;
}

const shouldAnimationRun = (onlyOnHover: boolean, isHovered: boolean): boolean => {
    return onlyOnHover ? isHovered : true;
};

// TODO Work in progress
const PlayerTicker: FC<IAudioPlayerTicker> = ({ text, maxWidth = "14.5rem", pauseInMs = 5000, onlyOnHover }: IAudioPlayerTicker) => {
    const [translatePos, setTranslatePos] = useState<number>(0);
    const [isMounted, setIsMounted] = useState<boolean>(true);
    const [timeoutTranslation, setTimeoutTranslation] = useState<NodeJS.Timeout>();
    const ref = useRef<any>();
    const hover = useHover();

    useEffect(() => {
        if (ref?.current && isMounted && shouldAnimationRun(!!onlyOnHover, hover.isHovered) && translatePos === 0) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const pos = ref?.current?.scrollWidth - ref?.current?.clientWidth;

            if (isNumber(pos) && pos > 0) {
                setTimeoutTranslation(setTimeout(() => setTranslatePos(pos), hover.isHovered ? pauseInMs / 4 : pauseInMs / 2));
            }
        }
    }, [ref, translatePos, text, hover.isHovered]);

    useEffect(() => {
        if (isMounted && translatePos > 0) {
            setTimeoutTranslation(setTimeout(() => setTranslatePos(0), text?.length * 50 + (hover.isHovered ? pauseInMs / 4 : pauseInMs / 2)));
        }
    }, [translatePos, text, hover.isHovered]);

    useEffect(() => {
        return () => {
            // @ts-ignore
            clearTimeout(timeoutTranslation);
            setIsMounted(false);
        };
    }, []);

    return (
        <div
            onMouseEnter={onlyOnHover ? hover.onMouseEnter : undefined}
            onMouseLeave={onlyOnHover ? hover.onMouseLeave : undefined}
            className="w-full overflow-hidden transition"
            style={{ maxWidth }}
        >
            <p
                ref={ref}
                className="whitespace-nowrap text-sm ease-linear dark:text-white lg:text-base"
                style={{
                    transform: `translateX(-${translatePos}px)`,
                    transitionDuration: `${translatePos > 0 ? text?.length * 50 : 0}ms`,
                }}
            >
                {text}
            </p>
        </div>
    );
};

export default PlayerTicker;
