import "moment/locale/de";

import moment from "moment";

export const agoTime = (time: Date | string) => {
    // moment.locale('de')
    return moment(time).fromNow();
};
export const getFormattedTimeStamp = (timestamp: Date, showHours = true): string =>
    `${timestamp.toLocaleDateString()}${showHours ? "," : ""} ${showHours ? timestamp.toLocaleTimeString() : ""}`;

export const msToTime = (ms: number): string => {
    if (typeof ms !== "number" || ms <= 0) return "";

    const seconds = moment.duration(ms).seconds();
    const minutes = moment.duration(ms).minutes();
    const hours = Math.trunc(moment.duration(ms).asHours());

    return `${hours ? `${hours}:` : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const formatDateToInput = (date: Date): string =>
    `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate()}`;

export const minutesAndSecondsFromDate = (date: Date): string => {
    const minutes = date.getMinutes().toLocaleString();
    const seconds = date.getSeconds().toLocaleString();
    const secondsDisplay = seconds.length > 1 ? seconds : `0${seconds}`;

    return `${minutes}:${secondsDisplay}`;
};

export const ytGetVideoTimeToRealTime = (date: string): number => {
    const reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let totalseconds;

    if (reptms.test(date)) {
        const matches = reptms.exec(date);
        if (matches[1]) hours = Number(matches[1]);
        if (matches[2]) minutes = Number(matches[2]);
        if (matches[3]) seconds = Number(matches[3]);
        totalseconds = hours * 3600 + minutes * 60 + seconds;
    }
    return totalseconds * 1000;
};

export const msToString = (_s: number) => {
    let s = _s;
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;

    return `${hrs}h ${mins}min`;
};
