import AuthStore from "@helper/auth/auth-store";
import { useRouter } from "next/router";
import { useEffect } from "react";

const ADMIN_ROUTES = ["/admin-dashboard", "/admin-dashboard/users", "/admin-dashboard/companies", "/settings", "/login", "/register"];
const EXCLUDED_ROUTES = ["/settings", "/login", "/register"];

const useRestrictRoute = () => {
    const router = useRouter();
    const user = AuthStore((state) => state.user);
    const isAdmin = user?.role === "admin";

    useEffect(() => {
        if (!user) return;
        const currentPath = router.asPath;
        if (!currentPath) return;
        if (!isAdmin) {
            if (ADMIN_ROUTES.includes(currentPath) && !EXCLUDED_ROUTES.includes(currentPath)) {
                router.push("/404");
            }
        } else if (!ADMIN_ROUTES.includes(currentPath)) {
            router.push("/404");
        }
    }, [user, router.asPath]);
};

export default useRestrictRoute;
