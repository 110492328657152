import React from "react";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface IRating {
    name: string;
    value: string;
}

export const Ratings: IRating[] = [
    { name: "40+", value: "50" },
    { name: "50+", value: "50" },
    { name: "60+", value: "60" },
    { name: "70+", value: "70" },
    { name: "80+", value: "80" },
    { name: "No restrictions", value: "-1" },
];

export interface RatingFilterState {
    ratingValues: IRating[];
    selectedRating: IRating;
    setSelectedRating: (ratintg: IRating) => void;
}

export const RatingStore = create<RatingFilterState>(
    devtools(
        (set) => ({
            ratingValues: Ratings,
            selectedRating: Ratings[5],
            setSelectedRating: (rating: IRating) => set(() => ({ selectedRating: rating })),
        }),
        { name: "RatingStore" },
    ),
);

export const RatingFilter = () => {
    const { ratingValues, selectedRating, setSelectedRating } = RatingStore();
    return (
        <div className="flex w-1/6 flex-col gap-4 p-2">
            <p className="border-b border-gray-600 pb-2 font-oswald text-lg font-bold uppercase">User Rating</p>
            <div className="flex flex-col gap-2">
                {ratingValues &&
                    ratingValues.length > 0 &&
                    ratingValues.map((ratingValue: IRating) => (
                        <div
                            className={`w-1/4 cursor-pointer hover:underline  ${
                                ratingValue === selectedRating ? "text-rc-pink" : "hover:text-rc-pink"
                            }`}
                            key={ratingValue.name}
                            onClick={() => setSelectedRating(ratingValue)}
                        >
                            <span className={`flex items-center whitespace-nowrap`}>{ratingValue.name}</span>
                        </div>
                    ))}
            </div>
        </div>
    );
};
