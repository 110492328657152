import { ITmdbMovieDetails } from "@helper/search/models/tmdb/movie";
import { ITmdbTvShowDetails } from "@helper/search/models/tmdb/tv";
import { IReminderItem } from "@models/reminder";
import create from "zustand";
import { devtools } from "zustand/middleware";

interface ReminderStoreState {
    reminder: (ITmdbMovieDetails | ITmdbTvShowDetails)[];
    toDayItems: IReminderItem[];
    setDayItems: (toDayItems: IReminderItem[]) => void;
    setReminder: (reminder: (ITmdbMovieDetails | ITmdbTvShowDetails)[]) => void;
}

const ReminderStore = create<ReminderStoreState>(
    devtools(
        (set) => ({
            reminder: [],
            toDayItems: undefined,
            setDayItems: (toDayItems: IReminderItem[]) => set(() => ({ toDayItems })),
            setReminder: (reminder: (ITmdbMovieDetails | ITmdbTvShowDetails)[]) => set(() => ({ reminder })),
        }),
        { name: "ReminderStore" },
    ),
);

export default ReminderStore;
