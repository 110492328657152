import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";

export interface Props {
    title: string;
    onClick?: (e?: any) => void;
    disabled?: boolean;
    loading?: boolean;
}

const SubmitButton: FC<Props> = ({ title, onClick, disabled, loading }: Props) => (
    <button
        disabled={disabled}
        type="submit"
        className="gradient-button relative w-full disabled:cursor-not-allowed disabled:opacity-50 "
        onClick={onClick}
    >
        {loading ? <FontAwesomeIcon icon={faSpinner} className="ml-1 animate-spin" /> : title}
    </button>
);
export default SubmitButton;
