import { CheckIcon } from "@heroicons/react/outline";
import React, { FC } from "react";

export interface Props {
    currentProgressPoint: number;
    bulletNumber: number;
    onClick: () => void;
    blocked?: boolean;
}

const ProgressBullet: FC<Props> = ({ currentProgressPoint, bulletNumber, onClick, blocked = false }: Props) => (
    <div
        className={`h-6 w-6 self-center md:h-10 md:w-10 ${currentProgressPoint >= bulletNumber ? `bg-rc-pink` : `bg-rc-gray-light`} ${
            currentProgressPoint > bulletNumber ? `opacity-50` : `opacity-100`
        } rounded-full ${blocked ? "" : "cursor-pointer"}`}
        onClick={onClick}
    >
        {currentProgressPoint > bulletNumber && <CheckIcon className="h-6 w-6 self-center text-white md:h-10 md:w-10" />}
    </div>
);
export default ProgressBullet;
