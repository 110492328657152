import { IPlayerItem } from "@models/player/player-item";
import { IPlayerList } from "@models/player/player-list";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";

interface PlayerStoreState {
    queue: IPlayerItem[];
    currentPlayerItem: IPlayerItem | undefined;
    currentPlayerList: IPlayerList | undefined;
    history: IPlayerItem[];
    shuffleList: IPlayerList;
    setShuffleList: (shuffleList: IPlayerList) => void;
    setHistory: (history: IPlayerItem[]) => void;
    setCurrentPlayerList: (currentPlayerList: IPlayerList) => void;
    setCurrentPlayerItem: (currentPlayerItem: IPlayerItem) => void;
    setQueue: (queue: IPlayerItem[]) => void;
}
const PlayerStore = create<PlayerStoreState>(
    devtools(
        persist(
            (set) => ({
                queue: [],
                currentPlayerItem: undefined,
                currentPlayerList: undefined,
                history: [],
                shuffleList: undefined,
                setShuffleList: (shuffleList: IPlayerList) => set(() => ({ shuffleList })),
                setHistory: (history: IPlayerItem[]) => set(() => ({ history })),
                setCurrentPlayerList: (currentPlayerList: IPlayerList) => set(() => ({ currentPlayerList })),
                setCurrentPlayerItem: (currentPlayerItem: IPlayerItem) => set(() => ({ currentPlayerItem })),
                setQueue: (queue: IPlayerItem[]) => set(() => ({ queue })),
            }),
            {
                name: "PlayerStore",
            },
        ),
        { name: "PlayerStore" },
    ),
);

export default PlayerStore;
