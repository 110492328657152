import { CollectionIcon } from "@heroicons/react/solid";
import { IPlaylist } from "@models/playlist/playlist";
import _ from "lodash";
import React, { FC } from "react";

export interface ICoverBuilder {
    playlist: IPlaylist;
    className?: string;
}

const CoverBuilder: FC<ICoverBuilder> = ({ playlist, className }: ICoverBuilder) => {
    if (playlist.items.length === 0)
        return (
            <div className={`grid grid-cols-2 ${className || ""}`}>
                <div className="col-span-2 row-span-2 h-full">
                    <CollectionIcon className="h-full w-full text-white" />
                </div>
            </div>
        );

    return (
        <div className={`grid grid-cols-2 ${className || ""}`}>
            {playlist.items.length < 3 ? (
                <img className="col-span-2 row-span-2" src={playlist.items[0].image_url} alt={`cover-${0}`} />
            ) : (
                _.uniq(playlist.items.map((p) => p.image_url))
                    ?.slice(0, 4)
                    .map((p, i) => <img src={p} key={p} alt={`cover-${i}`} />)
            )}
        </div>
    );
};

export default CoverBuilder;
