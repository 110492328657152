import DropdownRounded from "@components/dropdowns/dropdown-rounded";
import { Controller } from "react-hook-form";

const SelectField = ({ name, control, label, options, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div className="mb-4">
                    {label && (
                        <label htmlFor={name} className="mb-2 block text-sm font-bold text-white">
                            {label}
                        </label>
                    )}
                    <DropdownRounded
                        {...field}
                        {...props}
                        className="h-10 w-full rounded-md border border-rc-gray-dark px-2 text-sm text-white focus:outline-none dark:text-black"
                        options={options}
                    />
                    {error && <span className="text-sm text-red-500">{error.message}</span>}
                </div>
            )}
        />
    );
};

export default SelectField;
