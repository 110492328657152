import Networks from "@helper/networks";
import { EMultimediaFilter } from "models/multimedia-filter";
import { useTranslation } from "next-i18next";
import { Dispatch, SetStateAction, useState } from "react";

export type PopularFilterType = "nothing" | "hot" | "liked" | "recent";
export interface IFilterSettings {
    name: string;
    value: string;
    checked: boolean;
}

export interface IUseFilterContext {
    selectedMultimediaFilter: EMultimediaFilter;
    setSelectedMultimediaFilter: Dispatch<SetStateAction<EMultimediaFilter>>;
    search: {
        searchString: string;
        setSearchString: Dispatch<SetStateAction<string>>;
    };
    selectedPopularFilter: PopularFilterType;
    setSelectedPopularFilter: Dispatch<SetStateAction<PopularFilterType>>;
    postSettings: IFilterSettings[];
    postNetworkSettings: IFilterSettings[];
    changePostSettings: (index: number, filterValue: boolean) => void;
    changePostNetworkSettings: (index: number, filterValue: boolean) => void;
}

const useFilterContext = (initialState = EMultimediaFilter.all): IUseFilterContext => {
    const { t } = useTranslation();
    const [postNetworkSettings, setPostNetworkSettings] = useState<IFilterSettings[]>([
        {
            name: Networks.realclixx.name,
            value: Networks.realclixx.name,
            checked: true,
        },
        {
            name: Networks.twitter.name,
            value: Networks.twitter.name,
            checked: true,
        },
        {
            name: Networks.youTube.name,
            value: Networks.youTube.name,
            checked: true,
        },
        {
            name: Networks.pinterest.name,
            value: Networks.pinterest.name,
            checked: true,
        },
        {
            name: Networks.tiktok.name,
            value: Networks.tiktok.name,
            checked: true,
        },
        {
            name: Networks.instagrambasic.name,
            value: Networks.instagrambasic.name,
            checked: true,
        },
        {
            name: Networks.facebook.name,
            value: Networks.facebook.name,
            checked: true,
        },
        {
            name: "Spotify",
            value: "Spotify",
            checked: true,
        },
    ]);
    const [postSettings, setPostSetting] = useState<IFilterSettings[]>([
        {
            name: t("generic.text"),
            value: "Text",
            checked: true,
        },
        {
            name: t("generic.images"),
            value: "image",
            checked: true,
        },
        {
            name: t("generic.audio"),
            value: "audio",
            checked: true,
        },
        {
            name: t("generic.video"),
            value: "video",
            checked: true,
        },
    ]);

    const [selectedMultimediaFilter, setSelectedMultimediaFilter] = useState<EMultimediaFilter>(initialState);

    const [searchString, setSearchString] = useState<string>("");

    const [selectedPopularFilter, setSelectedPopularFilter] = useState<PopularFilterType>("nothing");

    const changePostSettings = (index: number, filterValue: boolean) => {
        const temp = [...postSettings];
        temp[index].checked = filterValue;
        setPostSetting(temp);
    };

    const changePostNetworkSettings = (index: number, filterValue: boolean) => {
        const temp = [...postNetworkSettings];
        temp[index].checked = filterValue;
        setPostNetworkSettings(temp);
    };

    return {
        selectedMultimediaFilter,
        setSelectedMultimediaFilter,
        search: { searchString, setSearchString },
        selectedPopularFilter,
        setSelectedPopularFilter,
        postNetworkSettings,
        postSettings,
        changePostSettings,
        changePostNetworkSettings,
    };
};

export default useFilterContext;
