import { updateVirtualTours } from "@helper/auth/auth-api-helper";
import useVirtualTour from "@helper/custom-hooks/use-virtual-tour";
import { IVirtualTourStep } from "constants/virtual-tour";
import React, { useEffect } from "react";
import { TooltipRenderProps } from "react-joyride";

interface ICustomTooltipRenderProps extends Omit<TooltipRenderProps, "step"> {
    step: IVirtualTourStep;
}

const VirtualTourTooltip = ({ index, step, size, tooltipProps, isLastStep }: ICustomTooltipRenderProps) => {
    const { onFinish, onNextStep, tourKey } = useVirtualTour();

    // keep watched step in localstorage
    useEffect(() => {
        const stepId = `${tourKey}_${step.target.replace("#", "")}`;
        localStorage.setItem(stepId, "true");
        updateVirtualTours(tourKey, { [stepId]: true });
    }, [tourKey]);

    return (
        <div className="bg-stone-800 flex w-96 flex-col gap-6 rounded-md px-5 py-4" {...tooltipProps}>
            <div className="flex w-full items-center justify-between">
                <p className="text-regular font-bold text-rc-gray-white">{step.title}</p>
                <p className="text-sm text-white">{`${index + 1} of ${size}`}</p>
            </div>

            <div className="flex flex-col gap-4">
                {step.content}
                {step.contentList?.map(({ type, value }, contentIndex) =>
                    type === "image" ? (
                        <img key={contentIndex} className="w-full rounded-lg" src={value} alt="" />
                    ) : (
                        <p key={contentIndex} className="text-sm leading-relaxed text-rc-gray-white">
                            {value}
                        </p>
                    ),
                )}
            </div>

            <div className="flex justify-between">
                <button className="rounded-md px-4 py-1 text-white" onClick={onFinish}>
                    Skip
                </button>
                <button className="rounded-md bg-gradient-to-r from-rc-pink to-rc-blue px-8 py-1" onClick={onNextStep}>
                    {isLastStep ? "Done" : "Next"}
                </button>
            </div>
        </div>
    );
};

export default VirtualTourTooltip;
