import { getLangParam } from "@helper/get-lang-param";
import { NewsResponse, NewsSimpleItem } from "@models/news/news";
import axios from "axios";

export const apiClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND,
    withCredentials: true,
});

apiClient.interceptors.response.use(null, (error) => {
    // Check if error from AbortController
    if (error.message === "canceled") {
        return;
    }

    return Promise.reject(error);
});

export const getTrendingNews = async (limit: number = 4): Promise<NewsSimpleItem[]> => {
    const res = await apiClient.get<NewsSimpleItem[]>(`/news/trends?${getLangParam()}&limit=${limit}`);
    return res.data;
};

export const getNewsByQuery = async (query: string, limit: number = 4, page: number = 1): Promise<NewsResponse> => {
    const res = await apiClient.get<NewsResponse>(`/news?${getLangParam()}&keywords=${encodeURI(query)}&limit=${limit}&page=${page}`);
    return res.data;
};
