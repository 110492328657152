import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nextTrack } from "@helper/player/player-helper";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { addItemToQueue } from "@helper/player/queue-handler";
import { youtubeInfosItemsToPlayerItems } from "@helper/player/youtube-infos-items-to-player-items";
import { PlayIcon } from "@heroicons/react/solid";
import { Post } from "@models/post";
import Link from "next/link";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MediumVideoPlayer from "./medium-video-player";

export interface ILargeVideoPlayer {
    youtubeItem: Post | undefined;
    showDetails?: boolean;
}

const LargeVideoPlayer: FC<ILargeVideoPlayer> = ({ youtubeItem, showDetails = false }: ILargeVideoPlayer) => {
    const [localTimeout, setLocalTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
    const { t } = useTranslation();
    const playerItem = youtubeInfosItemsToPlayerItems([youtubeItem])[0];

    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const setIsLargeVideoPlayer = PlayerSettingsStore((state) => state.setIsLargeVideoPlayer);
    const setCurrentPlayerItem = PlayerStore((state) => state.setCurrentPlayerItem);

    useEffect(() => {
        return () => {
            if (localTimeout) clearTimeout(localTimeout);
            setIsLargeVideoPlayer(false);
        };
    }, []);

    useEffect(() => {
        if (youtubeItem?.id === currentPlayerItem?.resource_id) setIsLargeVideoPlayer(true);
        else setIsLargeVideoPlayer(false);
    }, [youtubeItem?.id, currentPlayerItem]);

    const [showCheckMark, setShowCheckMark] = useState<boolean>(false);
    const handleAddItemToQueue = () => {
        setShowCheckMark(true);
        addItemToQueue(playerItem);
        setLocalTimeout(setTimeout(() => setShowCheckMark(false), 1500));
    };

    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);

    return (
        <>
            {youtubeItem?.id !== currentPlayerItem?.resource_id ? (
                <div className="group relative w-full">
                    <img alt="preview-image" src={youtubeItem.postContent?.entities?.[0]?.previewUrl} className="w-full" />
                    <div className="absolute bottom-0 left-0 z-30 hidden h-full w-full flex-col justify-center space-y-2 bg-rc-black text-white opacity-90 group-hover:flex">
                        <PlayIcon
                            className=" mx-auto h-2/6 w-2/6 cursor-pointer hover:text-rc-pink"
                            onClick={() => {
                                setCurrentPlayerItem({
                                    ...playerItem,
                                    media_type: "video",
                                });
                                setIsPlaying(true);
                            }}
                        />
                        {currentPlayerItem?.resource_id !== youtubeItem.id && (
                            <>
                                <div
                                    onClick={handleAddItemToQueue}
                                    className=" mx-auto cursor-pointer font-oswald text-3xl font-bold uppercase  hover:text-rc-pink"
                                >
                                    {t("generic.to_queue")}
                                    {showCheckMark && <FontAwesomeIcon icon={faCheck} className="ml-1" />}
                                </div>
                                {showDetails && (
                                    <Link href={`/video/youtube/${youtubeItem.id}`}>
                                        <a className="mx-auto cursor-pointer font-oswald text-3xl font-bold uppercase  hover:text-rc-pink">
                                            {t("generic.details")}
                                        </a>
                                    </Link>
                                )}
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <MediumVideoPlayer
                    isLarge
                    duration_ms={playerItem.duration_ms}
                    showIcons={false}
                    link={`/video/youtube/${youtubeItem && youtubeItem.id}`}
                    videoUrl={`https://www.youtube.com/watch?v=${youtubeItem && youtubeItem.id}`}
                    onEnded={() => {
                        nextTrack();
                    }}
                />
            )}
        </>
    );
};
export default LargeVideoPlayer;
