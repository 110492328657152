/* eslint-disable no-underscore-dangle */
import AppleStore from "@helper/apple/apple-store";
import Networks from "@helper/networks";
import { nextTrack } from "@helper/player/player-helper";
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { msToTime } from "@helper/time-helper";
import { FC, useEffect, useState } from "react";

const ApplePlayer: FC = () => {
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const isPlaying = PlayerSettingsStore((state) => state.isPlaying);

    const isApple = currentPlayerItem?.platform === Networks.apple.internalName;
    const setPlayerTime = PlayerPersistSettingsStore((state) => state.setPlayerTime);
    const seek = PlayerPersistSettingsStore((state) => state.seek);

    const [currentApple, setCurrentApple] = useState<any[]>([""]);
    const mediaContext = AppleStore((state) => state.mediaContext);

    const stopApple = async () => {
        if (mediaContext?.isAuthorized) {
            await mediaContext.pause();
        }
    };

    const doAsync = async () => {
        if (mediaContext?.isAuthorized) {
            if (currentApple[0] !== currentPlayerItem.resource_id) {
                const data = await mediaContext.setQueue({
                    songs: [currentPlayerItem.resource_id],
                    items: [],
                });
                setCurrentApple(data._itemIDs);
            }
            await mediaContext.play();
        }
    };

    useEffect(() => {
        if (isApple && mediaContext?.isAuthorized && currentPlayerItem) {
            mediaContext.seekToTime((currentPlayerItem.duration_ms * seek) / 1000);
        }
    }, [seek]);

    const volume = PlayerPersistSettingsStore((state) => state.volume);
    useEffect(() => {
        if (isApple && mediaContext?.isAuthorized && currentPlayerItem) {
            mediaContext.volume = volume;
        }
    }, [volume]);

    useEffect(() => {
        let interval;
        if (isPlaying && mediaContext?.isAuthorized && isApple) {
            interval = setInterval(() => {
                const ms = mediaContext.capabilities._mediaSession.controller._mediaItemPlayback.currentPlaybackTime * 1000;
                setPlayerTime(ms);

                // Play next Track
                if (msToTime(ms) >= msToTime(currentPlayerItem?.duration_ms)) {
                    stopApple()
                        .then(() => {
                            nextTrack();
                            setPlayerTime(0);
                        })
                        .catch(console.error);
                }
            }, 1000);
        } else clearInterval(interval);
        return () => clearInterval(interval);
    }, [isPlaying]);

    useEffect(() => {
        if (!isApple) {
            stopApple();
            return;
        }
        if (!isPlaying) {
            stopApple();
            return;
        }
        if (isPlaying) {
            doAsync();
        }
    }, [currentPlayerItem, isPlaying]);

    return <></>;
};

export default ApplePlayer;
