import { AgeStore } from "@helper/filter/age-filter";
import { LengthFilterStore } from "@helper/filter/length-filter";
import { RatingStore } from "@helper/filter/rating-filter";
import { ReleaseDateStore } from "@helper/filter/release-filter";
import { SortFilterStore } from "@helper/filter/sort-filter";
import { getIso649 } from "@helper/getIso639";
import { ITmdbMovie, ITmdbMovieDetails, ITmdbMovieResponse } from "@helper/search/models/tmdb/movie";
import { ITmdbPerson } from "@helper/search/models/tmdb/person";
import { ITmdbiscoverResponse, ITmdbTvResponse, ITmdbTvShowDetails } from "@helper/search/models/tmdb/tv";
import { ICreditsWork } from "@models/movie-series/credits";
import { IMediaTypes } from "@models/movie-series/movie-series";
import { IMovieTeaserResponse } from "@models/movie-series/movie-teaser";
import { IWatchProviders } from "@models/movie-series/watch-providers";
import axios from "axios";
import moment from "moment";
import { i18n } from "next-i18next";

import StreamingNetworkStore from "./streaming-network-store";

export const tmdbClient = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_BACKEND}/tmdb`,
    withCredentials: true,
});

export async function getMovieDetails(movieId: string | number): Promise<ITmdbMovieDetails> {
    const response = await tmdbClient.get(`/details/movie/${movieId}`, {
        params: { language: i18n.language },
    });
    return response.data;
}

export async function getSeriesDetails(tvId: string | number): Promise<ITmdbTvShowDetails> {
    const response = await tmdbClient.get(`details/tv/${tvId}`, {
        params: { language: i18n.language },
    });

    return response.data;
}

export async function loadTmdbMovies(page, upcoming, genreName?): Promise<ITmdbiscoverResponse> {
    const { selectedReleaseDate } = ReleaseDateStore.getState();

    const { selectedAge } = AgeStore.getState();
    const { selectedRating } = RatingStore.getState();

    const { selectedSortFilter } = SortFilterStore.getState();

    const { selectedLength } = LengthFilterStore.getState();

    const { selectedStreamingNetworks } = StreamingNetworkStore.getState();
    let params;
    if (upcoming) {
        params = {
            page,

            sort_by: "popularity.desc",
            release_date_gte: moment().format("YYYY-MM-DD"),
            release_date_lte: moment().add(60, "days").format("YYYY-MM-DD"),
        };
    } else {
        params = {
            page,
            sort_by: selectedSortFilter.valueTmbdMovies,
            "vote_count.gte": 50,
            release_date_gte: selectedReleaseDate?.value?.format("YYYY-MM-DD"),
            release_date_lte: moment().format("YYYY-MM-DD"),
        };
    }

    if (genreName) {
        if (genreName?.length > 1) {
            params = {
                ...params,
                with_genres: genreName.map((genre) => genre.join("|")).join("|"),
            };
        } else if (genreName?.length === 1) {
            params = {
                ...params,
                with_genres: genreName[0],
            };
        }
    }
    if (selectedStreamingNetworks.length > 0) {
        params.with_watch_providers = selectedStreamingNetworks.map((network) => network.id).join("|");
    }

    if (selectedAge.value !== "-1") {
        params = {
            ...params,
            certification_country: "DE",
            certification: selectedAge.value,
        };
    }
    if (selectedRating.value !== "-1" && !upcoming) {
        params = {
            ...params,
            vote_average_gte: Number.parseInt(selectedRating.value, 10) / 10,
            vote_count_gte: 10,
        };
    }
    if (selectedLength.id !== -1) {
        params = {
            ...params,
            with_runtime_gte: selectedLength.valueGte,
            with_runtime_lte: selectedLength.valueLte,
        };
    } else {
        params = {
            ...params,
            with_runtime_gte: 30,
            with_runtime_lte: 1000,
        };
    }
    const response = await tmdbClient.get(`/discover/movie`, {
        params,
    });
    return response.data;
}

export async function loadTmdbSeries(page, upcoming: boolean, genreName?): Promise<ITmdbiscoverResponse> {
    const { selectedReleaseDate } = ReleaseDateStore.getState();
    const { selectedStreamingNetworks } = StreamingNetworkStore.getState();

    const { selectedRating } = RatingStore.getState();
    const { selectedSortFilter } = SortFilterStore.getState();

    let params: any = {
        page,
        watch_region: "DE",
        sort_by: selectedSortFilter.valueTmbdSeries,
    };

    if (genreName) {
        if (genreName?.length > 1) {
            params = {
                ...params,
                with_genres: genreName.map((genre) => genre.join("|")).join("|"),
            };
        } else if (genreName?.length === 1) {
            params = {
                ...params,
                with_genres: genreName[0],
            };
        }
    }
    if (selectedStreamingNetworks.length > 0) {
        params.with_watch_providers = selectedStreamingNetworks.map((network) => network.id).join("|");
    }

    if (upcoming) {
        params = {
            ...params,
            air_date_gte: moment().add("1", "week").format("YYYY-MM-DD"),
        };
    } else if (selectedReleaseDate && selectedReleaseDate.value) {
        params = {
            ...params,
            first_air_date_gte: selectedReleaseDate.value.format("YYYY-MM-DD"),
            first_air_date_lte: moment().format("YYYY-MM-DD"),
        };
    }
    if (selectedRating.value !== "-1") {
        params = {
            ...params,
            vote_average_gte: Number.parseInt(selectedRating.value, 10) / 10,
            vote_count_gte: 100,
        };
    }
    const data = await tmdbClient.get(`/discover/tv`, {
        params,
    });
    const { selectedAge } = AgeStore.getState();

    const series = data.data.results;
    const seriesDetailsRes = await Promise.allSettled(series.map(async (t) => getSeriesDetails(t.id)));
    const seriesDetails = seriesDetailsRes.map((r) => r.status === "fulfilled" && r.value).filter(Boolean);
    const filteredSeries = seriesDetails.filter((t) =>
        selectedAge.value !== "-1" ? Number(t.release_infos?.rating?.replace(/\D/g, "")) >= Number(selectedAge.value) : true,
    );
    return {
        // TODO: find a better way
        // clean up already filtered total number
        total_results: data.data.total_results - series.length + filteredSeries.length,
        results: filteredSeries,
    };
}

export async function searchMovie(query: string, page = 1): Promise<ITmdbMovieResponse> {
    const data = await tmdbClient.get(`/search/movie`, {
        params: {
            query,
            page,
            include_adult: false,
            language: getIso649(),
        },
    });
    return data.data;
}

export async function searchTv(query: string, page = 1): Promise<ITmdbTvResponse> {
    const data = await tmdbClient.get(`/search/tv`, {
        params: {
            query,
            page,
            include_adult: false,
            language: getIso649(),
        },
    });
    return data.data;
}

export async function getPersonById(id: number | string): Promise<ITmdbPerson> {
    const data = await tmdbClient.get(`/person/${id}`, {
        params: { language: getIso649() },
    });

    return data.data;
}

export async function getPersonWork(id: number | string): Promise<ICreditsWork> {
    const data = await tmdbClient.get(`/person/${id}/combined_credits`);
    return data.data;
}

export async function getKnowForImdb(id: number | string): Promise<ITmdbMovie[]> {
    const data = await tmdbClient.get(`/getKnownAs/${id}`, {
        params: { language: i18n.language },
    });
    return data.data;
}

export async function getAllFilmsForImdb(id: number | string): Promise<Array<ITmdbTvShowDetails | ITmdbMovie>> {
    const data = await tmdbClient.get(`/findFilmographyByImdb/${id}`, {
        params: { language: i18n.language },
    });
    return data.data;
}

const rapidProviderToTmdbProviderMap = {
    apple: 2,
    prime: 10,
};
export async function getWatchProviders(video_id: number, type: IMediaTypes): Promise<IWatchProviders | undefined> {
    const [rapidData, tmdbData] = await Promise.allSettled([
        axios.get("https://streaming-availability.p.rapidapi.com/get", {
            params: {
                output_language: "en",
                tmdb_id: `${type}/${video_id}`,
            },
            headers: {
                "X-RapidAPI-Key": "81a5d5e124mshf524a4efec43f47p193212jsn45aeb1a1f8bf",
                "X-RapidAPI-Host": "streaming-availability.p.rapidapi.com",
            },
        }),
        tmdbClient.get(`/details/${type}/${video_id}/watch/providers`, {
            params: { language: getIso649() },
        }),
    ]);
    if (tmdbData.status === "fulfilled" && tmdbData.value.data?.results?.DE) {
        const tmdbProviders = tmdbData.value.data.results.DE;
        const rapidProviders = rapidData.status === "fulfilled" && (rapidData?.value?.data?.result?.streamingInfo?.de ?? []);
        return {
            ...tmdbProviders,
            buy: tmdbProviders.buy?.map((p) => ({
                ...p,
                link:
                    rapidProviders.find(
                        (r) =>
                            r.streamingType === "buy" &&
                            (p.provider_name?.toLowerCase().includes(r.service?.toLowerCase()) ||
                                rapidProviderToTmdbProviderMap[r.service] === p.provider_id),
                    )?.link || tmdbProviders.link,
            })),
            rent: tmdbProviders.rent?.map((p) => ({
                ...p,
                link:
                    rapidProviders.find(
                        (r) =>
                            r.streamingType === "rent" &&
                            (p.provider_name?.toLowerCase().includes(r.service?.toLowerCase()) ||
                                rapidProviderToTmdbProviderMap[r.service] === p.provider_id),
                    )?.link || tmdbProviders.link,
            })),
            flatrate: tmdbProviders.flatrate?.map((p) => ({
                ...p,
                link:
                    rapidProviders.find(
                        (r) =>
                            r.streamingType === "flatrate" &&
                            (p.provider_name?.toLowerCase().includes(r.service?.toLowerCase()) ||
                                rapidProviderToTmdbProviderMap[r.service] === p.provider_id),
                    )?.link || tmdbProviders.link,
            })),
        };
        // if (data) {
        //   const providers = data.data?.result?.streamingInfo?.de
        //   const mapData = (p) => ({})
        //   return {
        //     buy: providers.filter((p) => p.streamingType === 'buy'),
        //     rent: providers.filter((p) => p.streamingType === 'rent'),
        //     flatrate: providers.filter((p) => p.streamingType === 'flatrate'),
        //   }
        // }
    }

    return undefined;
}
export async function getTeaser(video_id: number, type: IMediaTypes): Promise<IMovieTeaserResponse | undefined> {
    const result = await tmdbClient.get(`/details/${type}/${video_id}/videos`, {
        params: { language: getIso649() },
    });
    return result.data;
}
export async function getSimilar(id: number, type: string, page: number): Promise<any | undefined> {
    const result = await tmdbClient.get(`/details/${type}/${id}/similar`, {
        params: { page },
    });
    return result.data;
}
