import { useState } from "react";

interface IUseHover {
    isHovered: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

const useHover = (initialState = false): IUseHover => {
    const [isHovered, setIsHovered] = useState<boolean>(initialState);

    const onMouseEnter = (): void => {
        setIsHovered(true);
    };

    const onMouseLeave = (): void => {
        setIsHovered(false);
    };

    return { isHovered, onMouseEnter, onMouseLeave };
};

export default useHover;
