import useOnClickOutside from "@helper/custom-hooks/use-on-click-outside";
import useOpenClose from "@helper/custom-hooks/use-open-close";
import useVisual from "@helper/custom-hooks/use-visual";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import React, { FC, useRef } from "react";
import { multimediaIcon, timelineIcon } from "styles/icons";

const NavbarMobile: FC = () => {
    const { navbarOpenClose, notificationOpenClose } = useVisual();
    const router = useRouter();
    const openClose = useOpenClose(false);
    const ref = useRef(null);

    const { toggleMenuHome } = useVisual();

    const handleClickOutside = () => {
        navbarOpenClose?.handleClose();
        notificationOpenClose?.handleClose();
    };

    useOnClickOutside(ref, handleClickOutside);

    return (
        <>
            <div className={`fixed bottom-0 z-40 h-14 w-full border-t border-rc-pink bg-rc-dark`}>
                <nav className="grid h-full w-full grid-cols-5 items-center justify-items-center">
                    <img
                        src="/signet.svg"
                        alt="Realclixx"
                        className="h-8"
                        onClick={() => {
                            router.push("/");
                            toggleMenuHome.setIndex(0);
                        }}
                    />

                    <button
                        onClick={() => {
                            router.push("/");
                            toggleMenuHome.setIndex(1);
                        }}
                    >
                        <>{timelineIcon("#fff", "h-8 w-8 self-start")}</>
                    </button>

                    <div onClick={openClose.handleOpen}>
                        <PlusCircleIcon className="h-12 w-12 text-white " />
                    </div>

                    <button
                        onClick={() => {
                            router.push("/");
                            toggleMenuHome.setIndex(2);
                        }}
                    >
                        <>{multimediaIcon("#fff", "h-8 w-8 self-start")}</>
                    </button>
                </nav>
            </div>
        </>
    );
};
export default NavbarMobile;
