import { getCookie } from "@helper/cookie-helper";
import { errorHelper } from "@helper/toasts/error-helper";
import { EProviderIds } from "@models/provider-ids";
import * as Sentry from "@sentry/nextjs";
import axios from "axios";

// import router from 'next/router'
import AuthStore from "./auth-store";

export const apiClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND,
    withCredentials: true,
});

apiClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export const getUserData = async () => {
    const result = await apiClient.get(`/user/data`);

    return result.data;
};

export const updateProfilePicture = async (profileImageURL: string, isUpdateUserAvatar: boolean = true, companyId: string = "") => {
    const { setUser, user } = AuthStore.getState();
    const image = await fetch(profileImageURL);
    const blob = await image.blob();
    const formData = new FormData();
    formData.append("avatar", blob);
    let url = `/user/profile-picture`;
    if (!isUpdateUserAvatar && companyId) {
        url = `/companies/profile-picture`;
        formData.append("companyId", companyId);
        setUser({
            ...user,
            companies: user.companies.map((company) => (company._id === companyId ? { ...company, avatar: URL.createObjectURL(blob) } : company)),
        });
    } else {
        setUser({ ...user, profile_picture: URL.createObjectURL(blob) });
    }
    return apiClient.post(url, formData);
};

export const updateCoverImage = async (coverImageURL: string, isUpdateUserCover: boolean = true, companyId: string = "") => {
    const { setUser, user } = AuthStore.getState();

    const image = await fetch(coverImageURL);
    const blob = await image.blob();
    if (!companyId) {
        setUser({ ...user, profile_cover_picture: URL.createObjectURL(blob) });
    }
    const formData = new FormData();
    formData.append("cover", blob);
    let url = `/user/cover-picture`;
    if (!isUpdateUserCover && companyId) {
        url = `/companies/profile-cover-picture`;
        formData.append("companyId", companyId);
        setUser({
            ...user,
            companies: user.companies.map((company) => (company._id === companyId ? { ...company, coverImage: URL.createObjectURL(blob) } : company)),
        });
    }
    return apiClient.post(url, formData);
};

export const updateProfileOnBoarding = async (onboarding: boolean) => {
    try {
        const { user, setUser } = AuthStore.getState();
        const { username, email } = user;

        const result = await apiClient.patch("/user/update", { username, email, onboarding });
        setUser(result.data);

        return result;
    } catch (error) {
        console.error(error);
    }
};

export const handleToggleFollow = async (companyId: string, state: boolean, activeCompanySlug: string) => {
    const url = state === true ? `/user/follow` : `/user/unfollow`;
    try {
        const res = await apiClient.put(url, {
            following: companyId,
            activeCompanySlug,
        });

        console.log(`Toggling follow state to ${state}`, res);
        return true;
    } catch (error) {
        console.error("Error toggling follow state", error);
        return false;
    }
};

export const updateVirtualTours = async (tourKey: string, data: { [key: string]: boolean }) => {
    await apiClient.patch(`/user/tours/${tourKey}`, data);
};

export const handleLoginSubmit = async (event) => {
    event.preventDefault();

    const data = await apiClient.post(`/user/login`, {
        identification: event.target.username.value,
        password: event.target.password.value,
    });
    const { setUser } = AuthStore.getState();

    if (data.status === 200) {
        const user = await getUserData();
        setUser(user);
        return true;
    }
    return false;
};

export const handleRegister = async (userValue: any) => {
    const { setUser } = AuthStore.getState();
    const result = await apiClient.post(`/user/register`, userValue);
    setUser(result.data);
    return true;
};

export const handleValidateEmail = async (email: string) => {
    const result = await apiClient.post("/user/validate-email", { email });
    return result.data;
};

export const handleValidateUsername = async (username: string) => {
    const result = await apiClient.post("/user/validate-username", { username });
    return result.data;
};

export const handleLogOut = async () => {
    try {
        const { setUser } = AuthStore.getState();

        await apiClient.get(`/user/logout`);
        setUser(undefined);
        return true;
    } catch (error) {
        console.error("Error logging out", error);
    }
};

export const connectNetwork = async (callback?: VoidFunction) => {
    const cookieNetwork = JSON.parse(<string>getCookie("network"));
    const { setUser, user } = AuthStore.getState();
    const isBusinessProfile = user.profile_type === "business";
    const companySlug = user.company_slug;
    if (cookieNetwork) {
        try {
            if (isBusinessProfile) {
                await apiClient.patch(`/companies/${companySlug}/provider`, cookieNetwork);
            } else {
                await apiClient.patch(`/user/provider`, cookieNetwork);
            }
            const user = await getUserData();
            setUser(user);
            callback?.();
            return user;
        } catch (error) {
            if (error.response?.status === 422) {
                errorHelper("Account is already used on this website");
            } else {
                errorHelper("Something went wrong");
            }
        }
    }
};

export const deleteNetwork = async (provider_id: EProviderIds) => {
    const { setUser, user } = AuthStore.getState();
    const isBusinessProfile = user.profile_type === "business";
    const companySlug = user.company_slug;
    try {
        if (isBusinessProfile) {
            await apiClient.delete(`/companies/${companySlug}/provider`, {
                data: { provider_id },
            });
        } else {
            await apiClient.delete(`/user/provider`, {
                data: { provider_id },
            });
        }

        const user = await getUserData();
        setUser(user);

        return true;
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        throw error;
        // You might want to handle the error here, e.g., by returning false or throwing an exception.
    }
};

export const refreshUserData = async () => {
    const { setUser } = AuthStore.getState();

    try {
        const user = await getUserData();
        setUser(user);

        return true;
    } catch (error) {
        Sentry.captureException(error);
        console.log(error);
        throw error;
        // You might want to handle the error here, e.g., by returning false or throwing an exception.
    }
};
