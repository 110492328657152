import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";

import LanguageSwitcher from "./language-switcher";

const Footer: FC = () => {
    const { t } = useTranslation();
    const { locale } = useRouter();
    return (
        <footer className="w-full bg-rc-dark text-white">
            <div className="h-0.5 w-full bg-gradient-to-r from-rc-pink to-rc-blue" />
            <div className="flex w-full justify-center py-2 text-xs">
                <LanguageSwitcher />
            </div>
            <div className="h-0.5 w-full bg-gradient-to-r from-rc-pink to-rc-blue" />
            <div className="mx-auto flex flex-col py-10 px-4 text-xs md:text-center">
                {/* TODO turn year into current year */}
                <span className="pb-4">© Copyright 2022 Realclixx GmbH</span>

                <div className="flex flex-col md:mx-auto md:flex-row md:space-x-6">
                    {/* TODO add translations + links */}

                    <Link href={locale === "de" ? `/nutzungsbedingungen` : `/termsandconditions`}>
                        <a className="capitalize">{t("generic.terms")}</a>
                    </Link>
                    {/* <Link href="/"> */}
                    {/*  <a className="capitalize">{t('generic.ad')}</a> */}
                    {/* </Link> */}
                    <Link href={locale === "de" ? `/datenschutz` : `/dataprotection`}>
                        <a className="capitalize">{`${t("generic.data_protection")} & ${t("generic.privacy")}`}</a>
                    </Link>
                    <Link href={locale === "de" ? `/datenschutz#cookies` : `/dataprotection#cookies`}>
                        <a className="capitalize">{t("generic.cookies")}</a>
                    </Link>
                    <Link href={locale === "de" ? `/impressum` : `/imprint`}>
                        <a className="capitalize">{t("generic.imprint")}</a>
                    </Link>
                    {/* <Link href="/"> */}
                    {/*  <a className="capitalize">{t('generic.help')}</a> */}
                    {/* </Link> */}
                </div>
            </div>
        </footer>
    );
};
export default Footer;
