import create from "zustand";
import { devtools } from "zustand/middleware";

interface PlayerPersistSettingsStoreState {
    queueListOpen: boolean;
    isLargeVideoPlayer: boolean;
    isPlaying: boolean;
    videoModal: boolean;
    videoMinimize: boolean;
    playerSlideIn: boolean;
    hasError: boolean;
    setPlayerSlideIn: (playerSlideIn: boolean) => void;
    setVideoMinimize: (videoMinimize: boolean) => void;
    setVideoModal: (videoModal: boolean) => void;
    setIsPlaying: (isPlaying: boolean) => void;
    setIsLargeVideoPlayer: (playerState: boolean) => void;
    setQueueListOpen: (queueListOpen: boolean) => void;
    setHasError: (hasError: boolean) => void;
}
const PlayerSettingsStore = create<PlayerPersistSettingsStoreState>(
    devtools(
        (set) => ({
            queueListOpen: false,
            isLargeVideoPlayer: false,
            isPlaying: false,
            videoModal: false,
            videoMinimize: false,
            playerSlideIn: false,
            hasError: false,
            setPlayerSlideIn: (playerSlideIn: boolean) => set(() => ({ playerSlideIn })),
            setVideoMinimize: (videoMinimize: boolean) => set(() => ({ videoMinimize })),
            setVideoModal: (videoModal: boolean) => set(() => ({ videoModal })),
            setIsPlaying: (isPlaying: boolean) => set(() => ({ isPlaying })),
            setIsLargeVideoPlayer: (isLargeVideoPlayer: boolean) => set(() => ({ isLargeVideoPlayer })),
            setQueueListOpen: (queueListOpen: boolean) => set(() => ({ queueListOpen })),
            setHasError: (hasError: boolean) => set(() => ({ hasError })),
        }),
        { name: "PlayerSettingsStore" },
    ),
);

export default PlayerSettingsStore;
