/* eslint-disable no-underscore-dangle */
import { faShuffle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayerStore from "@helper/player/player-store";
import { updatePlayingPosition } from "@helper/player/queue-handler";
import _ from "lodash";
import { FC, useEffect } from "react";

const PlayerShuffle: FC = () => {
    const currentPlayerList = PlayerStore((state) => state.currentPlayerList);
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const shuffleList = PlayerStore((state) => state.shuffleList);
    const setShuffleList = PlayerStore((state) => state.setShuffleList);

    useEffect(() => {
        setShuffleList(undefined);
    }, [currentPlayerList]);

    const shuffle = () => {
        if (!shuffleList)
            setShuffleList({
                ...currentPlayerList,
                tracks: _.shuffle(currentPlayerList.tracks.slice(currentPlayerList.playing_position + 1)),
            });
        else {
            const index = currentPlayerList.tracks.findIndex((f) => f.name === currentPlayerItem.name);
            updatePlayingPosition(index);
            setShuffleList(undefined);
        }
    };

    return (
        <>
            {currentPlayerList && (
                <FontAwesomeIcon
                    icon={faShuffle}
                    className={`hidden h-5 w-5 cursor-pointer self-center transition ${
                        !shuffleList ? "text-white" : "text-rc-pink"
                    }  hover:text-rc-pink lg:block`}
                    onClick={shuffle}
                />
            )}
        </>
    );
};

export default PlayerShuffle;
