import { IPlayerItem } from "@models/player/player-item";
import { Post, PostAuthor } from "@models/post";
import moment from "moment";

export const youtubeInfosItemsToPlayerItems = (youtubeItems: Post[] | undefined): IPlayerItem[] => {
    if (Array.isArray(youtubeItems)) {
        return youtubeItems.map((v) => ({
            platform: "Youtube",
            resource_id: typeof v?.id === "string" ? v?.id : "",
            name: v?.postContent?.text,
            uri: v?.postContent?.etag,
            media_type: "video",
            type: v?.postContent?.kind,
            duration_ms: moment.duration(v?.postContent?.entities?.[0]?.duration).asMilliseconds(),
            image_url: v?.postContent?.entities?.[0]?.previewUrl,
            artists: [{ name: (v?.user as PostAuthor)?.name, uri: (v?.user as PostAuthor)?.id }],
            is_list_item: false,
        }));
    }

    return [];
};
