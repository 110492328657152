/* eslint-disable no-fallthrough */
import AppleStore from "@helper/apple/apple-store";
import { lookForProvider } from "@helper/auth/provider-check";
import Networks from "@helper/networks";
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import PlayerStore from "@helper/player/player-store";
import { msToTime } from "@helper/time-helper";
import { IPlayerArtist } from "@models/player/player-item";
import { EProviderIds } from "@models/provider-ids";
import Link from "next/link";
import React, { FC, useEffect, useState } from "react";

const PlayerItemInfo: FC = () => {
    const spotifyLogin = lookForProvider(EProviderIds.spotify);

    const [appleLogIn, setAppleLogIn] = useState<boolean>(false);
    const mediaContext = AppleStore((state) => state.mediaContext);

    useEffect(() => {
        if (mediaContext) {
            const LoginStatus = mediaContext.isAuthorized;

            setAppleLogIn(LoginStatus);
        }
    }, [mediaContext]);

    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const playerTime = PlayerPersistSettingsStore((state) => state.playerTime);
    const link = (artist: IPlayerArtist) => {
        switch (currentPlayerItem?.platform) {
            case Networks.youTube.internalName:
                // broken after refactor youtube, need review
                return `/youtube/channel/${artist?.uri?.replace("https://www.youtube.com/channel/", "")}`;
            case Networks.spotify.name:
                if (artist.resource_id) return `/search/?query=${artist.name}&spotify=${artist.resource_id}`;

            default:
                return `/search/?query=${artist.name}`;
        }
    };

    const getPlayerTimeElement = () => {
        if (
            (!spotifyLogin && currentPlayerItem.platform === Networks.spotify.name) ||
            (!appleLogIn && currentPlayerItem.platform === Networks.apple.secondaryName)
        ) {
            return <>{msToTime(playerTime) || "0:00"} / 0:30</>;
        }

        return (
            <>
                {msToTime(playerTime) || "0:00"} / {msToTime(currentPlayerItem.duration_ms) || "0:00"}
            </>
        );
    };

    if (!currentPlayerItem) return <></>;

    return (
        <div className="text-white">
            <div title={currentPlayerItem.name} className="mr-4 w-64 overflow-hidden text-ellipsis whitespace-nowrap pt-3 font-bold dark:text-white">
                {currentPlayerItem.name}
            </div>
            <div className="text-xs text-rc-gray-light">
                {currentPlayerItem.artists?.map((a, i, element) => (
                    <Link key={link(a)} href={link(a)}>
                        <a className="hover:text-rc-pink">
                            {a.name}
                            {i !== element.length - 1 && ", "}
                        </a>
                    </Link>
                ))}
            </div>
            <div className="text-xs text-rc-gray-light">{getPlayerTimeElement()}</div>
        </div>
    );
};

export default PlayerItemInfo;
