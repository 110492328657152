import Button from "@components/buttons/button";
import InputField from "@components/input-field";
import useTextState from "@helper/custom-hooks/use-text-state";
import { createPlaylist } from "@helper/playlist/playlist-api-helper";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface ICreatePlaylist {
    handleClose: () => void;
}

const CreatePlaylist: FC<ICreatePlaylist> = ({ handleClose }: ICreatePlaylist) => {
    const playlistName = useTextState();
    const { t } = useTranslation();

    const handleSend = async () => {
        try {
            if (playlistName.text.trim().length > 0) {
                await createPlaylist(playlistName.text);
                handleClose();
            }
        } catch (e) {
            console.error("Fail to create playlist", e);
        }
    };

    const sendButtonIsActive = playlistName.text.trim().length > 0;

    return (
        <>
            <div>
                <div className="my-6">
                    <InputField
                        name="name"
                        label="Name"
                        required
                        type="text"
                        value={playlistName.text}
                        onChange={(e) => playlistName.setText(e.target.value)}
                    />
                </div>
            </div>
            <div className="mt-6 flex flex-row flex-nowrap space-x-5">
                <Button title={t("generic.cancel")} onClick={handleClose} />
                <Button title={t("generic.send")} onClick={handleSend} disabled={!sendButtonIsActive} />
            </div>
        </>
    );
};

export default CreatePlaylist;
