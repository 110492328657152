import { useTranslation } from "next-i18next";
import React, { FC } from "react";
import { logoutIcon } from "styles/icons";

interface ILogOut {
    onClick: () => void;
}

const LogOut: FC<ILogOut> = ({ onClick }: ILogOut) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-row gap-3.5 py-4 tracking-wide text-black dark:text-white lg:mb-2 lg:py-0 lg:pb-1" onClick={onClick}>
            <div className="flex w-10 items-center justify-center">{logoutIcon("#fff", "w-6 h-6")}</div>

            <div className="flex w-full grow flex-row justify-between">{t("generic.logout")}</div>
        </div>
    );
};
export default LogOut;
