import ThreeDotMenu from "@components/three-dot-menu/three-dot-menu";
import ThreeDotMenuItem from "@components/three-dot-menu/three-dot-menu-item";
import PlayerStore from "@helper/player/player-store";
import PlaylistStore from "@helper/playlist/playlist-store";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const PlayerMenu: FC = () => {
    const currentPlayerItem = PlayerStore((state) => state.currentPlayerItem);
    const setItemToBeAddedToPlaylist = PlaylistStore((state) => state.setItemToBeAddedToPlaylist);
    const { t } = useTranslation();

    return (
        <div className="my-auto hidden lg:block">
            <ThreeDotMenu className="absolute bottom-0 right-0 z-50 ">
                <ThreeDotMenuItem title={t("playlist.add")} onClick={() => setItemToBeAddedToPlaylist(currentPlayerItem)} />
            </ThreeDotMenu>
        </div>
    );
};

export default PlayerMenu;
