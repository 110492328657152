export enum EProviderIds {
    youtube,
    google,
    facebook,
    instagram,
    spotify,
    twitter,
    twitch,
    linkedin,
    discord,
    apple,
    tiktok,
    pinterest,
}
export enum EProviderNames {
    youtube = "youtube",
    google = "google",
    facebook = "facebook",
    instagram = "instagram",
    spotify = "spotify",
    twitter = "twitter",
    twitch = "twitch",
    linkedin = "linkedin",
    discord = "discord",
    apple = "apple",
    tiktok = "tiktok",
    pinterest = "pinterest",
}

export const NETWORKS_PROVIDER_MAP = {
    youtube: "youTube",
    google: "google",
    facebook: "facebook",
    instagram: "instagrambasic",
    spotify: "spotify",
    twitter: "twitter",
    twitch: "twitch",
    linkedin: "linkedin",
    discord: "discord",
    apple: "apple",
    tiktok: "tiktok",
    pinterest: "pinterest",
};
