import { faAngleDown, faAngleUp, faExpand, faExpandAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlayerPersistSettingsStore from "@helper/player/player-persist-settings-store";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import Link from "next/link";
import React, { FC, useEffect, useState } from "react";

import VideoPlayer from "./video-player";

interface IMediumVideoPlayer {
    videoUrl: string;
    link?: string;
    showIcons?: boolean;
    isLarge?: boolean;
    onEnded: () => void;
    duration_ms?: number;
    controls?: boolean;
}

const MediumVideoPlayer: FC<IMediumVideoPlayer> = ({
    link,
    videoUrl,
    showIcons = true,
    isLarge = false,
    controls = true,
    onEnded,
    duration_ms,
}: IMediumVideoPlayer) => {
    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);
    const setHasError = PlayerSettingsStore((state) => state.setHasError);
    const isPlaying = PlayerSettingsStore((state) => state.isPlaying);
    const hasError = PlayerSettingsStore((state) => state.hasError);
    const playerTime = PlayerPersistSettingsStore((state) => state.playerTime);
    const setIsLargeVideoPlayer = PlayerSettingsStore((state) => state.setIsLargeVideoPlayer);
    const setVideoModal = PlayerSettingsStore((state) => state.setVideoModal);

    const videoMinimize = PlayerSettingsStore((state) => state.videoMinimize);
    const isLargeVideoPlayer = PlayerSettingsStore((state) => state.isLargeVideoPlayer);
    const setVideoMinimize = PlayerSettingsStore((state) => state.setVideoMinimize);

    const [shouldBeFullScreen, setShouldBeFullScreen] = useState<boolean>(false);
    useEffect(() => {
        if (!document?.fullscreenElement) {
            setShouldBeFullScreen(false);
        }
    }, [document?.fullscreenElement]);

    const handleOnPlay = () => {
        setIsPlaying(true);
    };
    const handleOnStop = () => {
        setIsPlaying(false);
    };

    const handleOnError = () => {
        setHasError(true);
        setIsPlaying(false);
    };

    return (
        <div className="relative shadow-xl">
            <div className={`${isLarge ? "" : "absolute -inset-0.5 bg-gradient-to-r from-rc-pink to-rc-blue lg:rounded-l-lg lg:rounded-b-none"}`} />
            <div className={`${isLarge ? "" : "relative inset-0 h-full w-full bg-black lg:rounded-l-lg lg:rounded-b-none"}`}>
                {showIcons && (
                    <div className="flex flex-row flex-nowrap items-center justify-end space-x-2 py-1 px-2">
                        {videoMinimize ? (
                            <FontAwesomeIcon
                                icon={faAngleUp}
                                className="cursor-pointer text-white"
                                onClick={() => {
                                    setVideoMinimize(false);
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faAngleDown}
                                className="cursor-pointer text-white"
                                onClick={() => {
                                    setVideoMinimize(true);
                                }}
                            />
                        )}
                        <div
                            onClick={() => {
                                setVideoModal(true);
                                setIsLargeVideoPlayer(true);
                            }}
                            className="text-white"
                        >
                            <FontAwesomeIcon icon={faExpandAlt} className="cursor-pointer" />
                        </div>
                        <Link href={link}>
                            <a>
                                <FontAwesomeIcon icon={faInfoCircle} className="cursor-pointer text-white" />
                            </a>
                        </Link>
                        <span className="cursor-pointer text-white" onClick={() => setShouldBeFullScreen(!shouldBeFullScreen)}>
                            <FontAwesomeIcon icon={faExpand} />
                        </span>
                    </div>
                )}
                <div className={`cursor-pointer ${videoMinimize && !isLargeVideoPlayer ? "absolute -right-950" : ""}`}>
                    <VideoPlayer
                        key={`${videoUrl}-${hasError}`}
                        showOnlySlider={!isLarge}
                        showForwardIcon={isLarge}
                        play={isPlaying}
                        videoUrl={videoUrl}
                        shouldBeFullScreen={shouldBeFullScreen}
                        initialStartTime={playerTime / 1000}
                        onEnded={onEnded}
                        handleOnPlay={handleOnPlay}
                        handleOnStop={handleOnStop}
                        duration_ms={duration_ms}
                        controls={controls}
                        onError={handleOnError}
                    />
                </div>
            </div>
        </div>
    );
};

export default MediumVideoPlayer;
