import { getActiveCompany } from "@helper/auth/companies-helper";
import useOpenClose, { IUseOpenClose } from "@helper/custom-hooks/use-open-close";
import { CompanyProfile } from "components/companies/type";
import { Dispatch, SetStateAction, useState } from "react";

export interface IUseCompanyProfileContext {
    openCloseAddCompany?: IUseOpenClose;
    openCloseEditCompany?: IUseOpenClose;
    activeCompany: CompanyProfile;
    isEditProfile?: boolean;
    setIsEditProfile?: Dispatch<SetStateAction<boolean>>;
    places?: any;
    setPlaces?: Dispatch<SetStateAction<any>>;
}

const useSignUpContext = (): IUseCompanyProfileContext => {
    const openCloseAddCompany = useOpenClose();
    const openCloseEditCompany = useOpenClose();
    const activeCompany = getActiveCompany();
    const [isEditProfile, setIsEditProfile] = useState<any>(undefined);

    return {
        openCloseAddCompany,
        openCloseEditCompany,
        activeCompany,
        isEditProfile,
        setIsEditProfile,
    };
};

export default useSignUpContext;
