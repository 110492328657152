/* eslint-disable no-underscore-dangle */
import { nextTrack, prevTrack } from "@helper/player/player-helper";
import PlayerSettingsStore from "@helper/player/player-settings-store";
import PlayerStore from "@helper/player/player-store";
import { FastForwardIcon, PauseIcon, PlayIcon, RewindIcon } from "@heroicons/react/solid";
import { FC } from "react";

import PlayerShuffle from "./player-shuffle";

const PlayerControls: FC = () => {
    const queue = PlayerStore((state) => state.queue);
    const history = PlayerStore((state) => state.history);
    const currentPlayerList = PlayerStore((state) => state.currentPlayerList);

    const isPlaying = PlayerSettingsStore((state) => state.isPlaying);
    const setIsPlaying = PlayerSettingsStore((state) => state.setIsPlaying);

    const hasError = PlayerSettingsStore((state) => state.hasError);
    const setHasError = PlayerSettingsStore((state) => state.setHasError);

    const onPlay = () => {
        if (hasError) {
            setHasError(false);
        }
        setIsPlaying(true);
    };
    return (
        <>
            <div className="mr-4 flex flex-row space-x-2">
                {history && history.length > 0 ? (
                    <RewindIcon
                        className="hidden h-7 w-7 cursor-pointer self-center text-white transition hover:text-rc-pink lg:block"
                        onClick={prevTrack}
                    />
                ) : (
                    <RewindIcon className="h-7 w-7 cursor-not-allowed self-center text-rc-gray-dark transition " />
                )}
                <>
                    {isPlaying ? (
                        <PauseIcon
                            className="h-10 w-10 cursor-pointer text-white transition  hover:text-rc-pink "
                            onClick={() => setIsPlaying(false)}
                        />
                    ) : (
                        <PlayIcon className="h-10 w-10 cursor-pointer text-white transition  hover:text-rc-pink " onClick={onPlay} />
                    )}
                </>
                {(queue && queue.length > 0) || currentPlayerList?.tracks?.length > (currentPlayerList?.playing_position ?? 0) + 1 ? (
                    <FastForwardIcon className="h-7 w-7 cursor-pointer self-center text-white transition  hover:text-rc-pink " onClick={nextTrack} />
                ) : (
                    <FastForwardIcon className="h-7 w-7 cursor-not-allowed self-center text-rc-gray-dark transition " />
                )}
                <PlayerShuffle />
            </div>
        </>
    );
};

export default PlayerControls;
