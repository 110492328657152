import useLanguage from "@helper/custom-hooks/use-language";
import useOnClickOutside from "@helper/custom-hooks/use-on-click-outside";
import useOpenClose from "@helper/custom-hooks/use-open-close";
import { ILanguage, isILanguage } from "@models/translations/language";
import React, { FC, useRef } from "react";

const LanguageSwitcher: FC = () => {
    const languages: ILanguage[] = [
        {
            id: "en",
            title: "English",
            short: "EN",
            country: "",
            langSelect: "true",
        },
        {
            id: "de",
            title: "Deutsch",
            short: "DE",
            country: "Deutschland",
            langSelect: "true",
        },
    ];

    const { onLanguageChange } = useLanguage();

    const onClick = async (language: ILanguage) => {
        if (isILanguage(language)) {
            const lang = language.id.toLocaleLowerCase();

            onLanguageChange(lang);
        }
    };

    const openClose = useOpenClose();

    const ref = useRef(null);

    const handleClickOutside = () => {
        openClose.handleClose();
    };

    useOnClickOutside(ref, handleClickOutside);

    return (
        <>
            <div className="flex flex-col py-2 md:flex-row md:space-x-6 ">
                {languages.map((language) => {
                    if (language.langSelect === "true") {
                        return (
                            <button className=" " type="button" onClick={() => onClick(language)} key={language.id}>
                                {language.title}
                            </button>
                        );
                    }
                    return undefined;
                })}
            </div>
        </>
    );
};

export default LanguageSwitcher;
