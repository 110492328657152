import { IFilterLabel } from "@helper/search-filter";
import create from "zustand";
import { devtools } from "zustand/middleware";

interface SearchStoreState {
    searchValue: string;
    filterType: IFilterLabel;
    hiddenFilterType: IFilterLabel;
    setHiddenFilterType: (filterType: IFilterLabel) => void;
    setSearchValue: (searchValue: string) => void;
    setFilterType: (filterType: IFilterLabel) => void;
}

const SearchStore = create<SearchStoreState>(
    devtools(
        (set) => ({
            searchValue: "",
            filterType: undefined,
            hiddenFilterType: undefined,
            setHiddenFilterType: (hiddenFilterType: IFilterLabel) => set(() => ({ hiddenFilterType })),
            setSearchValue: (searchValue: string) => set(() => ({ searchValue })),
            setFilterType: (filterType: IFilterLabel) => set(() => ({ filterType })),
        }),
        { name: "SearchStore" },
    ),
);

export default SearchStore;
