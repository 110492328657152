import SingleLoadingSpinner from "@components/loading-spinner/single-loading-spinner";
import AppleStore from "@helper/apple/apple-store";
import { connectNetwork, deleteNetwork } from "@helper/auth/auth-api-helper";
import AuthStore from "@helper/auth/auth-store";
import { createProfile, getGeneratedJWTLink } from "@helper/ayrshare/api-help";
import { deleteCookie, getCookie } from "@helper/cookie-helper";
import { CheckCircleIcon, PlusCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { EProviderIds } from "@models/provider-ids";
import _ from "lodash";
import React, { FC } from "react";

export interface Properties {
    name: string;
    slug: string;
    iconUrl: string;
    loginUrl: string;
    isConnected: boolean;
    providerId?: EProviderIds;
    isConnectedAyrshare?: boolean;
    onLinkedSocial?: () => void;
}

const NetworkSlide: FC<Properties> = ({ name, iconUrl, loginUrl, isConnected, isConnectedAyrshare, providerId, onLinkedSocial }: Properties) => {
    const mediaContext = AppleStore((state) => state.mediaContext);
    const updateUser = AuthStore((state) => state.updateUser);
    const [isLoading, setIsLoading] = React.useState(false);

    const authorizeApple = async () => {
        await mediaContext.authorize();
        window.location.reload();
    };
    const unauthorizeApple = async () => {
        await mediaContext?.unauthorize();
        window.location.reload();
    };
    const handleApple = async () => {
        if (!isConnected) {
            await authorizeApple();
        } else {
            await unauthorizeApple();
        }
    };

    const socialHandler = async () => {
        let timer: NodeJS.Timeout | null = null;
        if (!isConnected && loginUrl) {
            const newWindow = window.open(
                loginUrl,
                "targetWindow",
                "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=720,height=880",
            );
            if (newWindow) {
                timer = setInterval(() => {
                    if (newWindow.closed && getCookie("network")) {
                        connectNetwork(() => deleteCookie("network"));
                        if (timer) {
                            clearInterval(timer);
                        }
                    }
                }, 5000);
            }
        } else {
            deleteNetwork(providerId);
        }
    };

    const ayrshareSocialHandler = async () => {
        // in case user not link with Ayrshare yet
        setIsLoading(true);
        if (!isConnectedAyrshare) {
            const profile = await createProfile();
            updateUser({ ayrshare_profiles: profile.ayrshare_profiles });
        }
        let timer: NodeJS.Timeout | null = null;
        // redirect to link with Ayrshare social
        const link = await getGeneratedJWTLink();
        const newWindow = window.open(
            link,
            "targetWindow",
            "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=720,height=880",
        );
        if (newWindow) {
            timer = setInterval(() => {
                if (newWindow.closed) {
                    onLinkedSocial?.();
                    if (timer) clearInterval(timer);
                }
            }, 200);
        }
        setIsLoading(false);
    };

    const clickHandler = async () => {
        if (providerId === EProviderIds.apple) {
            await handleApple();
            return;
        }
        if (!_.isNil(providerId)) {
            socialHandler();
        } else {
            ayrshareSocialHandler();
        }
    };

    if (isLoading) {
        return <SingleLoadingSpinner isLoading={isLoading} />;
    }

    return (
        <div className="group flex cursor-pointer justify-center" onClick={clickHandler}>
            <a className="relative">
                {isConnected ? (
                    <div className="absolute bottom-0 right-0 h-8 w-8 rounded-full bg-green-600 group-hover:bg-red-600 ">
                        <CheckCircleIcon className="text-white group-hover:hidden" />
                        <XCircleIcon className="hidden text-white group-hover:block" />
                    </div>
                ) : (
                    <div className="absolute bottom-0 right-0 h-8 w-8 rounded-full bg-rc-black-light">
                        <PlusCircleIcon className="text-rc-gray-light" />
                    </div>
                )}

                <img src={iconUrl} alt={name} className=" h-20 w-20 rounded-full object-cover" />
            </a>
        </div>
    );
};

export default NetworkSlide;
