import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";

export interface IInterestTile {
    title: string;
    imgUrl?: string;
    bgColor?: string;
    selected?: boolean;
    onClick?: () => void;
    customClass?: string;
}

const InterestTile: FC<IInterestTile> = ({ title, bgColor, selected, onClick, customClass = "" }: IInterestTile) => (
    <ul className="flex-1">
        <li className={`flex flex-1 cursor-pointer flex-col items-center ${customClass}`}>
            <div
                className={`cursor-pointer rounded-lg p-0.5 transition duration-150 hover:scale-105${
                    selected ? " bg-gradient-to-r from-rc-pink to-rc-blue" : ""
                }`}
                onClick={onClick}
            >
                <div className="relative h-[140px] w-[140px] rounded-lg bg-rc-dark p-1" style={{ backgroundColor: bgColor ?? "" }}>
                    {selected && (
                        <div className="absolute top-0 right-0 m-2 flex h-6 w-6 items-center justify-center">
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                    )}
                    <span
                        className={`absolute  bottom-2 px-2  text-sm lg:px-4 lg:text-base ${
                            selected ? "text-gradient font-bold" : "text-shadow text-white"
                        }`}
                    >
                        {title}
                    </span>
                </div>
            </div>
        </li>
    </ul>
);

export default InterestTile;
