import moment from "moment";
import React from "react";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface ILengthFilter {
    id: number;
    name: string;
    valueLte: number;
    valueGte: number;
}

export const lengthFilterEnum: ILengthFilter[] = [
    {
        id: 0,
        name: "0 - 90 Minutes",
        valueGte: moment.duration(0, "minute").asMinutes(),
        valueLte: moment.duration(90, "minute").asMinutes(),
    },
    {
        id: 0,
        name: "90 - 120 Minutes",
        valueGte: moment.duration(90, "minute").asMinutes(),
        valueLte: moment.duration(120, "minute").asMinutes(),
    },
    {
        id: 0,
        name: "120 - 180 Minutes",
        valueGte: moment.duration(120, "minute").asMinutes(),
        valueLte: moment.duration(180, "minute").asMinutes(),
    },
    {
        id: 0,
        name: "180+ Minutes",
        valueGte: moment.duration(180, "minute").asMinutes(),
        valueLte: moment.duration(999, "minute").asMinutes(),
    },
    {
        id: -1,
        name: "All durations",
        valueGte: undefined,
        valueLte: undefined,
    },
];

interface LengthFilterState {
    lengthValues: ILengthFilter[];
    selectedLength: ILengthFilter;
    setSelectedLength: (label: ILengthFilter) => void;
}

export const LengthFilterStore = create<LengthFilterState>(
    devtools(
        (set) => ({
            lengthValues: lengthFilterEnum,
            selectedLength: lengthFilterEnum[4],
            setSelectedLength: (label: ILengthFilter) => set(() => ({ selectedLength: label })),
        }),
        { name: "LengthFilterStore" },
    ),
);

export const LengthFilter = () => {
    const lengthValues = LengthFilterStore((state) => state.lengthValues);
    const selectedLength = LengthFilterStore((state) => state.selectedLength);
    const setSelectedLength = LengthFilterStore((state) => state.setSelectedLength);
    return (
        <div className="flex w-1/6 flex-col gap-4 p-2">
            <p className="border-b border-gray-600 pb-2 font-oswald text-lg font-bold uppercase">Duration</p>
            <div className="flex flex-col gap-2">
                {lengthValues.map((lengthValue: ILengthFilter) => (
                    <div
                        className={`w-1/4 cursor-pointer hover:underline  ${selectedLength === lengthValue ? "text-rc-pink" : "hover:text-rc-pink"}`}
                        key={lengthValue.name}
                        onClick={() => setSelectedLength(lengthValue)}
                    >
                        <span className={`flex items-center whitespace-nowrap`}>{lengthValue.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
